import { FieldProps } from 'formik';
import { FastField } from 'helpers/formik.helper';
//
import React, {
  ChangeEventHandler,
  FC,
  InputHTMLAttributes,
  memo,
} from 'react';
import Radio, { Props as RadioCheckbox } from '../../ui/Radio/Radio';

interface Props {
  name: string;
  id?: string;
  onChange?: (e: any, ...args: any[]) => ChangeEventHandler<any> | void;
  required?: boolean;
  isChecked?: boolean;
  getValue?: number;
  setGetValue?: any;
}

const FormikRadio: FC<
  Props & InputHTMLAttributes<HTMLInputElement> & RadioCheckbox
> = (props) => {
  const { name, value, onChange, required, isChecked, ...other } = props;
  return (
    <FastField name={name}>
      {({ field, form }: FieldProps) => {
        if (isChecked && onChange && !form.values[name]) {
          form.setFieldTouched(name, true, true);
          form.setFieldValue(name, Number(value), true);
          const event = Object.create({ target: { checked: true } });
          onChange?.(event);
        }
        const isCheck = String(field.value) === String(value);
        return (
          <Radio
            {...other}
            value={value}
            checked={isChecked || isCheck}
            onChange={(e) => {
              form.setFieldTouched(name, true, true);
              if (e.target.checked) {
                form.setFieldValue(name, Number(e.target.value), true);
              } else {
                form.setFieldValue(name, undefined, true);
              }

              onChange?.(e);
            }}
            isError={
              (required &&
                (field.value === null ||
                  field.value === '' ||
                  field.value === undefined)) ||
              other.isError
            }
          />
        );
      }}
    </FastField>
  );
};

export default memo(FormikRadio);
