import { getDetailByQrCodeApi } from 'api/courierOrder.api';
import images from 'assets/images';
import cx from 'classnames';
import Input from 'components/ui/Input/Input';
import { TYPE_ORDER } from 'constants/order';
import { CourierOrderRouteConst } from 'constants/route.const';
import { parse, stringify } from 'query-string';
import React, { FC, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import classes from './style.module.scss';
import Filter from './Filter';
import NewQrCode from './NewQrCode';

enum Type {
  Collect = 1,
  Delivery = 2,
}
interface Props {
  type: Type;
  filter: boolean;
  setPage: () => void;
  isNavLink?: boolean;
  locationSearch?: string;
  getInvisibleContent: (x: boolean) => void;
}

const NavOrder: FC<Props> = ({
  type,
  filter,
  setPage,
  isNavLink = true,
  getInvisibleContent,
}) => {
  const location = useLocation<any>();
  const queryParams = parse(location.search);
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [isQRCode, setIsQRCode] = useState(false);
  const [resultQrCode, setResultQrCode] = useState('');
  const [valueSearchForm, setValueSearchForm] = useState(queryParams.keyword);
  const { t } = useTranslation('order');
  const history = useHistory();
  const onSubmit = useCallback(
    (
      e:
        | React.FormEvent<HTMLFormElement>
        | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
      e.preventDefault();
      if (valueSearchForm.length === 0) {
        setPage();
        return history.push(`${location.pathname}`);
      }
      const filterString = stringify(
        {
          ...queryParams,
          keyword: valueSearchForm,
        },
        { arrayFormat: 'comma' },
      );
      const url = `${location.pathname}?${filterString}`;
      setPage();
      return history.push(url);
    },
    [history, location.pathname, queryParams, setPage, valueSearchForm],
  );
  const handleFilter = () => {
    setIsFilter(false);
    getInvisibleContent(false);
  };
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueSearchForm(e.target.value);
  };
  const handleScan = async (result: string) => {
    const response = await getDetailByQrCodeApi(result, type);
    if (response?.data?.id) {
      if (type === TYPE_ORDER.COLLECT) {
        history.push(
          `${CourierOrderRouteConst.ORDER_COLLECTION_DETAIL.replace(
            ':id',
            response.data.id,
          )}`,
        );
      } else if (type === TYPE_ORDER.DELIVERY) {
        history.push(
          `${CourierOrderRouteConst.ORDER_DELIVERY_DETAIL.replace(
            ':id',
            response.data.id,
          )}`,
        );
      }
    } else {
      setResultQrCode((prev) => t('NotResultFound'));
    }
  };
  const handleError = (err: Error) => {
    setResultQrCode((prev) => String(err));
  };
  const handleIsFilter = () => {
    setIsFilter((prev) => !prev);
    getInvisibleContent(true);
  };
  const handleQrCode = () => {
    setIsQRCode(true);
    getInvisibleContent(true);
  };
  const handleCloseQrCode = () => {
    setIsQRCode(false);
    getInvisibleContent(false);
  };
  return (
    <>
      {isQRCode && (
        <NewQrCode
          handleCloseQrCode={handleCloseQrCode}
          resultQrCode={resultQrCode}
          handleError={handleError}
          handleScan={handleScan}
          isOrder={true}
        />
      )}
      <div
        className={cx(
          classes.container,
          'flex flex-col w-full relative shadow',
        )}>
        <div
          className={cx('flex', {
            ' pb-3 ': !isNavLink,
          })}>
          <div className={classes.searchContainer}>
            <form onSubmit={onSubmit} className={classes.formSearch}>
              <Input
                className={classes.searchBar}
                placeholder={t('PlaceHoverSearch')}
                value={valueSearchForm}
                autoFocus={false}
                onChange={handleChangeSearch}
                renderSuffix={
                  <>
                    <button
                      type="button"
                      className={cx(
                        'outline-none',
                        valueSearchForm && valueSearchForm.length > 0
                          ? 'block'
                          : 'hidden',
                      )}
                      onClick={(e) => setValueSearchForm('')}>
                      <div className=" relative">
                        <img
                          className={cx('mr-6', classes.icon_close)}
                          src={images.common.close}
                          alt="close-img"
                        />
                        <div
                          className={cx(
                            ' absolute right-2 top-1/2 transform -translate-y-1/2',
                            classes.icon_close_after,
                          )}
                        />
                      </div>
                    </button>
                    <button onClick={onSubmit} type="button">
                      <img src={images.icons.iconSearch} alt="search" />
                    </button>
                  </>
                }
              />
            </form>
            <div onClick={handleQrCode}>
              <img
                className={cx(classes.qrCode, ' p-2 bg-blue rounded w-11 h-11')}
                src={images.orderCollection.qrLogo}
                alt="qrCode"
              />
            </div>
          </div>
        </div>
        {isNavLink && (
          <div>
            <div className={cx('flex items-center', classes.nav_order)}>
              <NavLink
                exact
                activeClassName={cx('', classes.active)}
                to={`${CourierOrderRouteConst.ORDER_COLLECTION}${location.search}`}>
                <span>
                  {t('Collect')}
                  <p> </p>
                </span>
              </NavLink>
              <NavLink
                exact
                activeClassName={cx('8', classes.active)}
                to={`${CourierOrderRouteConst.ORDER_DELIVERY}${location.search}`}>
                <span>
                  {t('Delivery')}
                  <p> </p>
                </span>
              </NavLink>
              <img
                className={classes.commonMargin}
                src={images.orderCollection.separateNav}
                alt="separate"
              />
              <a
                className={cx({ active: isFilter })}
                style={{ color: filter && '#3DAAE8' }}
                onClick={handleIsFilter}>
                {t('Filter')}
                <p> </p>
              </a>
              {filter ? (
                <img
                  className={classes.approach}
                  src={images.orderCollection.approachBlue}
                  alt="approach"
                />
              ) : (
                <img
                  className={classes.approach}
                  src={images.orderCollection.approach}
                  alt="approach"
                />
              )}
            </div>
          </div>
        )}
        {isFilter && (
          <Filter
            setFilterVisible={handleFilter}
            type={type}
            setPage={setPage}
          />
        )}
      </div>
    </>
  );
};

export default memo(NavOrder);
