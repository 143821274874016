import { createReducer } from 'typesafe-actions';
import { OrderHistoryStore } from '../../models/store/orderHistory/order-history.model';
import { getOrderHistory, clearOrderHistory } from './order-history.action';

const INIT: OrderHistoryStore = {
  history: [],
  loading: true,
  error: '',
  meta: {
    page: 1,
    recordCnt: 15,
    totalCnt: 0,
  },
};

export const reducer = createReducer(INIT)
  .handleAction(getOrderHistory.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getOrderHistory.success, (state, action) => ({
    ...state,
    loading: false,
    error: '',
    history: action.payload.data,
    meta: action.payload.meta,
  }))
  .handleAction(getOrderHistory.failure, (state, action) => ({
    ...state,
    loading: false,
  }))
  .handleAction(clearOrderHistory, (state) => ({
    ...state,
    history: [],
    error: '',
    meta: {
      page: 1,
      recordCnt: 15,
      totalCnt: 0,
    },
  }));

export default reducer;
