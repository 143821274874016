import Cookie from 'js-cookie';
import md5 from 'md5';
import { all, call, put, takeLatest } from 'typed-redux-saga';
import { loginApi, logoutApi } from 'api/authentication.api';
import { LOGIN_MESSAGE } from 'constants/authencation.const';
import { EXPIRED_TIME, TOKEN_KEY, USER_KEY } from 'constants/common.const';
import { Type } from 'models/api/authentication/authentication.model';
import { AuthRouteConst, CourierOrderRouteConst } from 'constants/route.const';
import { clearShops } from 'store/shops/shops.action';
import { clearListAssembling } from 'store/order/assemblingOrder/list/list.action';
import { clearListCollection } from 'store/orderCollection/list/list.action';
import { clearListDelivery } from 'store/orderDelivery/list/list.action';
import { clearOrderDetail } from 'store/order/detail/detail.action';
import { clearOrderHistory } from 'store/orderHistory/order-history.action';
import { clearCollectionDetail } from 'store/orderCollection/detail/detail.action';
import { clearDeliveryDetail } from 'store/orderDelivery/detail/detail.action';
import { clearList } from 'store/order/list/list.action';
import history from '../../helpers/history.helper';
import { doLogin, doLogout } from './authentication.action';

function* handleLogin(action: ReturnType<typeof doLogin.request>) {
  try {
    const response = yield* call(
      loginApi,
      action.payload.username,
      md5(action.payload.password),
    );
    if (response.result.message === LOGIN_MESSAGE.success) {
      yield* put(doLogin.success(response.data));
      yield put(clearShops());
      yield put(clearList());
      yield put(clearListAssembling());
      yield put(clearListCollection());
      yield put(clearListDelivery());
      yield put(clearOrderDetail());
      yield put(clearOrderHistory());
      yield put(clearCollectionDetail());
      yield put(clearDeliveryDetail());

      Cookie.set(TOKEN_KEY, response.data.access_token, {
        expires: EXPIRED_TIME,
      });
      Cookie.set(
        USER_KEY,
        JSON.stringify({
          data: response.data,
          access_token: response.data.access_token,
        }),
        {
          expires: EXPIRED_TIME,
        },
      );
      if (response.data.type === Type.COURIER) {
        yield history.push(CourierOrderRouteConst.ORDER_COLLECTION, {
          from: AuthRouteConst.SIGN_IN,
        });
      } else {
        yield history.push('/', {
          from: AuthRouteConst.SIGN_IN,
        });
      }
    } else {
      yield put(doLogin.failure(response.result.message));
    }
  } catch (e) {
    yield* put(doLogin.failure('Error'));
  }
}

function* handleLogout() {
  try {
    Cookie.remove(TOKEN_KEY);
    Cookie.remove(USER_KEY);
    yield* call(logoutApi);
    yield* put(doLogout.success());
  } catch (e) {
    yield* put(doLogout.failure());
  }
  history.push(AuthRouteConst.SIGN_IN);
}

export default function* authenticateSaga() {
  yield* all([
    takeLatest(doLogin.request, handleLogin),
    takeLatest(doLogout.request, handleLogout),
  ]);
}
