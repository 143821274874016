import React, { FC, useRef, useEffect } from 'react';
import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library';
import images from 'assets/images';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import classes from './style.module.scss';

const DELAY_TIME = 1000;
const codeReader = new BrowserMultiFormatReader(undefined, DELAY_TIME);

interface Props {
  handleCloseQrCode?: () => void;
  handleScan?: (result: string) => void;
  handleError?: (e: Error) => void;
  resultQrCode?: string;
  isCollectDetail?: boolean;
  setIsInputPw?: () => void;
  isOrder?: boolean;
  disableIntoPassword?: boolean;
}

const NewQrCode: FC<Props> = ({
  resultQrCode,
  isCollectDetail,
  isOrder,
  handleCloseQrCode,
  handleError,
  handleScan,
  setIsInputPw,
  disableIntoPassword,
}) => {
  const { t } = useTranslation('order');

  const videoRef = useRef<HTMLVideoElement>();
  const canvasRef = useRef<HTMLDivElement>();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (
        'mediaDevices' in navigator &&
        'getUserMedia' in navigator.mediaDevices
      ) {
        codeReader
          .listVideoInputDevices()
          .then((videoInputDevices) => {
            const selectedDeviceId =
              videoInputDevices[videoInputDevices.length - 1]?.deviceId;
            codeReader.decodeFromInputVideoDeviceContinuously(
              selectedDeviceId,
              videoRef.current,
              (result, error) => {
                if (result) {
                  handleScan(result.getText());
                  handleCloseQrCode();
                }
                if (error && !(error instanceof NotFoundException)) {
                  if (handleError) {
                    handleError(error);
                  }
                  handleCloseQrCode();
                }
              },
            );
          })
          .catch((e) => {
            throw e;
          });
      }
    }
    return () => {
      if (codeReader) {
        codeReader.reset();
      }
      mounted = false;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.scanner}>
      <div className={classes.scannerVideo}>
        {/* eslint-disable-next-line */}
        <video ref={videoRef} autoPlay playsInline />
        <div className="relative flex flex-col text-center px-10 pt-20">
          <div className=" flex justify-end">
            <button
              type="button"
              className={cx(
                'outline-none my-5 mx-1 z-30',
                classes.close_qrcode,
              )}
              onClick={handleCloseQrCode}>
              <img src={images.icons.iconCloseWhite} alt="close" />
            </button>
          </div>

          <div
            ref={canvasRef}
            className={cx('h-60 w-60 mt-4', classes.scannerCanvas)}>
            <img
              src={images.orderCollection.upperLeftCorner}
              alt="corner"
              className="absolute -right-6 -top-6"
            />
            <img
              src={images.orderCollection.upperRightCorner}
              alt="corner"
              className="-top-6 absolute -left-6"
            />
            <img
              src={images.orderCollection.lowerRightCorner}
              alt="corner"
              className="absolute -bottom-6 -left-6"
            />
            <img
              src={images.orderCollection.lowerLeftCorner}
              alt="corner"
              className="absolute -bottom-6 -right-6"
            />
          </div>

          {isCollectDetail && (
            <div className={'z-30'}>
              <p className={'mt-16 text-white mx-auto w-4/5 text-medium'}>
                {!disableIntoPassword
                  ? t('CollectionDetail.TextQrCode')
                  : t('CollectionDetail.TextQrCodeWithOutInput')}
              </p>
              {!disableIntoPassword ? (
                <button type="button" onClick={setIsInputPw}>
                  <p className={'mt-28 text-white underline'}>
                    {t('CollectionDetail.InputPasswordInstead')}
                  </p>
                </button>
              ) : (
                ''
              )}
            </div>
          )}
          {isOrder && <p className={'mt-7 text-white'}>{t('ScanQRCode')}</p>}
          <p className={'text-error'}>{resultQrCode}</p>
        </div>
      </div>
    </div>
  );
};

export default NewQrCode;
