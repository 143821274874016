import { all, fork } from '@redux-saga/core/effects';
import listSaga from './list/list.saga';
import detailSaga from './detail/detail.saga';

export default function* orderDeliverySaga() {
  try {
    yield all([fork(listSaga)]);
    yield all([fork(detailSaga)]);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
}
