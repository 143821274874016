import { FieldProps, FieldValidator } from 'formik';
import { FastField } from 'helpers/formik.helper';
import React, { FC, memo } from 'react';
import Input, { Props as InputProps } from '../../ui/Input/Input';

interface Props {
  name: string;
  validate?: FieldValidator;
  showError?: boolean;
  noAlphabet?: boolean;
  onBlur?: (e: any) => void;
  restrictDecimal?: number;
  uncontrolled?: boolean;
  mandatory?: boolean;
  setLang?: boolean;
}

const FormikInput: FC<Props & InputProps> = (props) => {
  const {
    name,
    validate,
    onChange,
    showError = true,
    noAlphabet,
    restrictDecimal,
    onBlur,
    uncontrolled,
    mandatory,
    setLang,
    ...other
  } = props;

  return (
    <FastField name={name} validate={validate}>
      {({ field, meta }: FieldProps) => {
        const isError = !!meta.error && !!meta.touched;
        // eslint-disable-next-line
        const { value: v, ...fieldPropsWithoutValue } = field;
        const shouldPassToInput = uncontrolled
          ? fieldPropsWithoutValue
          : {
              ...field,
              value: props.value || field.value || '',
            };
        return (
          <>
            <Input
              {...other}
              {...shouldPassToInput}
              onChange={(e) => {
                if (noAlphabet) {
                  e.target.value = e.target.value.replace(/[^\d.\-+]/, '');
                }
                if (restrictDecimal) {
                  const t = e.target.value;
                  e.target.value =
                    t.indexOf('.') >= 0
                      ? t.substr(0, t.indexOf('.')) +
                        t.substr(t.indexOf('.'), restrictDecimal + 1)
                      : t;
                }
                field.onChange(name)(e);
                onChange?.(e);
              }}
              onBlur={(e) => {
                field.onBlur(name)(e);
                onBlur?.(e);
              }}
              isError={isError || other.isError}
              mandatory={mandatory}
            />
            {(other.isError === undefined ? isError : other.isError) &&
              showError && (
                <div className="relative">
                  <p className={'error-text text-red-700 text-xs absolute'}>
                    {meta.error}
                  </p>
                </div>
              )}
          </>
        );
      }}
    </FastField>
  );
};

export default memo(FormikInput);
