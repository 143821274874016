import images from 'assets/images';
import cx from 'classnames';
import InvisibleBackdrop from 'components/ui/Backdrop/InvisibleBackdrop';
import { ToastIcon } from 'models/entities/common.entity';
import React, { FC, useEffect, useMemo, useState } from 'react';
import classes from './toast.module.scss';

interface Props {
  visible: boolean;
  className?: string;
  time?: number;
  content?: string;
  type?: 'success' | 'failure';
  icon?: ToastIcon;
  always?: boolean;
  position?: 'bottom' | 'top';
  iconClass?: string;
  contentClass?: string;
}

const Toast: FC<Props> = ({
  visible,
  className,
  content,
  time,
  type,
  icon,
  always,
  children,
  position,
  iconClass,
  contentClass,
}) => {
  const [open, setOpen] = useState(false);
  const iconUrl = useMemo(() => {
    switch (icon) {
      case 'success':
        return images.orderCollection.completed;

      case 'delivery':
        return images.order.pickUp;

      case 'failure':
        return images.common.close;

      case 'success-create':
        return images.order.successCreate;
      default:
        return images.common.close;
    }
  }, [icon]);

  useEffect(() => {
    if (!always && visible) {
      setOpen(true);
      setInterval(() => {
        setOpen(false);
      }, time || 3000);
    }
  }, [visible, time, always]);

  return (
    <InvisibleBackdrop onClick={() => setOpen(false)}>
      <div
        className={cx(classes.container, {
          [classes.open]: open && (!position || position === 'top'),
          [classes.bottom]: open && position === 'bottom',
          [classes.close]: !open,
        })}>
        <div className={cx(classes.complete_modal, className, classes[type])}>
          <img
            className={cx(classes.complete_icon, iconClass)}
            src={iconUrl}
            alt="collect modal"
          />
          <div className={contentClass}>{content}</div>
          {children}
        </div>
      </div>
    </InvisibleBackdrop>
  );
};

export default Toast;
