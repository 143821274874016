import { createAsyncAction, createAction } from 'typesafe-actions';
import { OrderHistoryData } from '../../models/api/orderHistory/orderHistory.model';

export interface GetHistoryPayload {
  startDate: string;
  endDate: string;
  page: number;
  sort: number;
  priority: number;
}
type GetHistorySuccessPayload = {
  data: OrderHistoryData[];
  meta: {
    page: number;
    recordCnt: number;
    totalCnt: number;
  };
};

export const getOrderHistory = createAsyncAction(
  'GET_HISTORY_REQUESTED',
  'GET_HISTORY_SUCCESS',
  'GET_HISTORY_FAILURE',
)<GetHistoryPayload, GetHistorySuccessPayload, void>();

export const clearOrderHistory = createAction('CLEAR_HISTORY')<void>();
