import { createAsyncAction, createAction } from 'typesafe-actions';
import { Shop } from '../../models/api/shops/shops.model';

export interface GetShopPayload {
  token: string;
}

export const getAllShops = createAsyncAction(
  'GET_SHOP_REQUEST',
  'GET_SHOP_SUCCESS',
  'GET_SHOP_FAILURE',
)<GetShopPayload, Shop[], string>();

export const clearShops = createAction('CLEAR_SHOP')<void>();
