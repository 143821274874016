import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { tokenManager } from '../utils/request/tokenManager';
import { logoutAction } from './authentication/authentication.action';
import rootReducer from './reducer';
import rootSaga from './saga';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware)),
);

tokenManager.setLogoutMethod(() => {
  store.dispatch(logoutAction());
});

sagaMiddleware.run(rootSaga);

let currentToken: string;

function handleChange() {
  const previousToken = currentToken;
  currentToken = store.getState().authentication.user?.access_token;
  if (previousToken !== currentToken) {
    tokenManager.setToken(currentToken);
  }
}

store.subscribe(handleChange);

const persistor = persistStore(store);

export { store, persistor };
