import images from 'assets/images';
import cx from 'classnames';
import NavOrder from 'containers/app/Common/NavOrder';
import Button from 'components/ui/Button/Button';
import { COLORS } from 'constants/color.const';
import { TYPE_BUTTON, TYPE_ORDER } from 'constants/order';
import { CourierOrderRouteConst } from 'constants/route.const';
import { getDate, getTime } from 'helpers/datetime.helper';
import history from 'helpers/history.helper';
import {
  DeliveryStatus,
  OrderStatus,
} from 'models/api/orderCollection/orderCollection.model';
import { parse } from 'query-string';
import React, { memo, useEffect, useState } from 'react';
// import { differenceInDays } from 'date-fns';

import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import { capitalize } from 'lodash';
import { getListDelivery } from '../../../store/orderDelivery/list/list.action';
import classes from './style.module.scss';

const Delivery = () => {
  const { t } = useTranslation('order');
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const ordersDelivery = useSelector((store) => store.orderDelivery.list);
  const location = useLocation<any>();
  const [page, setPage] = useState<number>(1);
  const [invisibleContent, setInvisibleContent] = useState(false);
  const queryParams = parse(location.search);
  const handleClear = () => {
    if (page !== 1) setPage((prev) => 1);
    history.push(location.pathname);
  };

  useEffect(() => {
    let filterStatusDelivery: string[] = [];
    if (typeof queryParams.filterStatusDelivery === 'string') {
      filterStatusDelivery = [...queryParams.filterStatusDelivery.split(',')];
    }

    if (typeof queryParams.filterStatusCollect === 'string') {
      const filterStatusCollect = queryParams.filterStatusCollect.split(',');
      if (filterStatusCollect.includes(String(OrderStatus.PENDING))) {
        filterStatusDelivery = [
          ...filterStatusDelivery,
          String(OrderStatus.PENDING),
        ];
      }
      if (
        !filterStatusDelivery.includes(String(OrderStatus.DELIVERED)) &&
        queryParams.filterStatusCollect
      ) {
        filterStatusDelivery = [...filterStatusDelivery, String(0)];
      }
    }
    const filterDeliveryStatus = filterStatusDelivery.map((item) =>
      !item ? '0' : item,
    );

    dispatch(
      getListDelivery.request({
        page,
        type: TYPE_ORDER.DELIVERY,
        filterDate: queryParams?.filterDate as string,
        filterStatusCollect: queryParams.filterStatusCollect as string[],
        filterStatusDelivery: filterDeliveryStatus as string[],
        filterRegion: queryParams.filterRegion as string[],
        keyword: queryParams?.keyword as string,
        lang: i18n.language,
      }),
    );
  }, [
    dispatch,
    queryParams.filterStatusCollect,
    queryParams.filterStatusDelivery,
    queryParams?.filterDate,
    queryParams.filterRegion,
    queryParams.keyword,
    page,
    i18n.language,
  ]);

  const handleScrollNext = () => {
    setPage((prev) => prev + 1);
  };
  const handlePageFilter = () => {
    setPage(1);
  };

  return (
    <div className={cx('flex flex-col', classes.mainContainer)}>
      <NavOrder
        setPage={handlePageFilter}
        type={TYPE_ORDER.DELIVERY}
        filter={location?.search?.length > 0}
        isNavLink={!queryParams.keyword}
        getInvisibleContent={(invisibleContent) =>
          setInvisibleContent(invisibleContent)
        }
      />

      {ordersDelivery.loading ? (
        <div className={'fixed top-1/3 flex w-full justify-center'}>
          <ScaleLoader color={COLORS.blue.DEFAULT} />
        </div>
      ) : (
        <div
          className={cx('flex-1 flex flex-col', classes.order_collection, {
            hidden: invisibleContent,
          })}>
          <InfiniteScroll
            style={{ overflow: 'hidden' }}
            dataLength={ordersDelivery.info.length}
            hasMore={ordersDelivery.hasMore}
            loader={
              <>
                <div className={'flex mt-10 w-full justify-center'}>
                  <ScaleLoader color={COLORS.blue.DEFAULT} />
                </div>
              </>
            }
            next={handleScrollNext}
            className={'row flex-wrap'}>
            <>
              {ordersDelivery.info.length > 0 &&
                ordersDelivery.info.map((item, key) => {
                  const typeButton =
                    parseInt(item?.status) === DeliveryStatus.DELIVERED
                      ? TYPE_BUTTON.SUCCESS
                      : TYPE_BUTTON.WARNING;
                  return (
                    <div
                      key={String(key)}
                      className={cx('border-b', classes.order_collection_item, {
                        'bg-blue-50': key % 2 === 0,
                      })}>
                      <div
                        className={cx(
                          ' flex-1 flex justify-between flex-col',
                          classes.collectFrames,
                        )}>
                        <div className="flex-col">
                          <p className={classes.frameHeader}>
                            {t('DeliveryFrames')}
                          </p>
                          <p className="text-gray-3">
                            {t('FieldCollection.RequestdDate')} :{' '}
                            {item?.pickupDate &&
                              getDate(
                                new Date(item.pickupDate).getTime(),
                                'yyyy-mm-dd',
                              )}
                          </p>
                          <p className="text-gray-3">
                            {t('FieldCollection.OfficeHour')} :{' '}
                            {item?.officeHourFrom &&
                              getTime(
                                new Date(item.officeHourFrom).getTime(),
                                'hh-mm',
                              )}
                            -
                            {item?.officeHourTo &&
                              getTime(
                                new Date(item.officeHourTo).getTime(),
                                'hh-mm',
                              )}
                          </p>
                        </div>
                        <button
                          type="button"
                          className={cx('font-bold', classes[typeButton])}>
                          {item && item.status
                            ? capitalize(DeliveryStatus[item.status])
                            : ''}
                        </button>
                      </div>
                      <Link
                        to={CourierOrderRouteConst.ORDER_DELIVERY_DETAIL.replace(
                          ':id',
                          item.id,
                        )}>
                        <div className="flex-col">
                          <p className="text-blue font-bold">{item?.region}</p>
                          <p
                            className={cx(
                              'font-bold text-lg uppercase text-gray-1',
                              classes.shopName,
                            )}>
                            {item?.shopName}
                          </p>
                          <p className="text-gray-3 text-sm">{item?.address}</p>
                        </div>
                      </Link>
                      <div className="code-item">
                        <span
                          className={cx(
                            'text-base text-dark-blue font-fat flex',
                            classes.idContainer,
                          )}>
                          <span className="text-base">#{item?.refNo}</span>
                          <img
                            src={images.orderCollection.glasses}
                            alt="icon-glasses"
                            className="ml-2 mr-1 mb-2"
                          />
                          <span style={{ color: '#3DAAE8' }} className="mb-1">
                            x{item?.salesOrderCnt}
                          </span>
                        </span>
                      </div>
                    </div>
                  );
                })}
            </>
          </InfiniteScroll>
          {ordersDelivery?.info.length === 0 && (
            <div className="w-full p-2 mx-auto text-center flex flex-col flex-1 justify-center">
              <h3 className="text-dark-blue text-xl font-bold pb-3">
                {t('NotResultFound')}
              </h3>
              {queryParams.keyword || !Object.keys(queryParams).length ? (
                <p>{t('OrtherFilterAgain')}</p>
              ) : (
                <>
                  <p className=" text-sm">{t('OrtherFilter')}</p>
                  <Button className="w-full mt-10" onClick={handleClear}>
                    {t('textButton.ClearFilter')}
                  </Button>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default memo(Delivery);
