import { fork } from '@redux-saga/core/effects';
import authenticateSaga from './authentication/authentication.saga';
import orderCollectionSaga from './orderCollection/order-collection.saga';
import orderDeliverySaga from './orderDelivery/order-delivery.saga';
import orderSaga from './order/order.saga';
import shopSaga from './shops/shops.saga';
import passwordSaga from './password/password.saga';
import orderHistorySaga from './orderHistory/order-history.saga';

export default function* rootSaga() {
  try {
    yield fork(authenticateSaga);
    yield fork(orderCollectionSaga);
    yield fork(orderDeliverySaga);
    yield fork(orderSaga);
    yield fork(shopSaga);
    yield fork(passwordSaga);
    yield fork(orderHistorySaga);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
}
