import {
  CollectionDetailResponse,
  RegionResponse,
} from '../models/api/orderCollection/orderCollection.model';
import authorizedRequest from '../utils/request/authorizedRequest';

enum Type {
  Collect = 1,
  Delivery = 2,
}

export function getDetailByQrCodeApi(qrCode: string, type?: Type) {
  return authorizedRequest.get<
    CollectionDetailResponse,
    CollectionDetailResponse
  >(`/courier/order/getByQRCode`, {
    params: {
      qrCode,
      type,
    },
  });
}

export function getRegionWithPage(page: number) {
  return authorizedRequest.get<RegionResponse, RegionResponse>(
    `/courier/region`,
    {
      params: {
        page,
      },
    },
  );
}
