import { StoreState } from 'models/store';
import { combineReducers, ReducersMapObject } from 'redux';
import { ActionType } from 'typesafe-actions';
import authenticationReducer from './authentication/authentication.reducer';
import orderCollectionReducer from './orderCollection/order-collection.reducer';
import orderDeliveryReducer from './orderDelivery/order-delivery.reducer';
import orderReducer from './order/order.reducer';
import shopReducer from './shops/shops.reducer';
import orderHistoryReducer from './orderHistory/order-history.reducer';

const rootReducer: ReducersMapObject<
  StoreState,
  ActionType<typeof import('../store/action').default>
> = {
  authentication: authenticationReducer,
  order: orderReducer,
  shop: shopReducer,
  orderCollection: orderCollectionReducer,
  orderDelivery: orderDeliveryReducer,
  orderHistory: orderHistoryReducer,
};

export default combineReducers(rootReducer);
