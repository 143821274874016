import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { stringify } from 'query-string';
import i18n from 'i18next';
import { LanguageConfig } from 'constants/i18n.const';
import CONFIG from '../../config';

const unauthorizedRequest: AxiosInstance = axios.create({
  baseURL: CONFIG.BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  paramsSerializer: (params) => stringify(params, { arrayFormat: 'comma' }),
});

unauthorizedRequest.interceptors.request.use((config: AxiosRequestConfig) => {
  const newConfig = { ...config };
  newConfig.params = {
    ...newConfig.params,
    lang: LanguageConfig.find((i) => i.lang === i18n.language)?.index || 1,
  };
  newConfig.data = stringify(config.data);

  return newConfig;
});

unauthorizedRequest.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  (error: AxiosError) => {
    throw error;
  },
);

export default unauthorizedRequest;
