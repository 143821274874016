import { ShopsResponse } from '../models/api/shops/shops.model';
import authorizedRequest from '../utils/request/authorizedRequest';

export function getAllShopsApi(token: string) {
  return authorizedRequest.get<ShopsResponse, ShopsResponse>(
    '/company/admin/getList',
    {
      params: {
        token,
      },
    },
  );
}
