const isUndefined = (value: any): value is undefined => value === undefined;

const isNull = (value: any): value is null => value === null;

const isBoolean = (value: any): value is boolean => typeof value === 'boolean';

const isObject = (value: any): value is object => value === Object(value);

const isArray = (value: any): value is Array<any> => Array.isArray(value);

const isDate = (value: any): value is Date => value instanceof Date;

const isBlob = (value: any): value is Blob =>
  value &&
  typeof value.size === 'number' &&
  typeof value.type === 'string' &&
  typeof value.slice === 'function';

const isFile = (value: any): value is File =>
  value &&
  typeof value.size === 'number' &&
  typeof value.type === 'string' &&
  typeof value.slice === 'function' &&
  typeof value.name === 'string' &&
  (typeof value.lastModifiedDate === 'object' ||
    typeof value.lastModified === 'number');

export type Options = {
  indices?: boolean;
  nullsAsUndefineds?: boolean;
  booleansAsIntegers?: boolean;
  allowEmptyArrays?: boolean;
};
const defaultOption = {
  /**
   * include array indices in FormData keys
   * defaults to false
   */
  indices: false,

  /**
   * treat null values like undefined values and ignore them
   * defaults to false
   */
  nullsAsUndefineds: false,

  /**
   * convert true or false to 1 or 0 respectively
   * defaults to false
   */
  booleansAsIntegers: false,

  /**
   * store arrays even if they're empty
   * defaults to false
   */
  allowEmptyArrays: false,
};

interface EntrySerialize<T extends { [key: string]: T | any }>
  extends Object,
    FormData {}

const serialize = <T extends Object>(
  obj: T,
  options?: Options,
  existingFormData?: FormData,
  pre?: string,
): FormData => {
  const cfg = options || defaultOption;

  cfg.indices = isUndefined(cfg.indices) ? false : cfg.indices;

  cfg.nullsAsUndefineds = isUndefined(cfg.nullsAsUndefineds)
    ? false
    : cfg.nullsAsUndefineds;

  cfg.booleansAsIntegers = isUndefined(cfg.booleansAsIntegers)
    ? false
    : cfg.booleansAsIntegers;

  cfg.allowEmptyArrays = isUndefined(cfg.allowEmptyArrays)
    ? false
    : cfg.allowEmptyArrays;

  const fd = existingFormData || new FormData();

  if (isUndefined(obj)) {
    return fd;
  }
  if (isNull(obj)) {
    if (!cfg.nullsAsUndefineds) {
      fd.append(pre, '');
    }
  } else if (isBoolean(obj)) {
    if (cfg.booleansAsIntegers) {
      fd.append(pre, String(obj ? 1 : 0));
    } else {
      fd.append(pre, `${obj}`);
    }
  } else if (isArray(obj)) {
    if (obj.length) {
      obj.forEach((value, index) => {
        const key = `${pre}[${cfg.indices ? index : ''}]`;

        serialize(value, cfg, fd, key);
      });
    } else if (cfg.allowEmptyArrays) {
      fd.append(`${pre}[]`, '');
    }
  } else if (isDate(obj)) {
    fd.append(pre, obj.toISOString());
  } else if (isObject(obj) && !isFile(obj)) {
    if (!isBlob(obj)) {
      Object.keys(obj).forEach((prop) => {
        const value = (obj as any)[prop];
        let newKey = prop;
        if (isArray(value)) {
          while (
            newKey.length > 2 &&
            newKey.lastIndexOf('[]') === newKey.length - 2
          ) {
            newKey = newKey.substring(0, newKey.length - 2);
          }
        }

        const key = pre ? `${pre}[${newKey}]` : newKey;

        serialize(value, cfg, fd, key);
      });
    } else {
      fd.append(pre, obj);
    }
  } else {
    fd.append(pre, obj);
  }

  return fd;
};

export default serialize;
export type { EntrySerialize };
