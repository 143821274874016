import cx from 'classnames';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../../../../models/api/authentication/authentication.model';
import classes from './menu.module.scss';

interface Props {
  onHideDropdown: () => void;
  onLogout: () => void;
  user: User;
}

const MENU_KEY = {
  LOGOUT: 'LOGOUT',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  DETAIL: 'DETAIL',
};

const MenuCourier: FC<Props> = ({ onHideDropdown, onLogout, user }) => {
  const { t } = useTranslation('header');

  const onMenuClick = useCallback(
    (key) => {
      switch (key) {
        case MENU_KEY.LOGOUT: {
          onLogout();
          break;
        }
      }
      onHideDropdown();
    },
    [onHideDropdown, onLogout],
  );

  return (
    <div
      className={cx(
        'px-4 py-2 flex flex-col items-start h-auto',
        classes.menu,
      )}>
      <div
        className={cx(
          'hover:text-black flex flex-col break-words w-full px-0',
          classes.shopDetail,
          classes.menuItem,
        )}>
        <span className="text-gray-3 text-xs">{t('courierAccount')}</span>
        <span
          className={
            'text-dark-blue text-xl font-bold break-words whitespace-normal static w-64 left-0 top-1/4 bottom-0 leading-snug'
          }>
          {user?.username}
        </span>
      </div>
      <div className={cx('w-full h-px mt-3', classes.divider)} />
      <div className={cx('px-0', classes.menuItem)}>
        <span className="text-gray-1 font-bold text-xs block mt-4">
          {t('name')}
        </span>
        <div className={'text-gray-3 leading-snug text-base'}>{user?.name}</div>
      </div>
      <div className={cx('px-0', classes.menuItem)}>
        <span className={'text-gray-1 font-bold text-xs block mt-4'}>
          {t('email')}
        </span>
        <div className={'text-gray-3 leading-snug text-base'}>
          {user?.email}
        </div>
      </div>
      <div className={cx('px-0', classes.menuItem)}>
        <span className="text-gray-1 font-bold text-xs block mt-4">
          {t('phone')}
        </span>
        <div className={'text-gray-3 leading-snug text-base'}>
          {user?.phone}
        </div>
      </div>
      <button
        type={'button'}
        className={cx(
          'font-bold p-0 text-gray-3 block leading-snug mt-6 focus:outline-none',
        )}
        onClick={() => onMenuClick(MENU_KEY.LOGOUT)}>
        {t('logOut')}
      </button>
    </div>
  );
};

export default MenuCourier;
