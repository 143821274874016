import { call, put, takeLatest } from 'typed-redux-saga';
import { getListCollectionApi } from 'api/orderCollection.api';
import { RESULT_MESSAGES } from 'constants/common.const';
import { TYPE_ORDER } from 'constants/order';
import {
  OrderStatus,
  OrderDate,
} from 'models/api/orderCollection/orderCollection.model';
import { getListCollection, disableLoadMore } from './list.action';

function* handleGetListCollection(
  action: ReturnType<typeof getListCollection.request>,
) {
  const filterStatus =
    action.payload.type === TYPE_ORDER.COLLECT
      ? action.payload.filterStatusCollect
      : action.payload.filterStatusDelivery;

  let { filterDate } = action.payload;
  if (
    !action.payload.filterDate &&
    action.payload.type === TYPE_ORDER.COLLECT &&
    action.payload.filterStatusCollect === OrderStatus.COMPLETED.toString()
  ) {
    filterDate = OrderDate.TODAY;
  }
  if (
    !action.payload.filterDate &&
    action.payload.type === TYPE_ORDER.DELIVERY &&
    action.payload.filterStatusDelivery === OrderStatus.DELIVERED.toString()
  ) {
    filterDate = OrderDate.TODAY;
  }

  try {
    const response = yield* call(
      getListCollectionApi,
      action.payload.page,
      action.payload.type,
      action.payload.lang,
      filterDate,
      filterStatus,
      action.payload.filterRegion,
      action.payload.keyword,
    );
    if (
      response.result.code === 200 &&
      response.result.message === RESULT_MESSAGES.NO_RECORD_FOUND
    ) {
      yield put(disableLoadMore());
    } else if (
      response.result.code === 200 &&
      response.result.message !== RESULT_MESSAGES.NO_RECORD_FOUND
    ) {
      yield put(getListCollection.success(response.data));
    }
  } catch (e) {
    yield put(getListCollection.failure('Error'));
  }
}

export default function* orderCollectionSaga() {
  yield* takeLatest(getListCollection.request, handleGetListCollection);
}
