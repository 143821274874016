import cx, { Mapping } from 'classnames';
import React, {
  ChangeEvent,
  forwardRef,
  InputHTMLAttributes,
  MouseEvent,
  ReactElement,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';
import { useHistory } from 'react-router-dom';

export enum InputSize {
  Large = 'l',
  Medium = 'm',
  Small = 's',
  ExtraLarge = 'xl',
}

export type InputSizeType = typeof InputSize[keyof typeof InputSize];

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  renderPrefix?: (() => ReactElement) | ReactElement;
  renderSuffix?: (() => ReactElement) | ReactElement;
  isError?: boolean;
  inputClassName?: string;
  inputSize?: InputSizeType;
  classSuffix?: string;
  classPrefix?: string;
  label?: (() => ReactElement) | ReactElement | string;
  hasBorder?: boolean;
  id?: string;
  rounded?: boolean;
}

const Checkbox = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    isError = false,
    className,
    renderPrefix,
    renderSuffix,
    classSuffix,
    classPrefix,
    onChange,
    inputClassName,
    style,
    disabled,
    label,
    id,
    children,
    hasBorder = true,
    rounded = false,
    ...other
  } = props;
  const inputRef = useRef<HTMLInputElement>();
  const checkboxWrapperRef = useRef<HTMLDivElement>();
  const history = useHistory();

  const onMouseUp = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useImperativeHandle(ref, () => inputRef.current);

  const handleOnClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (inputRef?.current) {
        const event = Object.create(e);
        event.target = inputRef.current;
        event.currentTarget = inputRef.current;
        inputRef.current.checked = !inputRef.current.checked;
        onChange && onChange(event as ChangeEvent<HTMLInputElement>);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange, inputRef.current?.checked],
  );

  return (
    <>
      {label && (
        <span className="block text-sm font-normal text-gray-1">{label}</span>
      )}

      <div
        ref={checkboxWrapperRef}
        className={cx(
          'checkbox rounded border-gray-4 items-center flex bg-white cursor-pointer focus:bg-white',
          {
            hidden: props.type === 'hidden',
            error: isError && !inputRef?.current?.checked,
            'border-light-blue checked': inputRef?.current?.checked,
            'mt-1': label,
            'bg-gray-6 border-gray-4 text-gray-4 disabled': disabled,
            'border pr-2.5 pl-2.5': hasBorder,
            rounded,
          } as Mapping,
          className,
        )}
        style={style}
        onClick={handleOnClick}
        onMouseUp={onMouseUp}
        onTouchStart={() => {
          if (
            history.location.pathname !== '/auth/login' &&
            history.location.pathname !== '/order/new-order'
          ) {
            checkboxWrapperRef.current.click();
          }
        }}>
        {renderPrefix && (
          <span
            className={cx('renderPrefix inline-flex h-full mr-2', classPrefix)}>
            {renderPrefix}
          </span>
        )}
        <input
          className={cx(
            'focus:outline-none border-none bg-transparent outline-none  w-5 hidden opacity-0',
            inputClassName,
          )}
          type="checkbox"
          {...other}
          ref={inputRef}
          disabled={disabled}
          onChange={onChange}
          id={id}
        />
        <span className={cx('checkmark flex items-center justify-center')} />
        {children && (
          <div className={'ml-2.5 text-sm font-normal'}>{children}</div>
        )}
        {renderSuffix && (
          <span className={cx('renderSuffix inline-flex h-full', classSuffix)}>
            {renderSuffix}
          </span>
        )}
      </div>
    </>
  );
});

Checkbox.displayName = 'Checkbox';

export default Checkbox;
