import iconClose from './images/common/close.svg';
import iconCommonNavigateNext from './images/common/next.svg';
import iconCommonNavigatePrevious from './images/common/previous.svg';
import chinaLogo from './images/footer/china.svg';
import franceLogo from './images/footer/france.svg';
import germanyLogo from './images/footer/germany.svg';
import hongkongLogo from './images/footer/hongkong.svg';
import footerLogo from './images/footer/logo.png';
import separate from './images/footer/separate.svg';
import usaLogo from './images/footer/usa.svg';
import headerLogo from './images/header/logo.png';
import iconAccount from './images/icons/Account.svg';
import iconCalendar from './images/icons/Calendar.svg';
import iconMagnifyingGlass from './images/icons/MagnifyingGlass.svg';
import iconPlusCircleWhite from './images/icons/PlusCircleWhite.svg';
import loginLogo from './images/authentication/logo.png';
import iconDropDownArrow from './images/common/dropdownArrow.svg';
import iconCircleCheck from './images/common/circleCheck.svg';
import iconDelete from './images/common/delete.svg';
import iconLock from './images/icons/Lock.svg';
import iconBarcode from './images/common/brcode.svg';
import iconPhone from './images/icons/Phone.svg';
import iconFax from './images/icons/Fax.svg';
import iconEmail from './images/icons/Email.svg';
import iconDuplicateSuccess from './images/icons/DuplicateSuccess.svg';
import iconFieldCompleted from './images/icons/fieldCompleted.svg';
import iconUpdate from './images/icons/Update.svg';
import iconUpdated from './images/icons/Updated.svg';
import iconExpiryed from './images/icons/Expiryed.svg';
// order-collection
import qrCodelogo from './images/orderCollection/qrCodelogo.png';
import logoScan from './images/orderCollection/LogoScan-logo.png';
import separateNav from './images/orderCollection/separateNav.svg';
import approach from './images/orderCollection/approach.png';
import approachBlue from './images/orderCollection/approachBule.svg';
import glasses from './images/orderCollection/glasses.svg';
import iconCloseFilter from './images/icons/Close.svg';
import iconCloseWhite from './images/orderCollection/closeWhite.svg';
import dropdown from './images/orderCollection/dropdown.svg';
import dropup from './images/orderCollection/dropup.svg';
import lowerLeftCorner from './images/orderCollection/lowerLeftCorner.svg';
import lowerRightCorner from './images/orderCollection/lowerRightCorner.svg';
import upperLeftCorner from './images/orderCollection/upperLeftCorner.svg';
import upperRightCorner from './images/orderCollection/upperRightCorner.svg';
import iconSearch from './images/icons/Search.svg';
import iconExport from './images/common/export.svg';
import iconCompleted from './images/orderCollection/iconCompleted.svg';
import iconPickUp from './images/icons/PickUp.svg';
import iconSuccessCreate from './images/icons/SuccessCreate.svg';
import qrLogo from './images/orderCollection/qrLogo.svg';
import iconCameraSwitch from './images/icons/CameraSwitch.svg';

const images = {
  usaLogo,
  chinaLogo,
  hongkongLogo,
  germanyLogo,
  franceLogo,
  header: {
    logo: headerLogo,
  },
  authentication: {
    logo: loginLogo,
  },
  footer: {
    separate,
    logo: footerLogo,
  },
  icons: {
    iconAccount,
    iconCalendar,
    iconPlusCircleWhite,
    iconMagnifyingGlass,
    iconLock,
    iconPhone,
    iconFax,
    iconEmail,
    iconSearch,
    iconCloseFilter,
    iconCloseWhite,
    iconDuplicateSuccess,
    iconFieldCompleted,
    iconCameraSwitch,
    iconUpdate,
    iconUpdated,
    iconExpiryed,
  },
  common: {
    navigateNext: iconCommonNavigateNext,
    navigatePrevious: iconCommonNavigatePrevious,
    close: iconClose,
    dropdownArrow: iconDropDownArrow,
    circleCheck: iconCircleCheck,
    delete: iconDelete,
    barcode: iconBarcode,
    export: iconExport,
  },
  orderCollection: {
    qrLogo,
    qrCode: qrCodelogo,
    separateNav,
    approach,
    glasses,
    dropdown,
    dropup,
    approachBlue,
    lowerLeftCorner,
    lowerRightCorner,
    upperLeftCorner,
    upperRightCorner,
    logoScan,
    completed: iconCompleted,
  },
  order: {
    pickUp: iconPickUp,
    successCreate: iconSuccessCreate,
  },
};

export default images;
