import { createAsyncAction, createAction } from 'typesafe-actions';
import { Detail } from 'models/api/orderCollection/orderCollection.model';

interface DeliveryDetailPayload {
  orderId: string;
  lang: string;
}

export const getDeliveryDetail = createAsyncAction(
  'GET_DELIVERY_DETAIL_REQUESTED',
  'GET_DELIVERY_DETAIL_SUCCESS',
  'GET_DELIVERY_DETAIL_FAILURE',
)<DeliveryDetailPayload, Detail, string>();

export const clearDeliveryDetail = createAction(
  'CLEAR_DELIVERY_DETAIL',
)<void>();
