import { OrderHistoryResponse } from 'models/api/orderHistory/orderHistory.model';
import authorizedRequest from 'utils/request/authorizedRequest';

export function getOrderHistoryApi(
  startDate: string,
  endDate: string,
  page: number,
  sort: number,
  priority: number,
) {
  return authorizedRequest.get<OrderHistoryResponse, OrderHistoryResponse>(
    'sales_order/getOrderHistory',
    {
      params: { startDate, endDate, page, sort, priority },
    },
  );
}
