import { call, delay, put, takeLatest } from 'typed-redux-saga';
import {
  getAssemblingOrderApi,
  getAssemblingOrderByKeywordApi,
} from 'api/order.api';
import { identity, pickBy } from 'lodash';
import { RESULT_MESSAGES } from 'constants/common.const';
import { getList } from './list.action';

function* handleGetList(action: ReturnType<typeof getList.request>) {
  try {
    yield* delay(500);
    const payload = pickBy(action.payload, identity);
    let response;
    if (payload.keyword) {
      response = yield* call(
        getAssemblingOrderByKeywordApi,
        payload.keyword,
        payload.page,
      );
    } else {
      response = yield* call(
        getAssemblingOrderApi,
        payload.page,
        payload.sort,
        payload.priority,
      );
    }
    if (response.result.message === RESULT_MESSAGES.NO_RECORD_FOUND) {
      yield* put(
        getList.success({
          data: [],
          meta: null,
        }),
      );
    } else {
      yield* put(
        getList.success({
          data: response.data,
          meta: response.metadata,
        }),
      );
    }
  } catch (e) {
    yield* put(getList.failure());
  }
}

export default function* listSaga() {
  yield* takeLatest(getList.request, handleGetList);
}
