import authorizedRequest from '../utils/request/authorizedRequest';
import { ChangePasswordResponse } from '../models/api/changePassword/changePassword.model';

export function changePasswordApi(
  currentPassword: string,
  newPassword: string,
) {
  return authorizedRequest.put<ChangePasswordResponse, ChangePasswordResponse>(
    'user/changePassword',
    {
      currentPassword,
      newPassword,
    },
  );
}
