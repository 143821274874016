import images from '../assets/images';

export enum I18nNamespace {
  COMMON = 'common',
}

export enum Languages {
  ENGLISH = 'en',
  SIMPLE_CHINESE = 'zh',
  TRADITIONAL_CHINESE = 'tw',
  FRANCE = 'fr',
  GERMANY = 'de',
}

export const LanguageConfig = [
  {
    lang: Languages.TRADITIONAL_CHINESE,
    img: images.hongkongLogo,
    name: 'Hong Kong',
    index: 2,
  },
  {
    lang: Languages.SIMPLE_CHINESE,
    img: images.chinaLogo,
    name: 'China',
    index: 3,
  },
  {
    lang: Languages.ENGLISH,
    img: images.usaLogo,
    name: 'USA',
    index: 1,
  },
  // {
  //   lang: Languages.FRANCE,
  //   img: images.franceLogo,
  //   name: 'France',
  //   index: 4,
  // },
  // {
  //   lang: Languages.GERMANY,
  //   img: images.germanyLogo,
  //   name: 'Germany',
  //   index: 5,
  // },
];
