import React, { FC, useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { COLORS } from 'constants/color.const';
import Close from 'components/common/svg/Close/Close';
import cx from 'classnames';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import FormikCheckbox from '../../../../components/formik/Checkbox/FormikCheckbox';
import FormikInput from '../../../../components/formik/Input/FormikInput';
import Button from '../../../../components/ui/Button/Button';
import { changePassword } from '../../../../store/password/password.action';
import { CHANGE_PASSWORD_MESSAGE } from '../../../../constants/password.const';
import classes from './password.module.scss';

interface Props {
  visible: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

interface FormValue {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  showPass: boolean;
}

const initValue: FormValue = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
  showPass: false,
};

const ChangePassword: FC<Props> = ({ visible, onClose, onSuccess }) => {
  const { t } = useTranslation(['password', 'validation']);
  const [dataChanged, setDataChanged] = useState(false);
  const dispatch = useDispatch();
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const handleClose = useCallback(() => {
    setIncorrectPassword(false);
    onClose();
  }, [onClose]);

  const onSuccessResult = useCallback(() => {
    onSuccess();
  }, [onSuccess]);

  const onSubmit = useCallback(
    (value: FormValue) => {
      setDataChanged(false);
      dispatch(
        changePassword.request({
          currentPassword: value.currentPassword,
          newPassword: value.newPassword,
          onCallBack: (e: string) => {
            if (e === CHANGE_PASSWORD_MESSAGE.success) {
              onClose();
              onSuccessResult();
            } else {
              setIncorrectPassword(true);
            }
          },
        }),
      );
    },
    [dispatch, onClose, onSuccessResult],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        currentPassword: Yup.string(),
        newPassword: Yup.string()
          .required(
            t('validation:required', { field: t('password:newPassword') }),
          )
          .matches(
            /^(?=.*[a-z])(?=.*[0-9])(?=.{6,})/,
            t('password:mustContainSpecialCases'),
          )
          .notOneOf(
            [Yup.ref('currentPassword'), null],
            t('password:mustDifferent'),
          ),
        confirmPassword: Yup.string()
          .required(
            t('validation:required', { field: t('password:confirmPassword') }),
          )
          .oneOf([Yup.ref('newPassword'), null], t('password:doNotMatch')),
      }),
    [t],
  );

  return (
    <>
      {visible && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className={cx('relative mx-auto', classes.wrapper)}>
              <div className="border-gray-5 rounded relative flex flex-col w-full bg-white outline-none focus:outline-none h-full w-full pt-10 pb-12 px-16">
                <h1 className={'text-2xl text-dark-blue font-bold'}>
                  {t('password:changePassword')}
                </h1>

                <Formik
                  initialValues={initValue}
                  onSubmit={onSubmit}
                  validateOnChange={false}
                  validationSchema={validationSchema}>
                  {({
                    handleSubmit,
                    values,
                    setFieldError,
                  }: FormikProps<FormValue>) => (
                    <form onSubmit={handleSubmit} className={'mt-5'}>
                      <div className={'mt-1'}>
                        <FormikInput
                          labelClassName={'text-base'}
                          className={'h-11'}
                          name={'currentPassword'}
                          type={'password'}
                          onChange={() => {
                            setDataChanged(true);
                            setIncorrectPassword(false);
                          }}
                          label={t('password:currentPassword')}
                          passwordVisible={values.showPass}
                          isError={incorrectPassword}
                          clearable
                          showError={true}
                        />
                      </div>
                      {incorrectPassword && (
                        <div className={'relavtive'}>
                          <p
                            className={
                              'error-text text-red-700 text-xs absolute'
                            }>
                            {t('password:incorrectPassword')}
                          </p>
                        </div>
                      )}
                      <div className={'mt-5'}>
                        <FormikInput
                          labelClassName={'text-base'}
                          className={'h-11'}
                          name={'newPassword'}
                          type={'password'}
                          onChange={() => {
                            setDataChanged(true);
                            setFieldError('newPassword', '');
                          }}
                          label={t('password:newPassword')}
                          passwordVisible={values.showPass}
                          clearable
                          showError={true}
                        />
                      </div>
                      <div className={'mt-5'}>
                        <FormikInput
                          labelClassName={'text-base'}
                          className={'h-11'}
                          name={'confirmPassword'}
                          type={'password'}
                          onChange={() => {
                            setDataChanged(true);
                            setFieldError('confirmPassword', '');
                          }}
                          label={t('password:confirmPassword')}
                          passwordVisible={values.showPass}
                          clearable
                          showError={true}
                        />
                      </div>
                      <div className={'flex items-center mt-2'}>
                        <FormikCheckbox name={'showPass'} hasBorder={false}>
                          <span className="text-sm font-normal text-gray-3">
                            {t('password:showPassword')}
                          </span>
                        </FormikCheckbox>
                      </div>

                      <Button
                        type={'submit'}
                        className={cx('w-full h-11 mt-2')}
                        disabled={!dataChanged}>
                        {t('password:save')}
                      </Button>
                    </form>
                  )}
                </Formik>
                <button
                  type={'button'}
                  onClick={handleClose}
                  className={cx(
                    'absolute top-6 right-6 rounded focus:outline-none',
                    classes.btn,
                  )}>
                  <Close className={'w-5 h-5'} fill={COLORS.gray[4]} />
                </button>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-gray-1" />
        </>
      )}
    </>
  );
};

export default ChangePassword;
