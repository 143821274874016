import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { ContentType } from 'models/api/content/content/content.model';
import Content from 'components/Footer/Content/Content';
import classes from 'containers/app/Sitemap/sitemap.module.scss';

const Sitemap = () => {
  const { t } = useTranslation('footer');

  return (
    <>
      <div className={cx('md:mx-4 lg:mx-auto xl:mx-auto', classes.sitemap)}>
        <div className={'text-sm '}>
          <span className={'text-gray-3 mr-2'}>
            <Link to="/">{t('home')}</Link>
          </span>
          <span className={'text-gray-3 mr-2'}>{'>'}</span>
          <span className={'text-gray-3'}>{t('sitemap')}</span>
        </div>
        <div className={'mt-11.5px'}>
          <h1 className={'text-4xl text-dark-blue font-bold'}>
            {t('sitemap')}
          </h1>
          <div
            className={
              'container bg-white border rounded-sm px-8 pt-5 pb-8 mt-2.5 mb-5'
            }>
            <h3 className={'text-xl text-dark-blue font-bold'}>Swisscoat</h3>
            <div className={'mt-2'}>
              <span className={cx('text-base mr-1', classes.linkText)}>
                <Link to="/about">{t('about')} </Link>
              </span>
              <span className={'text-gray-300'}>|</span>
              <span className={cx('text-base ml-1 mr-1', classes.linkText)}>
                <Link to="/contact"> {t('contact')} </Link>
              </span>
              <span className={'text-gray-300'}>|</span>
              <span
                className={cx(
                  'text-base ml-1 mr-1 cursor-pointer',
                  classes.linkText,
                )}>
                <Content
                  contentType={ContentType.PRODUCT_LIST}
                  title={t('productList')}
                />
              </span>
            </div>
            <h3 className={'text-xl text-dark-blue font-bold mt-10'}>
              {t('mainFunction')}
            </h3>
            <div className={'mt-2'}>
              <span className={cx('text-base mr-1', classes.linkText)}>
                <Link to="/order/new-order">{t('newOrder')} </Link>
              </span>
              <span className={'text-gray-300'}>|</span>
              <span className={cx('text-base ml-1 mr-1', classes.linkText)}>
                <Link to="/order-history">{t('orderHistory')}</Link>
              </span>
            </div>
            <h3 className={'text-xl text-dark-blue font-bold mt-10'}>
              {t('whereToBuy')}
            </h3>
            <div className={'mt-2'}>
              <span className={cx('text-base mr-1', classes.linkText)}>
                <Link to="/about">{t('about')} </Link>
              </span>
              <span className={'text-gray-300'}>|</span>
              <span className={cx('text-base ml-1 mr-1', classes.linkText)}>
                <Link to="/latest-news">{t('latestNews')} </Link>
              </span>
              <span className={'text-gray-300'}>|</span>
              <span className={cx('text-base ml-1 mr-1', classes.linkText)}>
                <a
                  href="https://www.swisscoat.com/eyes-care-information/"
                  target="_blank"
                  rel="noreferrer noopener">
                  {t('eyeCareIns')}
                </a>
              </span>
            </div>
            <h3 className={'text-xl text-dark-blue font-bold mt-10'}>Policy</h3>
            <div className={'mt-2'}>
              <span className={cx('text-base mr-1', classes.linkText)}>
                <Link to="/terms">{t('termsAndConditions')} </Link>
              </span>
              <span className={'text-gray-300'}>|</span>
              <span className={cx('text-base ml-1 mr-1', classes.linkText)}>
                <Link to="/privacy"> {t('privacyPolicy')} </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sitemap;
