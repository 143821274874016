import images from 'assets/images';
import React, { FC, memo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getContentLogo } from 'api/content.api';
import cx from 'classnames';
import { User } from 'models/api/authentication/authentication.model';
import classes from './header-new.module.scss';

interface Props {
  user: User;
  bigSize?: boolean;
}

const HeaderNew: FC<Props> = ({ user, bigSize }) => {
  const [source, setSource] = useState<any>('');
  const [country, setCountry] = useState('hk');
  const lang = localStorage.getItem('i18nextLng');

  useEffect(() => {
    if (lang === 'en') {
      setCountry('os');
    } else if (lang === 'tw') {
      setCountry('hk');
    } else {
      setCountry('cn');
    }

    getContentLogo(country).then((res) => {
      setSource(res?.data);
    });
  }, [country, lang]);

  return (
    <>
      <div
        style={{ height: !bigSize ? 60 : 80, lineHeight: !bigSize ? 60 : 80 }}
        className="bg-white rounded-sm border-b sticky top-0 z-40">
        <div className="container md:max-w-700px xl:max-w-1170px 2xl:max-w-1280px lg:max-w-980px 3xl:max-w-1536px mx-auto flex items-center justify-center px-2 md:px-0">
          <Link to={'/'}>
            <img
              style={{
                width: !bigSize ? 148.2 : 216.13,
                height: !bigSize ? 48 : 70,
              }}
              src={source?.logo?.filepath}
              alt={'Logo'}
            />
          </Link>
        </div>
      </div>
    </>
  );
};

export default memo(HeaderNew);
