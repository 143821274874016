import React, { ComponentType, FC } from 'react';
import { useSelector } from 'react-redux';
import {
  AppRouteConst,
  AuthRouteConst,
  OrderRouteConst,
} from 'constants/route.const';
import { Redirect, useLocation } from 'react-router-dom';

export default function withAuthenticate(InnerComponent: ComponentType<any>) {
  const WithAuthenticate: FC<any> = (props) => {
    const pagesWithoutAuth: string[] = [
      AppRouteConst.DISCLAIMER,
      AppRouteConst.PRIVACY,
      AppRouteConst.TERMS_AND_CONDITIONS,
      OrderRouteConst.ORDER_EDIT,
    ];
    const token = useSelector((store) => store.authentication.token);
    const location = useLocation();
    return (
      <>
        {!pagesWithoutAuth.includes(location.pathname) &&
        (!token || token === '') ? (
          <Redirect to={AuthRouteConst.SIGN_IN} />
        ) : (
          <InnerComponent {...props} />
        )}
      </>
    );
  };

  return WithAuthenticate;
}
