export const TYPE_BUTTON = {
  WARNING: 'warning',
  SUCCESS: 'success',
};
export const TYPE_STATUS = {
  PENDING: 'Pending',
  COLLECTED: 'Collected',
};
export const TYPE_DELIVERY_STATUS = {
  PENDING: 'Pending',
  COMPLETE: 'Delivered',
};

export const BASE_URL = 'https://swisscoat-dev.legato.co/api/v1/';

export const TYPE_COLLECTION_STATUS = {
  PENDING: 'Pending',
  COMPLETE: 'Collected',
};

export const TYPE_ORDER = {
  COLLECT: 1,
  DELIVERY: 2,
};

export const INPUT = {
  LENGTH_CODE: 6,
};

export enum ORDER_TYPE {
  LENS_AND_FRAME = 1,
  LENS_ONLY = 2,
}

export enum FILE_TYPE {
  XLS_FILE = 1,
  CSV_FILE = 2,
}

export enum TARGET_LAYER {
  TYPE = 2,
  INDEX = 3,
  FUNCTION_1 = 4,
  FUNCTION_2 = 5,
  DESIGN_COLLECTION = 6,
  MATERIAL_COLOR = 7,
  PREMIUM_COATING = 8,
  BACKSIDE_AR = 9,
  IMPACT_FREE = 10,
  MIRROR = 11,
}

export enum MATERIAL_TYPE {
  SAFEWEAR = 5,
  MINERAL_GLASS = 6,
  PLASTIC = 7,
  ACTIFWEAR = 8,
}

export enum SECTION {
  ORDER_INFORMATION = 'Order Information',
  PATIENT_INFORMATION = 'Patient Information',
  ADDITION_SERVICE = 'Addition Service',
  SERVICE_ITEM_CHARGE = 'Service Item Charge',
  FRAME_INFORMATION = 'Frame Information',
  EXTRA_REMARKS = 'Extra Remarks',
}

export enum MAX_VALUE {
  RECORD_PER_PAGE = 15,
}
