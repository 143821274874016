import React, { FC, useState } from 'react';
import cx from 'classnames';
import images from 'assets/images';
import classes from './style.module.scss';

interface Props {
  location: number;
  title: string;
  content1?: string;
  content2?: string;
}
const SildeToggle: FC<Props> = ({ content1, content2, location, title }) => {
  const [visible, setVisible] = useState(true);

  return (
    <div
      className={cx('p-1 border-b border-gray-5 ', classes.list_code_item, {
        'bg-white': location % 2 === 0,
      })}
      onClick={() => setVisible(!visible)}>
      <div
        className={cx('flex justify-between leading-default', {
          'bg-white': location % 2 === 0,
        })}>
        <span className="text-gray-1 text-lg font-fat pl-3">#{title}</span>
        <button type="button" className="outline-none">
          {visible ? (
            <img src={images.orderCollection.dropdown} alt="dropdown" />
          ) : (
            <img
              src={images.orderCollection.dropup}
              alt="dropdown"
              style={{ paddingRight: '6px' }}
            />
          )}
        </button>
      </div>
      <div className={cx('px-1', { hidden: visible })}>
        <p>{content1}</p>
        <p>{content2}</p>
      </div>
    </div>
  );
};

export default SildeToggle;
