import { combineReducers } from 'redux';
import { reducer as listOrderReducer } from './list/list.reducer';
import { reducer as saleOrderDetailReducer } from './detail/detail.reducer';
import assemblingOrderReducer from './assemblingOrder/assemblingOrder.reducer';

const orderReducer = combineReducers({
  list: listOrderReducer,
  assemblingOrder: assemblingOrderReducer,
  detail: saleOrderDetailReducer,
});

export default orderReducer;
