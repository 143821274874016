import { createReducer } from 'typesafe-actions';
import { ShopStore } from '../../models/store/shops/shops.model';
import { getAllShops, clearShops } from './shops.action';

const INIT_STATE: ShopStore = {
  loading: true,
  shops: [],
  error: '',
};

export const reducer = createReducer(INIT_STATE)
  .handleAction(getAllShops.request, (state) => ({
    ...state,
    loading: true,
    shops: [],
    error: '',
  }))
  .handleAction(getAllShops.success, (state, action) => ({
    ...state,
    loading: false,
    shops: action.payload,
    error: '',
  }))
  .handleAction(getAllShops.failure, (state, action) => ({
    ...state,
    loading: false,
    shops: [],
    error: action.payload,
  }))
  .handleAction(clearShops, (state) => ({
    ...state,
    shops: [],
    error: '',
  }));

export default reducer;
