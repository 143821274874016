import { createAsyncAction } from 'typesafe-actions';

export interface ChangePasswordPayload {
  currentPassword: string;
  newPassword: string;
  onCallBack: (e: string) => void;
}

export const changePassword = createAsyncAction(
  'CHANGE_PASSWORD_REQUEST',
  'CHANGE_PASSWORD_SUCCESS',
  'CHANGE_PASSWORD_FAILURE',
)<ChangePasswordPayload, string, string>();
