import React from 'react';

function Minus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="22"
      height="4"
      viewBox="0 0 22 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M21.6666 3.32919L21.6666 0.670464L12.3293 0.670534L9.67062 0.670535L0.333299 0.670623L0.333299 3.32934L9.67062 3.32926L12.3293 3.32926L21.6666 3.32919Z"
        fill={props.fill || '#00579E'}
      />
    </svg>
  );
}

export default Minus;
