import { all, fork, takeLatest } from '@redux-saga/core/effects';
import { createNewOrder } from 'api/order.api';
import { FormValue } from 'components/Order/CreateOrder/Form/form.helper';
import { formatDate } from 'helpers/datetime.helper';
import { identity, omit, pickBy, set } from 'lodash';
import assemblingOrderSaga from 'store/order/assemblingOrder/assemblingOrder.saga';
import { createOrder } from 'store/order/order.action';
import { call } from 'typed-redux-saga';
import listSaga from './list/list.saga';
import detailSaga from './detail/detail.saga';

function* createOrderSaga(action: ReturnType<typeof createOrder.request>) {
  try {
    let form = pickBy(action.payload.form, identity) as FormValue;

    form = {
      ...form,
      isEmergency: action.payload.form.isEmergency,
      ...(action.payload.form.rIsSt === 1 && { rIsRx: 0, rIsSt: 1 }),
      ...(action.payload.form.rIsSt === 0 && { rIsRx: 1, rIsSt: 0 }),
      ...(action.payload.form.lIsSt === 1 && { lIsRx: 0, lIsSt: 1 }),
      ...(action.payload.form.lIsSt === 0 && { lIsRx: 1, lIsSt: 0 }),
      isSwisscoatFrame: action.payload.form.isSwisscoatFrame,
      ...((action.payload.form.frameBoxQty !== null ||
        action.payload.form.frameBoxQty !== undefined) && {
        frameBoxQty: action.payload.form.frameBoxQty,
      }),
      requestDate: formatDate(new Date(form.requestDate), 'yyyy-MM-dd'),
    };

    if (!form.different_lens_eye) {
      form = {
        ...form,
        lProductId: form.rProductId,
        lIsImpactfree: form.rIsImpactfree,
        lIsMirror: form.rIsMirror,
        lIsBacksideAr: form.rIsBacksideAr,
        lTintWsCoatId: form.rTintWsCoatId,
        lPolarizedWsCoatId: form.rPolarizedWsCoatId,
        lDriveWsCoatId: form.rDriveWsCoatId,
        ...(form.rIsMc && { lIsMc: form.rIsMc }),
        ...(form.rIsMv && { lIsMv: form.rIsMv }),
        lTntTypeId: form.rTntTypeId,
        lTntRemarks: form.rTntRemarks,
        lTntDepth: form.rTntDepth,
        lTntColour: form.rTntColour,
        lTntUpperColor: form.rTntUpperColor,

        lTntLowerColor: form.rTntLowerColor,

        lCoatColorId: form.rCoatColorId,
        lCoatRemarks: form.rCoatRemarks,
      };
    }
    
    if (form !== undefined) {
      if (form?.rIsMirror === 'null') {
        delete form?.rIsMirror;
      } else {
        form.rIsMirror = '1';
      }

      if (form?.lIsMirror === 'null') {
        delete form?.lIsMirror;
      } else {
        form.lIsMirror = '1';
      }

      if (form?.rIsBacksideAr === 'null') {
        delete form?.rIsBacksideAr;
      } else {
        form.rIsBacksideAr = '1';
      }

      if (form?.lIsBacksideAr === 'null') {
        delete form?.lIsBacksideAr;
      } else {
        form.lIsBacksideAr = '1';
      }

      if (form?.rIsImpactfree === 'null') {
        delete form?.rIsImpactfree;
      } else {
        form.rIsImpactfree = '1';
      }

      if (form?.lIsImpactfree === 'null') {
        delete form?.lIsImpactfree;
      } else {
        form.lIsImpactfree = '1';
      }

      if(form?.orderType === 'null' || form?.orderType === null|| form?.orderType === undefined) {
        form.orderType = 2;
      }
    }

    const formData = omit(form, 'images');
    if (form.images) {
      form.images.forEach((i, index) => {
        set(formData, `photo${index + 1}`, i);
      });
    }

    const data = yield* call(createNewOrder, formData);

    if (data.result.code >= 200 && data.result.code < 300) {
      yield call(action.payload.onSuccess, data.data.id);
    } else {
      throw new Error(JSON.stringify(data));
    }
  } catch (e) {
    yield call(action.payload.onFail, e);
  }
}

export default function* orderSaga() {
  yield all([takeLatest(createOrder.request, createOrderSaga)]);
  yield fork(listSaga);
  yield fork(assemblingOrderSaga);
  yield fork(detailSaga);
}
