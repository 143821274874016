import { DeliveryDetailStore } from 'models/store/orderDelivery/detail/detail.model';
import { createReducer } from 'typesafe-actions';
import { getDeliveryDetail, clearDeliveryDetail } from './detail.action';

const INIT_STATE: DeliveryDetailStore = {
  loading: false,
  detail: {},
  error: '',
};

export const reducer = createReducer(INIT_STATE)
  .handleAction(getDeliveryDetail.request, (state) => ({
    ...state,
    loading: true,
    detail: {},
    error: '',
  }))
  .handleAction(getDeliveryDetail.success, (state, action) => ({
    ...state,
    loading: false,
    detail: action.payload,
    error: '',
  }))
  .handleAction(getDeliveryDetail.failure, (state, action) => ({
    ...state,
    loading: false,
    detail: {},
    error: action.payload,
  }))
  .handleAction(clearDeliveryDetail, (state) => ({
    ...state,
    detail: {},
    error: '',
  }));
