import { CollectioneDetailStore } from 'models/store/orderCollection/detail/detail.model';
import { createReducer } from 'typesafe-actions';
import { getCollectionDetail, clearCollectionDetail } from './detail.action';

const INIT_STATE: CollectioneDetailStore = {
  loading: false,
  detail: {},
  error: '',
};

export const reducer = createReducer(INIT_STATE)
  .handleAction(getCollectionDetail.request, (state) => ({
    ...state,
    loading: true,
    detail: {},
    error: '',
  }))
  .handleAction(getCollectionDetail.success, (state, action) => ({
    ...state,
    loading: false,
    detail: action.payload,
    error: '',
  }))
  .handleAction(getCollectionDetail.failure, (state, action) => ({
    ...state,
    loading: false,
    detail: {},
    error: action.payload,
  }))
  .handleAction(clearCollectionDetail, (state) => ({
    ...state,
    detail: {},
    error: '',
  }));
