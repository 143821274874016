import { MainRouteConst } from 'constants/route.const';
import { RouteProps } from 'react-router';
import AppLayout from 'layouts/app/app.layout';
import AuthLayout from 'layouts/auth/auth.layout';
import withAuthenticate from '../hocs/withAuthenticate';

const routes: RouteProps[] = [
  {
    path: MainRouteConst.AUTH,
    component: AuthLayout,
  },
  {
    path: MainRouteConst.APP,
    component: withAuthenticate(AppLayout),
  },
];

export default routes;
