import React, { FC, useState, useEffect } from 'react';
import { getContentApi } from 'api/content.api';

interface Props {
  title: string;
  contentType: number;
}

const ContentCp: FC<Props> = ({ contentType, title }) => {
  const [source, setSource] = useState<string>('');

  useEffect(() => {
    let mounted = true;
    getContentApi(contentType).then((r) => {
      if (r) {
        const url =
          r.data?.filepath.slice(0, r.data?.filepath.length - 1) +
          r.data?.filename;
        if (mounted) {
          setSource(url);
        }
      }
    });
    return () => {
      mounted = false;
    };
  }, [contentType]);

  return (
    <a href={source} target={'_blank'} rel="noopener noreferrer">
      {title}
    </a>
  );
};

export default ContentCp;
