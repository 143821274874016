import { AuthenticationStore } from 'models/store/authentication/authentication.model';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createReducer } from 'typesafe-actions';
import { doLogin, doLogout, logoutAction } from './authentication.action';

const INIT_STATE: AuthenticationStore = {
  loading: false,
  user: null,
  error: '',
  token: '',
};

const authenticationReducer = createReducer(INIT_STATE)
  .handleAction(doLogin.request, (state) => ({
    ...state,
    loading: true,
    user: null,
    error: '',
    token: '',
  }))
  .handleAction(doLogin.success, (state, action) => ({
    ...state,
    loading: false,
    user: action.payload,
    error: '',
    token: action.payload.access_token,
  }))
  .handleAction(doLogin.failure, (state, action) => ({
    ...state,
    user: null,
    error: action.payload,
    loading: false,
    token: '',
  }))
  .handleAction(doLogout.success, (state) => ({
    ...state,
    user: null,
    token: '',
  }))
  .handleAction(logoutAction, (state) => ({
    ...state,
    user: null,
    token: '',
  }));

export default persistReducer(
  { key: 'auth', storage, whitelist: ['token', 'user'] },
  authenticationReducer,
);
