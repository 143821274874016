import { ListCollectionStore } from 'models/store/orderCollection/list/list.model';
import { createReducer } from 'typesafe-actions';
import {
  getListDelivery,
  disableLoadMore,
  clearListDelivery,
} from './list.action';

const INIT_STATE: ListCollectionStore = {
  loading: false,
  info: [],
  error: '',
  loadingNext: false,
  hasMore: true,
  textSearch: '',
};

export const reducer = createReducer(INIT_STATE)
  .handleAction(getListDelivery.request, (state, action) => ({
    ...state,
    info: action.payload.page === 1 ? [] : [...state.info],
    loading: action.payload.page === 1,
    hasMore: true,
    error: '',
    textSearch: '',
  }))
  .handleAction(getListDelivery.success, (state, action) => ({
    ...state,
    loading: false,
    info: [...state.info, ...action.payload],
    error: '',
    hasMore: action.payload.length < 15 ? false : state.hasMore,
  }))
  .handleAction(disableLoadMore, (state) => ({
    ...state,
    loading: false,
    hasMore: false,
  }))
  .handleAction(getListDelivery.failure, (state, action) => ({
    ...state,
    loading: false,
    info: [],
    error: action.payload,
    hasMore: false,
  }))
  .handleAction(clearListDelivery, (state) => ({
    ...state,
    info: [],
    error: '',
    hasMore: false,
  }));
