import { format, parseISO } from 'date-fns';

const FORMAT = {
  DATE: 'dd MMM yyyy',
  TIME: 'H:mm',
  DATETIME: 'dd/M/yyyy - H:mm',
  TIMEAMPM: 'hh:mm a',
};

export function formatDate(date: Date, dateFormat?: string) {
  return format(date, dateFormat || FORMAT.DATE);
}

export function formatTime(date: Date) {
  return format(date, FORMAT.TIME);
}

export function formatTimeAMPM(date: Date) {
  return format(date, FORMAT?.TIMEAMPM);
}

export function formatDateTime(date: Date) {
  return format(date, FORMAT?.DATETIME);
}

export function formatDateISO(
  dateStr: string | undefined,
  dateFormat?: string,
) {
  if (dateStr) {
    const dateObj = parseISO(dateStr);
    return formatDate(dateObj, dateFormat);
  }
  return '';
}

export function LocalDatetime(value: number | string): number {
  const GMT = -new Date().getTimezoneOffset() / 60;
  let timestamp: number = 0;
  if (typeof value === 'string') {
    let date: Date = new Date(value);
    date = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds(),
    );
    timestamp = date.getTime() / 1000 + GMT * 60 * 60;
  } else timestamp = value / 1000;
  return timestamp;
}

export function getDate(
  _date: number | string,
  format:
    | 'dd/mm/yyyy'
    | 'dd-mm-yyyy'
    | 'yyyy-mm-dd'
    | 'dd thg mm, yyyy'
    | string,
  use_utc_0: boolean = true,
): string {
  const date = use_utc_0
    ? new Date(LocalDatetime(_date) * 1000)
    : new Date(_date);
  const D = date.getDate();
  const M = date.getMonth() + 1;
  const dd = D < 10 ? `0${D}` : D;
  const mm = M < 10 ? `0${M}` : M;
  const yyyy = date.getFullYear();

  // replace
  let result: string = format;
  result = result.replace('D', D.toString());
  result = result.replace('M', D.toString());
  result = result.replace('dd', dd.toString());
  result = result.replace('mm', mm.toString());
  result = result.replace('yyyy', yyyy.toString());

  return result;
}
export function getTime(
  _date: number | string,
  format: 'hh:mm' | 'hh:mm:ss' | string,
  use_utc_0: boolean = true,
): string {
  const date = use_utc_0
    ? new Date(LocalDatetime(_date) * 1000)
    : new Date(_date);

  // /*Time */
  const H = date.getHours();
  const M = date.getMinutes();
  const S = date.getSeconds();
  const hh = H < 10 ? `0${H}` : H;
  const mm = M < 10 ? `0${M}` : M;
  const ss = S < 10 ? `0${S}` : S;

  // replace
  let result: string = format;
  result = result.replace('H', H.toString());
  result = result.replace('M', M.toString());
  result = result.replace('S', S.toString());
  result = result.replace('hh', hh.toString());
  result = result.replace('mm', mm.toString());
  result = result.replace('ss', ss.toString());

  return result;
}
