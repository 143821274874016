import images from 'assets/images';
import cx, { Mapping } from 'classnames';
import React, {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  MouseEvent,
  ReactElement,
  TextareaHTMLAttributes,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

export enum InputType {
  STANDARD = 'standard',
  NORMAL = 'normal',
}

export enum InputSize {
  Large = 'l',
  Medium = 'm',
  Small = 's',
  ExtraLarge = 'xl',
}

export type InputSizeType = typeof InputSize[keyof typeof InputSize];

export interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  renderPrefix?: (() => ReactElement) | ReactElement;
  renderSuffix?: (() => ReactElement) | ReactElement;
  isError?: boolean;
  inputType?: typeof InputType[keyof typeof InputType];
  inputClassName?: string;
  labelClassName?: string;
  inputSize?: InputSizeType;
  classSuffix?: string;
  classPrefix?: string;
  label?: (() => ReactElement) | ReactElement | string;
  clearable?: boolean;
}

const TextArea = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const {
    isError = false,
    inputType = InputType.STANDARD,
    className,
    renderPrefix,
    renderSuffix,
    classSuffix,
    classPrefix,
    inputClassName,
    style,
    disabled,
    label,
    labelClassName,
    clearable = false,
    cols = 4,
    rows = 4,
    ...other
  } = props;
  const inputRef = useRef<HTMLTextAreaElement>();
  const [isFocus, setIsFocus] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const onMouseUp = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const onClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, []);
  useImperativeHandle(ref, () => inputRef.current);

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setIsTyping(true);
      if (other.onChange) {
        other.onChange(e);
      }
    },
    [other],
  );

  const onBlur = useCallback(
    (e: FocusEvent<HTMLTextAreaElement>) => {
      setIsFocus(false);
      setIsTyping(false);
      if (other.onBlur) {
        other.onBlur(e);
      }
    },
    [other],
  );

  const onClear = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (inputRef.current) {
        const event = Object.create(e);
        event.target = inputRef.current;
        event.currentTarget = inputRef.current;
        inputRef.current.value = '';
        other.onChange(event as ChangeEvent<HTMLTextAreaElement>);
      }
    },
    [other],
  );

  return (
    <>
      {label && (
        <span
          className={cx(
            'font-normal text-sm block text-gray-1',
            labelClassName,
          )}>
          {label}
        </span>
      )}

      <div
        className={cx(
          'textarea border rounded border-gray-4 items-center flex bg-white py-1',
          {
            normal: inputType === InputType.NORMAL,
            'border-light-blue focus': isFocus && !isTyping,
            'mt-1': label,
            'bg-gray-6 border-gray-4 disabled': disabled,
            'border-error': isError && !isTyping,
          } as Mapping,
          className,
        )}
        style={style}
        onMouseUp={onMouseUp}
        onClick={onClick}>
        {renderPrefix && (
          <span
            className={cx(
              'renderPrefix inline-flex h-full mr-2 items-center',
              classPrefix,
            )}>
            {renderPrefix}
          </span>
        )}
        <textarea
          cols={cols}
          rows={rows}
          className={cx(
            'focus:outline-none inline-flex border-none bg-transparent w-full outline-none',
            inputClassName,
          )}
          {...other}
          ref={inputRef}
          disabled={disabled}
          onFocus={() => setIsFocus(true)}
          onBlur={onBlur}
          onChange={handleOnChange}
        />
        {clearable && other.value !== '' && (
          <button type={'button'} onClick={onClear}>
            <img src={images.common.close} alt={'Close Icon'} />
          </button>
        )}
        {renderSuffix && (
          <span
            className={cx(
              'renderSuffix inline-flex h-full items-center',
              classSuffix,
            )}>
            {renderSuffix}
          </span>
        )}
      </div>
    </>
  );
});

TextArea.displayName = 'TextArea';

export default TextArea;
