import { combineReducers } from 'redux';
import { reducer as listOrderReducer } from './list/list.reducer';
import { reducer as detailOrderReducer } from './detail/detail.reducer';

const orderCollectionReducer = combineReducers({
  list: listOrderReducer,
  detail: detailOrderReducer,
});

export default orderCollectionReducer;
