export const MainRouteConst = {
  AUTH: '/auth',
  APP: '/',
  ORDER: '/order',
  COURIER_ORDER: '/courier/order',
};

export const AuthRouteConst = {
  SIGN_IN: `${MainRouteConst.AUTH}/login`,
};

export const AppRouteConst = {
  HOME: '/',
  ABOUT: '/about',
  DISCLAIMER: '/disclaimer',
  PRIVACY: '/privacy',
  TERMS_AND_CONDITIONS: '/terms',
  SITEMAP: '/sitemap',
  SHOP: '/shop',
  CONTACT_US: '/contact',
  LATEST_NEWS: '/latest-news',
  LATEST_NEWS_DETAIL: '/latest-news/:id',
  ORDER_HISTORY: '/order-history',
};

export const OrderRouteConst = {
  NEW_ORDER: `${MainRouteConst.ORDER}/new-order`,
  ASSEMBLING_ORDER: `${MainRouteConst.ORDER}/assembling-order`,
  ORDER_DETAIL: `${MainRouteConst.ORDER}/:id`,
  ORDER_EDIT: `${MainRouteConst.ORDER}/edit`,
};

export const CourierOrderRouteConst = {
  ORDER_COLLECTION: `${MainRouteConst.COURIER_ORDER}/collection`,
  ORDER_DELIVERY: `${MainRouteConst.COURIER_ORDER}/delivery`,
  ORDER_COLLECTION_DETAIL: `${MainRouteConst.COURIER_ORDER}/collection/:id`,
  ORDER_DELIVERY_DETAIL: `${MainRouteConst.COURIER_ORDER}/delivery/:id`,
};
