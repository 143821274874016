import { call, put, takeLatest } from 'typed-redux-saga';
import { getAllShopsApi } from '../../api/shops.api';
import { getAllShops } from './shops.action';
import { GET_SHOP_MESSAGE } from '../../constants/shops.const';

function* handleGetAllShops(action: ReturnType<typeof getAllShops.request>) {
  try {
    const response = yield* call(getAllShopsApi, action.payload.token);

    if (response.result.message === GET_SHOP_MESSAGE.success) {
      yield* put(getAllShops.success(response.data));
    } else {
      yield* put(getAllShops.failure(response.result.message));
    }
  } catch (e) {
    yield* put(getAllShops.failure('Error'));
  }
}

export default function* shopSaga() {
  yield* takeLatest(getAllShops.request, handleGetAllShops);
}
