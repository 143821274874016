import {
  addRemarksApi,
  completeCollectOrderApi,
  pickUpParcelCollectOrderApi,
  completeCollectOrderByPasswordApi,
  completeCollectOrderByQrCodeApi,
} from 'api/orderCollection.api';
import images from 'assets/images';
import cx from 'classnames';
import Button, { ButtonType } from 'components/ui/Button/Button';
import TextArea from 'components/ui/Textarea/TextArea';
import { COLORS } from 'constants/color.const';
import { TYPE_BUTTON } from 'constants/order';
import { CourierOrderRouteConst } from 'constants/route.const';
import SlideToggle from 'containers/app/Common/SildeToggle';
import { getDate, getTime } from 'helpers/datetime.helper';
import { capitalize } from 'lodash';
import {
  DeliveryStatus,
  OrderStatus,
} from 'models/api/orderCollection/orderCollection.model';
import { ResultType, ToastIcon } from 'models/entities/common.entity';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import {
  getDeliveryDetail,
  clearDeliveryDetail,
} from '../../../store/orderDelivery/detail/detail.action';
import ModalCollection from '../Common/ModalCollection';
import Toast from '../Common/toast/Toast';
import classes from './style.module.scss';

const DeliveryDetail = () => {
  const { t } = useTranslation('order');
  const { i18n } = useTranslation();
  const { id } = useParams<any>();
  const history = useHistory();
  const deliveryDetail = useSelector((store) => store.orderDelivery.detail);
  const [visibleCollection, setVisibleCollection] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [completeVisible, setCompleteVisible] = useState(false);
  const [errorResult, setErrorResult] = useState('');
  const [icon, setIcon] = useState<ToastIcon>();
  const [contentRemarks, setContentRemarks] = useState('');
  const inputTextArea = useRef<HTMLDivElement>();
  const [isInputRemarks, setIsInputRemarks] = useState(false);
  const [isAddMarks, setIsAddMarks] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    dispatch(
      getDeliveryDetail.request({
        orderId: id,
        lang: i18n.language,
      }),
    );
    return () => {
      dispatch(clearDeliveryDetail());
    };
  }, [dispatch, i18n.language, id]);

  useEffect(() => {
    if (deliveryDetail.error !== '') {
      history.replace(CourierOrderRouteConst.ORDER_DELIVERY);
    }
  }, [deliveryDetail.error, history]);

  const handleAddRemarks = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContentRemarks(e.target?.value);
  };

  const handleListenClick = useCallback(
    async (e: MouseEvent) => {
      if (!inputTextArea?.current?.contains(e.target as Node)) {
        if (isInputRemarks) {
          const result = await addRemarksApi(id, contentRemarks.trim());
          if (result.result.code === 200) {
            setIsInputRemarks(false);
          }
        }
      }
    },
    [contentRemarks, id, isInputRemarks],
  );

  useEffect(() => {
    window.addEventListener('mousedown', handleListenClick);
    return () => {
      window.removeEventListener('mousedown', handleListenClick);
    };
  }, [handleListenClick]);

  useEffect(() => {
    if (
      deliveryDetail.detail.remarks &&
      deliveryDetail?.detail?.remarks.length !== 0
    ) {
      setContentRemarks(deliveryDetail.detail?.remarks);
      setIsAddMarks(true);
    }
  }, [deliveryDetail.detail?.remarks]);

  const handleAddMarks = () => {
    setIsAddMarks(true);
    setIsInputRemarks(true);
  };

  const handleEditTextArea = () => {
    setIsInputRemarks(true);
  };

  const onCloseModal = useCallback(() => {
    setVisibleCollection(false);
  }, []);

  const onPickUpParcel = useCallback(() => {
    pickUpParcelCollectOrderApi(id)
      .then((result) => {
        if (result) {
          setSuccess(true);
          setIcon(ToastIcon.DELIVERY);
          setErrorResult('');
          setCompleteVisible(true);
          setLoading(true);
          dispatch(
            getDeliveryDetail.request({
              orderId: id,
              lang: i18n.language,
            }),
          );
        }
      })
      .catch((err) => {
        if (err) {
          setSuccess(false);
          setErrorResult(t('NotResultFound'));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, i18n.language, id, t]);

  const typeButton =
    deliveryDetail.detail?.status === DeliveryStatus.DELIVERED
      ? TYPE_BUTTON.SUCCESS
      : TYPE_BUTTON.WARNING;

  const telHref = `tel:+${deliveryDetail?.detail?.phone}`;

  const onCompleteByPassword = useCallback(
    (event: string) => {
      setLoading(true);
      const body = { password: event };
      completeCollectOrderByPasswordApi(id, body)
        .then((result) => {
          if (result?.result?.code === 200) {
            setSuccess(true);
            setIcon(ToastIcon.SUCCESS);
            setCompleteVisible(true);
            setVisibleCollection(false);
            dispatch(
              getDeliveryDetail.request({
                orderId: id,
                lang: i18n.language,
              }),
            );
          }
        })
        .catch((err) => {
          setSuccess(false);
          setErrorResult(t('NotResultFound'));
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [id, dispatch, i18n.language, t],
  );

  const onCompleteByQrCode = useCallback(
    (event: string) => {
      setLoading(true);
      const body = { qrCode: event };
      completeCollectOrderByQrCodeApi(id, body)
        .then((result) => {
          if (result) {
            setSuccess(true);
            setIcon(ToastIcon.SUCCESS);
            setVisibleCollection(false);
            setCompleteVisible(true);
            dispatch(
              getDeliveryDetail.request({
                orderId: id,
                lang: i18n.language,
              }),
            );
          }
        })
        .catch((err) => {
          if (err) {
            setSuccess(false);
            setErrorResult(t('NotResultFound'));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [id, dispatch, i18n.language, t],
  );

  const onComplete = useCallback(() => {
    setLoading(true);
    completeCollectOrderApi(id)
      .then((r) => {
        setSuccess(true);
        setCompleteVisible(true);
        setVisibleCollection(false);
        setIcon(ToastIcon.SUCCESS);
        dispatch(
          getDeliveryDetail.request({
            orderId: id,
            lang: i18n.language,
          }),
        );
      })
      .catch((e) => {
        setSuccess(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, dispatch, i18n.language]);

  const content = useMemo(() => {
    const isDelivery = !!deliveryDetail?.detail?.phone;
    const isDelivering =
      deliveryDetail?.detail?.status === DeliveryStatus.DELIVERING;
    const isDelivered =
      deliveryDetail?.detail?.status === DeliveryStatus.DELIVERED;
    let content = `#${deliveryDetail?.detail?.phone} `;
    if (isDelivery && isDelivering) {
      content += t('CollectionDetail.SuccessOrderPickedUp');
    } else if (isDelivery && isDelivered) {
      content += t('CollectionDetail.SuccessOrderDelivered');
    } else {
      content += t('CollectionDetail.FailureOrderCollected');
    }
    return content;
  }, [t, deliveryDetail]);

  const typeRes = useMemo(() => {
    const isDelivery = deliveryDetail?.detail?.phone;
    return isDelivery ||
      deliveryDetail?.detail?.status === OrderStatus.COMPLETED
      ? ResultType.SUCCESS
      : ResultType.FAILURE;
  }, [deliveryDetail]);

  const msgButton = useMemo(() => {
    let msg = t('DeliveryDetail.PickUpParcel');
    if (deliveryDetail.detail.status === DeliveryStatus.DELIVERING) {
      msg = t('complete');
    }

    if (deliveryDetail.detail.status === DeliveryStatus.DELIVERED) {
      msg = t('CollectionDetail.BackToHomepage');
    }
    return msg;
  }, [t, deliveryDetail]);

  const handleActionWithDelivery = useCallback(() => {
    if (deliveryDetail?.detail?.status === DeliveryStatus.DELIVERED) {
      history.push('/courier/order/delivery');
    } else {
      typeRes === ResultType.SUCCESS &&
      deliveryDetail?.detail.status === DeliveryStatus.DELIVERING
        ? setVisibleCollection(!visibleCollection)
        : onPickUpParcel();
    }
  }, [
    deliveryDetail?.detail.status,
    history,
    typeRes,
    visibleCollection,
    onPickUpParcel,
  ]);

  return (
    <>
      {deliveryDetail?.detail?.status && success && (
        <Toast
          time={3000}
          visible={completeVisible}
          icon={icon}
          type={typeRes}
          content={content}
          always={false}
        />
      )}

      <ModalCollection
        visible={visibleCollection}
        onRemoveResult={() => setErrorResult('')}
        setModalVisible={onCloseModal}
        onComplete={onComplete}
        errorResult={errorResult}
        onCompleteByPassword={onCompleteByPassword}
        onCompleteByQrCode={onCompleteByQrCode}
      />
      <div className={'relative bg-blue-50'}>
        {deliveryDetail.loading ? (
          <div className={'fixed top-1/4 flex w-full justify-center'}>
            <ScaleLoader color={COLORS.blue.DEFAULT} />
          </div>
        ) : (
          <div className={cx('', classes.order_collection_detail)}>
            <div>
              <div
                className={cx('bg-white py-3', classes.order_collection_item)}>
                <div className="flex justify-between mb-2">
                  <div className="flex-col">
                    <p className="text-gray-2 font-fat">
                      {t('DeliveryFrames')}
                    </p>
                    <p className="text-gray-3">
                      {t('FieldCollection.RequestdDate')} :{' '}
                      {deliveryDetail?.detail?.pickupDate &&
                        getDate(
                          new Date(deliveryDetail.detail.pickupDate).getTime(),
                          'yyyy-mm-dd',
                        )}
                    </p>
                    <p className="text-gray-3">
                      {t('FieldCollection.OfficeHour')} :{' '}
                      {deliveryDetail?.detail?.officeHourFrom &&
                        getTime(
                          new Date(
                            deliveryDetail?.detail?.officeHourFrom,
                          ).getTime(),
                          'hh-mm',
                        )}
                      -
                      {deliveryDetail?.detail?.officeHourTo &&
                        getTime(
                          new Date(
                            deliveryDetail?.detail?.officeHourTo,
                          ).getTime(),
                          'hh-mm',
                        )}
                    </p>
                  </div>
                  <button
                    type="button"
                    className={cx('font-bold', classes[typeButton])}>
                    {capitalize(DeliveryStatus[deliveryDetail?.detail?.status])}
                  </button>
                </div>
                <div className="flex-col">
                  <p className="text-dark-blue font-bold">
                    {deliveryDetail?.detail?.region}
                  </p>
                  <p className="font-fat text-gray-1 text-medium uppercase">
                    {deliveryDetail?.detail?.name}
                  </p>
                  <p className="text-gray-3 text-xs leading-5">
                    {deliveryDetail?.detail?.address}
                  </p>
                  <p className="underline text-blue font-fat">
                    <a href={telHref}>
                      {t('CollectionDetail.ContactNo')}
                      {deliveryDetail?.detail?.phone}
                    </a>
                  </p>
                </div>
              </div>
              <div
                className={cx(
                  'border bg-blue-50 p-3 border-b-0',
                  classes.extra_item,
                )}>
                <div className={cx('mt-1', classes.code_item)}>
                  <p className="leading-8 text-dark-blue font-bold flex">
                    <span className="text-2xl">
                      #{deliveryDetail?.detail?.refNo}
                    </span>
                    <img
                      src={images.orderCollection.glasses}
                      alt="icon-glasses"
                      className="mt-1 ml-2 mr-1"
                    />
                    <span className="mt-1 text-xl text-light-blue">
                      {deliveryDetail?.detail?.salesOrderCnt
                        ? `x${deliveryDetail?.detail?.salesOrderCnt}`
                        : `${0}`}
                    </span>
                  </p>
                  <div
                    className={cx(
                      'border border-gray-5 rounded-md mt-2 overflow-hidden',
                      classes.list_order,
                    )}>
                    {deliveryDetail?.detail?.salesOrder &&
                      deliveryDetail?.detail?.salesOrder.map((item, key) => (
                        <div key={Number(key)} className="delivery_sales_order">
                          <SlideToggle
                            location={key}
                            title={item.edgeNo}
                            content1={item.lensProduct.left}
                            content2={item.lensProduct.right}
                          />
                        </div>
                      ))}
                  </div>
                  <div className="w-full mt-5 flex flex-col">
                    {!isAddMarks ? (
                      <span
                        className={cx('font-bold underline', classes.blueColor)}
                        onClick={handleAddMarks}>
                        {t('CollectionDetail.AddRemarks')}
                      </span>
                    ) : (
                      <div>
                        <div className="flex justify-between">
                          <h3 className={'font-fat text-gray-1 text-sm mb-1'}>
                            {t('CollectionDetail.Remarks')}
                          </h3>
                          {!isInputRemarks && (
                            <span
                              className="underline text-blue font-fat mb-1 text-sm pt-0.5 leading-default"
                              onClick={handleEditTextArea}>
                              {t('CollectionDetail.Edit')}
                            </span>
                          )}
                        </div>
                        <div ref={inputTextArea}>
                          <TextArea
                            value={contentRemarks}
                            onChange={handleAddRemarks}
                            rows={4}
                            className={'rounded'}
                            readOnly={!isInputRemarks}
                          />
                        </div>
                      </div>
                    )}
                    <Button
                      buttonType={
                        deliveryDetail?.detail?.status ===
                        DeliveryStatus.DELIVERED
                          ? ButtonType.Outline
                          : ButtonType.Primary
                      }
                      style={{ width: '100%', height: '45px' }}
                      className="my-3"
                      onClick={() => handleActionWithDelivery()}>
                      {loading ? (
                        <ScaleLoader color={COLORS['dark-blue']} />
                      ) : (
                        msgButton
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DeliveryDetail;
