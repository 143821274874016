import { CommonResponse, Result } from '../common.model';

export type CollectionResponse = CommonResponse<Info[]>;

export interface Info {
  id: any;
  refNo: string;
  companyName: string;
  address: string;
  region: string;
  salesOrderCnt: number;
  status: any;
  requestDate: string;
  shopName: string;
  pickupDate: string;
  updatedDate: string;
  officeHourFrom: string;
  officeHourTo: string;
}

export type ResultResponse = CommonResponse<Result>;

export type ResultResponseNew = CommonResponse<Result>;

export type CollectionDetailResponse = CommonResponse<Detail>;

export type RegionResponse = CommonResponse<Region[]>;
export interface Detail {
  id?: string;
  refNo?: string;
  address?: string;
  region?: string;
  phone?: string;
  salesOrderCnt?: 0;
  status?: number;
  pickupDate?: string;
  name?: string;
  extraCnt?: number;
  remarks?: string;
  salesOrder?: TypeSalesOrder[];
  officeHourFrom?: string;
  officeHourTo?: string;
}

export interface Region {
  id: number;
  name: string;
}
export interface TypeSalesOrder {
  edgeNo: string;
  lensProduct: {
    left: string;
    right: string;
  };
}

export enum OrderDate {
  ALL = 1,
  THIS_3_DAYS = 2,
  TODAY = 3,
}

export enum OrderRegion {
  KNOWLOON_BAY = 1,
  CHOI_HUNG = 2,
  DIAMOND_HILL = 3,
}

export enum OrderStatus {
  PENDING = 1,
  DELIVERING = 2,
  COMPLETED = 3,
  DELIVERED = 3,
}

export enum DeliveryStatus {
  PENDING = 1,
  DELIVERING = 2,
  DELIVERED = 3,
}
