import { createAction, createAsyncAction } from 'typesafe-actions';
import { Detail } from 'models/api/orderCollection/orderCollection.model';

interface collectionDetailPayload {
  orderId: string;
  lang: string;
}

export const getCollectionDetail = createAsyncAction(
  'GET_COLLECTION_DETAIL_REQUESTED',
  'GET_COLLECTION_DETAIL_SUCCESS',
  'GET_COLLECTION_DETAIL_FAILURE',
)<collectionDetailPayload, Detail, string>();

export const clearCollectionDetail = createAction(
  'CLEAR_COLLECTION_DETAIL',
)<void>();
