import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { stringify } from 'query-string';
import i18n from 'i18next';
import { tokenManager } from 'utils/request/tokenManager';
import { AuthRouteConst } from 'constants/route.const';
import { LOGIN_MESSAGE } from 'constants/authencation.const';
import { LanguageConfig } from 'constants/i18n.const';
import history from '../../helpers/history.helper';
import CONFIG from '../../config';

const authorizedRequest: AxiosInstance = axios.create({
  baseURL: CONFIG.BASE_URL,

  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  paramsSerializer: (params) => stringify(params, { arrayFormat: 'separator' }),
});

authorizedRequest.interceptors.request.use((config: AxiosRequestConfig) => {
  const newConfig = { ...config };
  const { token } = tokenManager;
  if (token) {
    newConfig.headers.common['Access-Token'] = token;
  }

  if (['GET', 'get'].includes(config.method)) {
    newConfig.params = {
      ...newConfig.params,
      ...(newConfig.params?.language !== undefined
        ? {
            lang: LanguageConfig.find(
              (i) => i.lang === newConfig.params?.language,
            )?.index,
          }
        : {
            lang:
              LanguageConfig.find((i) => i.lang === i18n.language)?.index || 1,
          }),
    };
  }

  if (config.shouldUseFlatData) {
    newConfig.data = config.data;
  } else {
    newConfig.data = stringify(config.data);
  }

  return newConfig;
});

authorizedRequest.interceptors.response.use(
  (response: AxiosResponse) => {
    if (
      response &&
      response.data?.result?.code === 400 &&
      response.data?.result?.message === LOGIN_MESSAGE.notLoggedIn
    ) {
      history.push(AuthRouteConst.SIGN_IN);
    }

    return response.data;
  },
  (error: AxiosError<any>) => {
    if (
      error &&
      error.response &&
      error.response.data?.result?.code === 400 &&
      (error.response.data?.result?.message === LOGIN_MESSAGE.notLoggedIn ||
        error.response.data?.result?.message === LOGIN_MESSAGE.loggedOut)
    ) {
      tokenManager.doLogout();
      // eslint-disable-next-line
    }
    return Promise.reject(error.response);
  },
);

export default authorizedRequest;
