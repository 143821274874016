import {
  CollectionDetailResponse,
  ResultResponse,
} from 'models/api/orderCollection/orderCollection.model';
import {
  OrderStatus,
  OrderDate,
  CollectionResponse,
} from '../models/api/orderCollection/orderCollection.model';

import authorizedRequest from '../utils/request/authorizedRequest';

export function getListCollectionApi(
  page: number,
  type: number,
  lang: string,
  filterDate: string | OrderDate,
  filterStatus?: string[] | string | OrderStatus[] | OrderStatus,
  filterRegion?: string[],
  keyword?: string,
) {
  const url = `/courier/order/filtered`;
  return authorizedRequest.get<CollectionResponse, CollectionResponse>(url, {
    params: {
      page,
      filterDate,
      filterRegion,
      filterStatus,
      keyword,
      type,
      lang,
    },
  });
}

export function getCollectionDetailApi(orderId: string, lang: string) {
  return authorizedRequest.get<
    CollectionDetailResponse,
    CollectionDetailResponse
  >(`/courier/order/id/${orderId}`, {
    params: {
      lang,
    },
  });
}
export function getCollectionDetailByQrCodeApi(qrCode: string, type?: number) {
  return authorizedRequest.get<
    CollectionDetailResponse,
    CollectionDetailResponse
  >(`/courier/order/getByQRCode`, {
    params: {
      qrCode,
      type,
    },
  });
}

export function addExtraSalesApi(orderId: string, extraCnt: number) {
  return authorizedRequest.put<ResultResponse, ResultResponse>(
    `/courier/order/id/${orderId}`,
    {
      extraCnt,
    },
  );
}

export function addRemarksApi(orderId: string, remarks: string) {
  return authorizedRequest.put<ResultResponse, ResultResponse>(
    `/courier/order/${orderId}/addRemarks`,
    {
      remarks,
    },
  );
}

interface CompleteCollectOrderApiByPasswordBody {
  password: string;
}

export function completeCollectOrderByPasswordApi(
  orderId?: string,
  body?: CompleteCollectOrderApiByPasswordBody,
) {
  return authorizedRequest.put<ResultResponse, ResultResponse>(
    `/courier/order/${orderId}/completeByPassword`,
    body,
  );
}

interface CompleteCollectOrderApiByQrCodeBody {
  qrCode: string;
}

export function completeCollectOrderByQrCodeApi(
  orderId: string,
  body?: CompleteCollectOrderApiByQrCodeBody,
) {
  return authorizedRequest.put<ResultResponse, ResultResponse>(
    `/courier/order/${orderId}/completeByQrCode`,
    body,
  );
}

export function pickUpParcelCollectOrderApi(orderId: string) {
  return authorizedRequest.put<ResultResponse, ResultResponse>(
    `/courier/order/${orderId}/pickupParcel`,
  );
}

export function completeCollectOrderApi(orderId: string) {
  return authorizedRequest.put<ResultResponse, ResultResponse>(
    `/courier/order/${orderId}/complete`,
  );
}
