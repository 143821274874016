import React, { FC, useCallback } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import Button, { ButtonType } from 'components/ui/Button/Button';
import classes from './change-language.module.scss';

interface Props {
  visible: boolean;
  onClose: () => void;
  handleChangeLang: (lang: string) => void;
  newLanguage: string;
}

const ChangeLanguagePopup: FC<Props> = ({
  visible,
  onClose,
  handleChangeLang,
  newLanguage,
}) => {
  const { t } = useTranslation(['auth', 'order', 'common']);
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleChange = useCallback(() => {
    handleChangeLang(newLanguage);
    onClose();
  }, [handleChangeLang, newLanguage, onClose]);

  return (
    <>
      {visible && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className={cx('relative mx-auto', classes.wrapper)}>
              <div className="border-gray-5 rounded relative flex flex-col w-full bg-white outline-none focus:outline-none h-full w-full p-7.5">
                <h1 className={'text-2xl text-dark-blue font-bold'}>
                  {t('common:changeLanguage')}
                </h1>
                <span className={'mt-1 text-gray-1 font-normal'}>
                  {t('common:confirmChange')}
                </span>

                <div className={'flex mt-7.5'}>
                  <Button
                    className={cx(
                      'flex-grow whitespace-nowrap',
                      classes.changeBtn,
                    )}
                    onClick={handleChange}>
                    {t('common:change')}
                  </Button>
                  <Button
                    className={cx(
                      'flex-grow whitespace-nowrap',
                      classes.cancelBtn,
                    )}
                    buttonType={ButtonType.Outline}
                    onClick={handleClose}>
                    {t('common:cancel')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-gray-1" />
        </>
      )}
    </>
  );
};

export default ChangeLanguagePopup;
