import { createAction, createAsyncAction } from 'typesafe-actions';
import { User } from '../../models/api/authentication/authentication.model';

interface LoginPayload {
  username: string;
  password: string;
}

export const doLogin = createAsyncAction(
  'DO_LOGIN_REQUESTED',
  'DO_LOGIN_SUCCESS',
  'DO_LOGIN_FAILURE',
)<LoginPayload, User, string>();

export const doLogout = createAsyncAction(
  'LOGOUT_REQUESTED',
  'LOGOUT_SUCCESS',
  'LOGOUT_FAILURE',
)<void, void, void>();

export const logoutAction = createAction('LOGOUT_ACTION')<void>();
