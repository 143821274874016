import { createAction, createAsyncAction } from 'typesafe-actions';
import {
  Info,
  OrderStatus,
} from 'models/api/orderCollection/orderCollection.model';
import { OrderDate } from '../../../models/api/orderCollection/orderCollection.model';

interface ListDeliveryPayload {
  page: number;
  type?: number;
  filterDate: OrderDate | string;
  filterRegion?: string[];
  filterStatusCollect?: OrderStatus | string | string[];
  filterStatusDelivery?: OrderStatus | string | string[];
  textSearch?: string;
  keyword?: string;
  lang: string;
}

export const getListDelivery = createAsyncAction(
  '@orderDelivery/GET_LIST_DELIVERY_REQUESTED',
  '@orderDelivery/GET_LIST_DELIVERY_SUCCESS',
  '@orderDelivery/GET_LIST_DELIVERY_FAILURE',
)<ListDeliveryPayload, Info[], string>();

export const disableLoadMore = createAction(
  `@orderDelivery/LIST/DISABLE_LOAD_MORE`,
)<void>();

export const clearListDelivery = createAction(
  '@orderDeliver/CLEAR_LIST_DELIVERY',
)<void>();
