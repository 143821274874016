import React, { FC, useRef } from 'react';
import cx from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import classes from './success-popup.module.scss';
import images from '../../../assets/images';

interface Props {
  visible: boolean;
  successType: string;
}

const SuccessConfig = [
  {
    type: 'password',
    image: images.icons.iconLock,
    text: 'passwordChangedSuccessfully',
  },
  {
    type: 'contact message',
    image: images.icons.iconEmail,
    text: 'messageSentSuccessfully',
  },
  {
    type: 'duplicateDetail',
    image: images.icons.iconDuplicateSuccess,
    text: 'orderHasBeenDuplicated',
  },
];

const SuccessPopup: FC<Props> = ({ visible, successType }) => {
  const { t } = useTranslation('popup');
  const Popup = useRef(null);

  const messageType = SuccessConfig.find((i) => i.type === successType);

  const onImageLoad = () => {
    Popup.current.classList.remove('hidden');
  };

  return (
    <>
      {visible && messageType && (
        <div
          ref={Popup}
          className="hidden justify-center items-end flex overflow-x-hidden overflow-y-auto fixed inset-x-0 bottom-16 z-50 outline-none focus:outline-none">
          <div className={cx('fixed rounded bg-blue-1 p-5', classes.snackBar)}>
            <img
              onLoad={() => onImageLoad()}
              className={'mr-5 inline-block mb-1'}
              src={messageType.image}
              alt={'lock-icon'}
            />
            <span className={'text-xl text-white'}>
              <Trans t={t}>{messageType.text}</Trans>
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default SuccessPopup;
