import { call, put, takeLatest } from 'typed-redux-saga';
import { getOrderHistoryApi } from '../../api/orderHistory.api';
import { getOrderHistory } from './order-history.action';
import { RESULT_MESSAGES } from '../../constants/common.const';

function* handleGetOrderHistory(
  action: ReturnType<typeof getOrderHistory.request>,
) {
  try {
    const response = yield* call(
      getOrderHistoryApi,
      action.payload.startDate,
      action.payload.endDate,
      action.payload.page,
      action.payload.sort,
      action.payload.priority,
    );

    if (response.result.message === RESULT_MESSAGES.NO_RECORD_FOUND) {
      yield* put(getOrderHistory.success({ data: [], meta: null }));
    } else {
      yield* put(
        getOrderHistory.success({
          data: response.data,
          meta: response.metadata,
        }),
      );
    }
  } catch (e) {
    yield* put(getOrderHistory.failure());
  }
}

export default function* orderHistorySaga() {
  yield* takeLatest(getOrderHistory.request, handleGetOrderHistory);
}
