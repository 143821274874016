import { createAction, createAsyncAction } from 'typesafe-actions';
import {
  Info,
  OrderStatus,
} from 'models/api/orderCollection/orderCollection.model';
import { OrderDate } from '../../../models/api/orderCollection/orderCollection.model';

interface ListCollectionPayload {
  page: number;
  type?: number;
  filterDate: OrderDate | string;
  filterRegion?: string[];
  filterStatusCollect?: OrderStatus | string | string[];
  filterStatusDelivery?: OrderStatus | string | string[];
  textSearch?: string;
  keyword?: string;
  lang: string;
}

export const getListCollection = createAsyncAction(
  '@orderCollection/GET_LIST_COLLECTION_REQUESTED',
  '@orderCollection/GET_LIST_COLLECTION_SUCCESS',
  '@orderCollection/GET_LIST_COLLECTION_FAILURE',
)<ListCollectionPayload, Info[], string>();

export const disableLoadMore = createAction(
  `@orderCollection/LIST/DISABLE_LOAD_MORE`,
)<void>();

export const completeStatus = createAsyncAction(
  'COMPLETE_STATUS_REQUESTED',
  'COMPLETE_STATUS_SUCCESS',
  'COMPLETE_STATUS_FAILURE',
)<any, void, void>();

export const clearListCollection = createAction(
  '@orderCollection/CLEAR_LIST_COLLECTION',
)<void>();
