import images from 'assets/images';
import cx, { Mapping } from 'classnames';
import React, {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  InputHTMLAttributes,
  MouseEvent,
  ReactElement,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import Mandatory from '../Mandatory/Mandatory';

export enum InputType {
  STANDARD = 'standard',
  NORMAL = 'normal',
}

export enum InputSize {
  Large = 'l',
  Medium = 'm',
  Small = 's',
  ExtraLarge = 'xl',
}

export type InputSizeType = typeof InputSize[keyof typeof InputSize];

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  renderPrefix?: (() => ReactElement) | ReactElement;
  renderSuffix?: (() => ReactElement) | ReactElement;
  isError?: boolean;
  inputType?: typeof InputType[keyof typeof InputType];
  inputClassName?: string;
  labelClassName?: string;
  inputSize?: InputSizeType;
  classSuffix?: string;
  classPrefix?: string;
  label?: (() => ReactElement) | ReactElement | string;
  passwordVisible?: boolean;
  clearable?: boolean;
  hasBorder?: boolean;
  mandatory?: boolean;
  setLang?: any;
}

const Input = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    isError = false,
    inputType = InputType.STANDARD,
    passwordVisible = false,
    className,
    renderPrefix,
    renderSuffix,
    classSuffix,
    classPrefix,
    inputClassName,
    style,
    disabled,
    label,
    labelClassName,
    clearable = false,
    type = 'text',
    hasBorder = true,
    inputMode = 'text',
    autoFocus = false,
    mandatory = false,
    setLang = false,
    ...other
  } = props;
  const inputRef = useRef<HTMLInputElement>();
  const [isFocus, setIsFocus] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const onMouseUp = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const onClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, []);
  useImperativeHandle(ref, () => inputRef.current);

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setIsTyping(true);
      if (other.onChange) {
        other.onChange(e);
      }
    },
    [other],
  );
  const onBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      setIsFocus(false);
      setIsTyping(false);
      if (other.onBlur) {
        other.onBlur(e);
      }
    },
    [other],
  );

  const onClear = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (inputRef.current) {
        const event = Object.create(e);
        event.target = inputRef.current;
        event.currentTarget = inputRef.current;
        inputRef.current.value = '';
        other.onChange(event as ChangeEvent<HTMLInputElement>);
      }
    },
    [other],
  );

  const currentInputType = useMemo(() => {
    if (type === 'password' && passwordVisible) {
      return 'text';
    }
    return type;
  }, [passwordVisible, type]);

  return (
    <>
      {label && (
        <span
          className={cx(
            'font-normal text-sm block text-gray-1 whitespace-nowrap mb-1',
            labelClassName,
          )}>
          {label}
          {mandatory ? <Mandatory /> : ''}
        </span>
      )}

      <div
        className={cx(
          'input items-center flex bg-#F2F2F2',
          {
            'border rounded border-gray-4 ': hasBorder,
            normal: inputType === InputType.NORMAL,
            hidden: props.type === 'hidden',
            'border-light-blue focus': isFocus && !isTyping,
            'bg-gray-6 border-gray-4 text-gray-4 disabled': disabled,
            'border-error': isError && !isTyping,
            'mt-1': label,
          } as Mapping,
          className,
        )}
        style={style}
        onMouseUp={onMouseUp}
        onClick={onClick}>
        {renderPrefix && (
          <span
            className={cx(
              'renderPrefix inline-flex h-full mr-2 items-center text-sm',
              classPrefix,
            )}>
            {renderPrefix}
          </span>
        )}
        <input
          type={currentInputType}
          className={cx(
            'focus:outline-none inline-flex border-none bg-transparent w-full outline-none flex-grow',
            inputClassName,
          )}
          {...other}
          ref={inputRef}
          disabled={disabled}
          onFocus={() => setIsFocus(true)}
          onBlur={onBlur}
          onChange={handleOnChange}
          inputMode={inputMode}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
        />
        {clearable && other.value !== '' && (
          <button type={'button'} onClick={onClear}>
            <img src={images.common.close} alt={'Close Icon'} />
          </button>
        )}
        {renderSuffix && (
          <span
            className={cx(
              'renderSuffix inline-flex h-full items-center text-sm',
              classSuffix,
            )}>
            {renderSuffix}
          </span>
        )}
      </div>
    </>
  );
});

Input.displayName = 'Input';

export default Input;
