export const GET_SHOP_MESSAGE = {
  success: 'Operation Success',
  invalid: 'Invalid Supplied',
  notExist: 'The company is not existed',
};

export const LENS_LAYER_NO = {
  MATERIAL: 1,
  TYPE: 2,
  INDEX: 3,
  FUNCTION_1: 4,
  FUNCTION_2: 5,
  DESIGN_COLLECTION: 6,
  MATERIAL_COLOR: 7,
  PREMIUM_COATING: 8,
  BACKSIDE_AR: 9,
  IMPACT_FREE: 10,
  MIRROR: 11,
};
