import { CommonResponse } from '../common.model';

export type LoginResponse = CommonResponse<User>;

export interface User {
  id: number;
  username: string;
  surname: string;
  name: string;
  email: string;
  phone: string;
  access_token: string;
  type: Type;
  status: UserStatus;
  company: Company | null;
}

export interface Company {
  id: number;
  name: string;
  shipCode: string;
  country: Country;
  region: Country;
  is_overseas: boolean;
  address: string;
  email: string;
  phone: string;
  fax: string;
  type: number;
  enableXlsx: number;
  enableCsv: number;
  status: CompanyStatus;
  coupon: Coupon[];
  contactPerson: string;
}

export interface Coupon {
  id: number;
  couponNo: string;
  couponName: null | string;
  pointBalance: number;
  status: number;
  lastModifiedTime: string;
}

export interface Country {
  id: number;
  name: string;
}

export enum UserStatus {
  SUSPENDED = 0,
  ACTIVE = 1,
  DELETED = 9,
}

export enum CompanyStatus {
  SUSPENDED = 0,
  ACTIVE = 1,
  DELETED = 9,
}

export enum Type {
  HEADQUARTER = 0,
  SHOP = 1,
  COURIER = 2,
}
