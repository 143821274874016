import React, { FC, useCallback } from 'react';
import { COLORS } from 'constants/color.const';
import { useTranslation } from 'react-i18next';
import { User } from 'models/api/authentication/authentication.model';
import Divider from 'components/ui/Divider/Divider';
import { formatDate } from 'helpers/datetime.helper';
import Close from 'components/common/svg/Close/Close';
import cx from 'classnames';
import classes from './coupon-modal.module.scss';

interface Props {
  modalIsOpen: boolean;
  onClose: () => void;
  user: User;
}

const CouponModal: FC<Props> = ({ modalIsOpen, onClose, user }) => {
  const { t } = useTranslation('shopDetail');
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const renderCouponName = (couponName: string) => {
    if (!couponName) {
      return '';
    }
    return ` - ${couponName}`;
  };

  return (
    <>
      {modalIsOpen && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div
              className={cx(
                'relative mx-auto',
                classes.wrapper,
                classes.shopDetail,
              )}>
              <div className="border-gray-5 rounded relative flex flex-col bg-white outline-none focus:outline-none h-full w-full pt-7.5 pb-10 px-7.5">
                <h1 className={'text-2xl text-dark-blue font-bold'}>
                  {t('couponCode')}
                </h1>
                <div className={'flex justify-start w-full mt-15px'}>
                  <div className={cx('flex flex-col', classes.coupon)}>
                    <span className="text-gray-1 font-bold text-sm w-24">
                      {t('coupon')}
                    </span>
                  </div>
                  <div className={cx('flex flex-col', classes.pointBalance)}>
                    <span className="text-gray-1 font-bold text-sm">
                      {t('pointBalance')}
                    </span>
                  </div>
                  <div className={'w-auto flex flex-col'}>
                    <span className="text-gray-1 font-bold text-sm">
                      {t('lastUpdatedTime')}
                    </span>
                  </div>
                </div>
                <Divider className={'mt-2.5'} />

                {user?.company?.coupon.length !== 0 && (
                  <div className={'flex justify-start w-full'}>
                    <div className={cx('flex flex-col', classes.coupon)}>
                      {user?.company?.coupon?.map((i) => (
                        <span
                          key={`${i.couponNo + i.couponName}`}
                          className="text-gray-3 text-base mt-2.5">
                          {`${i.couponNo}${renderCouponName(i.couponName)}`}
                        </span>
                      ))}
                    </div>
                    <div className={cx('flex flex-col', classes.pointBalance)}>
                      {user?.company?.coupon?.map((i) => (
                        <span
                          key={`${i.couponNo + i.couponName + i.pointBalance}`}
                          className="text-gray-3 text-base mt-2.5">
                          {i.pointBalance}
                        </span>
                      ))}
                    </div>
                    <div className={'w-auto flex flex-col'}>
                      {user?.company?.coupon?.map((i) => (
                        <span
                          key={`${
                            i.couponNo +
                            i.couponName +
                            i.pointBalance +
                            i.status
                          }`}
                          className="text-gray-3 text-base mt-2.5">
                          {formatDate(
                            new Date(i.lastModifiedTime.replace(/-/g, '/')),
                          )}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
                <button
                  type={'button'}
                  onClick={handleClose}
                  className={cx(
                    'absolute top-6 right-6 rounded focus:outline-none',
                    classes.btn,
                  )}>
                  <Close className={'w-5 h-5'} fill={COLORS.gray[4]} />
                </button>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-gray-1" />
        </>
      )}
    </>
  );
};

export default CouponModal;
