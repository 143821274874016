import { createReducer } from 'typesafe-actions';
import { ListOrderStore } from 'models/store/order/list/list.model';
import { getList, clearList } from './list.action';

const INIT_STATE: ListOrderStore = {
  loading: true,
  list: [],
  meta: {
    page: 1,
    recordCnt: 15,
    totalCnt: 0,
  },
  error: '',
};

export const reducer = createReducer(INIT_STATE)
  .handleAction(getList.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getList.success, (state, action) => ({
    ...state,
    loading: false,
    list: action.payload.data,
    meta: action.payload.meta,
  }))
  .handleAction(getList.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(clearList, (state) => ({
    ...state,
    list: [],
    meta: {
      page: 1,
      recordCnt: 15,
      totalCnt: 0,
    },
    error: '',
  }));
