import { ResultResponse } from 'models/api/orderCollection/orderCollection.model';
import { CommonResponse, CommonResponseNew } from 'models/api/common.model';
import { LENS_LAYER_NO } from 'constants/shops.const';
import { identity, pickBy } from 'lodash';
import { AssemblingOrderListResponse } from 'models/api/order/assemblingOrder.model';
import {
  LayerListItem,
  ValidationRuleResponse,
} from 'models/api/order/createOrder.model';
import serialize from 'helpers/form.helper';
import {
  CreateOrderRequest,
  SaleOrderResponse,
  UploadPhotoRequest,
} from '../models/api/order/order.model';
import {
  SaleOrderDetailResponse,
  DeleteOrderDetailResponse,
} from '../models/api/order/orderDetail.model';
import authorizedRequest from '../utils/request/authorizedRequest';

export function getSaleOrderApi(page: number, sort = 1, priority = 2) {
  return authorizedRequest.get<SaleOrderResponse, SaleOrderResponse>(
    'sales_order',
    {
      params: {
        page,
        sort,
        priority,
      },
    },
  );
}

export function getSaleOrderByStatusApi(
  page: number,
  status: number[],
  sort = 1,
  priority = 2,
) {
  return authorizedRequest.get<SaleOrderResponse, SaleOrderResponse>(
    'sales_order/findByStatuses',
    {
      params: {
        status,
        page,
        sort,
        priority,
      },
    },
  );
}

type ExportOrderResponse = CommonResponse<any>;

export function getSaleOrderByKeywordApi(
  keyword: string,
  page: number,
  status: number[],
  sort = 1,
  priority = 2,
) {
  return authorizedRequest.get<SaleOrderResponse, SaleOrderResponse>(
    'sales_order/filtered',
    {
      params: { keyword, status, page, sort, priority },
    },
  );
}

export type ListItemResponse = CommonResponse<
  { id: number; name: string }[]
> & {
  metadata: {
    page: number;
    recordCnt: number;
    totalCnt: number;
  };
};

type CreateOrderSuccessResponse = CommonResponse<{ id: number }>;

export function createNewOrder(data: CreateOrderRequest) {
  return authorizedRequest.post<
    CreateOrderSuccessResponse,
    CreateOrderSuccessResponse
  >(`sales_order/setDetail`, serialize(data), {
    shouldUseFlatData: true,
  });
}

export function exportOrderApi(format: number, orderId: number) {
  return authorizedRequest.get<ExportOrderResponse, ExportOrderResponse>(
    `sales_order/export/${orderId}`,
    {
      params: { format },
      responseType: 'blob',
    },
  );
}

export function uploadFiles(data: UploadPhotoRequest, orderId: number) {
  return authorizedRequest.post<ResultResponse, ResultResponse>(
    `/sales_order/changePhotos/${orderId}`,
    serialize(data),
    {
      shouldUseFlatData: true,
    },
  );
}

export function uploadFilesGet(orderId: number) {
  return authorizedRequest.get<ResultResponse, ResultResponse>(
    `/sales_order/changePhotos/${orderId}`,
  );
}

export function getUploadFilesNew(orderId: number, qrcode?: string) {
  return authorizedRequest.get<ResultResponse, ResultResponse>(
    `/external/sales_order/changePhotos/${orderId}`,
    {
      params: { qrcode },
    },
  );
}

export function PostUploadFilesNew(
  data: UploadPhotoRequest,
  orderId: number,
  qrcode?: string,
) {
  return authorizedRequest.post<ResultResponse, ResultResponse>(
    `/external/sales_order/changePhotos/${orderId}?qrcode=${qrcode}`,
    serialize(data),
    {
      shouldUseFlatData: true,
    },
  );
}

export function deleteUploadFilesNew(
  orderId: number,
  isRefDelete1?: number,
  isRefDelete2?: number,
  isRefDelete3?: number,
  qrcode?: string,
) {
  return authorizedRequest.post<ResultResponse, ResultResponse>(
    `/external/sales_order/changePhotos/${orderId}?isRefDelete1=${isRefDelete1}&isRefDelete2=${isRefDelete2}&isRefDelete3=${isRefDelete3}&qrcode=${qrcode}`,
  );
}

export function getClickButton(orderId: number) {
  return authorizedRequest.get<CommonResponseNew, CommonResponseNew>(
    `/external/sales_order/qrcode/${orderId}`,
  );
}

export function getLooping(orderId: number, qrcode?: string) {
  return authorizedRequest.get<ResultResponse, ResultResponse>(
    `/external/sales_order/qrcode/${orderId}/check?qrcode=${qrcode}`,
  );
}

export function postDataCrossButton(orderId: number) {
  return authorizedRequest.post<ResultResponse, ResultResponse>(
    `/external/sales_order/qrcode/${orderId}`,
  );
}

type OrderListItemRequest =
  | 'color_tnt'
  | 'color_tnt_type'
  | 'color_coating'
  | 'silver_coating'
  | 'service/plastic'
  | 'service/remarks'
  | 'service_charge/coating'
  | 'service_charge/remarks'
  | 'frame/series'
  | 'frame/model'
  | 'frame/color'
  | 'frame/lens_shape';

export function getListItemApi(item: OrderListItemRequest) {
  return authorizedRequest.get<ListItemResponse, ListItemResponse>(
    `list/${item}`,
  );
}

export function getLensProductByKeyWord(
  keyword: string,
  page: number,
  language?: string,
) {
  return authorizedRequest.get<ListItemResponse, ListItemResponse>(
    '/order_form/getLensProductByKeyword',
    {
      params: { keyword, page, language },
    },
  );
}

type GetLensProductFilterRequest =
  typeof LENS_LAYER_NO[keyof typeof LENS_LAYER_NO];

export function getLensProductFilter(layerNo: GetLensProductFilterRequest) {
  return authorizedRequest.get<ListItemResponse, ListItemResponse>(
    `/list/lens_product_layer/${layerNo}`,
  );
}

export function getLensProductFromField(
  page: number,
  materialId: number,
  typeId: number,
  indexId: number,
  function1Id: number,
  function2Id: number,
  designCollectionId: number,
  materialColorId: number,
  premiumCoatingId: number,
  backsideArId: number,
  impactfreeId: number,
  // mirrorId: number,
) {
  return authorizedRequest.get<ListItemResponse, ListItemResponse>(
    '/order_form/getLensProductByLayer',
    {
      params: {
        page: page ?? 1,
        ...pickBy(
          {
            materialId,
            typeId,
            indexId,
            function1Id,
            function2Id,
            designCollectionId,
            materialColorId,
            premiumCoatingId,
            backsideArId,
            impactfreeId,
            // mirrorId,
          },
          identity,
        ),
      },
    },
  );
}

export function getLensProductFilterLayerOptions(
  targetLayerNo: number,
  materialId: number,
  typeId?: number,
  designCollectionId?: number,
  indexId?: number,
  function1Id?: number,
  function2Id?: number,
  materialColorId?: number,
  premiumCoatingId?: number,
  backsideArId?: number,
  impactfreeId?: number,
  mirrorId?: number,
) {
  return authorizedRequest.get<LayerListItem, LayerListItem>(
    `/order_form/enabled_layer_options/${targetLayerNo}`,
    {
      params: pickBy(
        {
          materialId,
          typeId,
          designCollectionId,
          indexId,
          function1Id,
          function2Id,
          materialColorId,
          premiumCoatingId,
          backsideArId,
          impactfreeId,
          mirrorId,
        },
        identity,
      ),
    },
  );
}

export function getAssemblingOrderApi(page: number, sort = 1, priority = 1) {
  return authorizedRequest.get<
    AssemblingOrderListResponse,
    AssemblingOrderListResponse
  >('assembling_order', {
    params: pickBy(
      {
        page,
        sort,
        priority,
      },
      identity,
    ),
  });
}

export function getValidationParam(productId: number) {
  return authorizedRequest.get<ValidationRuleResponse, ValidationRuleResponse>(
    'sales_order/getValidationParam',
    {
      params: {
        productId,
      },
    },
  );
}

export function getLensProductInfo(productId: number) {
  return authorizedRequest.get<ValidationRuleResponse, ValidationRuleResponse>(
    'list/lens_product/info',
    {
      params: {
        productId,
      },
    },
  );
}

export function getAssemblingOrderByKeywordApi(
  keyword: string,
  page = 1,
  // sort = 1,
  // priority = 1,
) {
  return authorizedRequest.get<
    AssemblingOrderListResponse,
    AssemblingOrderListResponse
  >('assembling_order/findByKeyword', {
    params: pickBy(
      {
        keyword,
        page,
        // sort,
        // priority,
      },
      identity,
    ),
  });
}

type QrCodeFromAssemblingOrderResponse = CommonResponse<{ qrCode: string }>;

export function getQrCodeFromAssemblingOrder(orderId: number) {
  return authorizedRequest.get<
    QrCodeFromAssemblingOrderResponse,
    QrCodeFromAssemblingOrderResponse
  >('assembling_order/getQRCode', {
    params: { orderId },
  });
}

export function getQrCodeFromSaleOrder(orderId: number) {
  return authorizedRequest.get<
    QrCodeFromAssemblingOrderResponse,
    QrCodeFromAssemblingOrderResponse
  >('sales_order/getQRCode', {
    params: { orderId },
  });
}

export function getSaleOrderDetailApi(orderId: number) {
  return authorizedRequest.get<
    SaleOrderDetailResponse,
    SaleOrderDetailResponse
  >(`sales_order/id/${orderId}`);
}

export function deleteSaleOrderDetailApi(orderId: string) {
  return authorizedRequest.delete<
    DeleteOrderDetailResponse,
    DeleteOrderDetailResponse
  >(`sales_order/id/${orderId}`);
}

export function newRequestDateApi(
  orderId: number,
  pickupDate: string,
  isEmergency: number,
) {
  return authorizedRequest.put<ResultResponse, ResultResponse>(
    `/sales_order/${orderId}/updatePickupDate`,
    {
      isEmergency,
      pickupDate,
    },
  );
}
