import { createAsyncAction } from 'typesafe-actions';
import { FormValue } from 'components/Order/CreateOrder/Form/form.helper';

type CreateOrderPayload = {
  form: FormValue;
  onSuccess: (id: number) => void;
  onFail: (e: any) => void;
};

export const createOrder = createAsyncAction(
  '@order/CREATE_ORDER',
  '@order/CREATE_ORDER_SUCCESS',
  '@order/CREATE_ORDER_FAILED',
)<CreateOrderPayload, any, void>();
