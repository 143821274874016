import React, { FC, Suspense } from 'react';
import { Route, RouteProps, Switch } from 'react-router';

export interface CustomRouteProp extends RouteProps {
  [key: string]: any;
}

export interface CustomSwitchProps {
  routes: CustomRouteProp[];
}

const CustomSwitch: FC<CustomSwitchProps> = ({ routes }) => (
  <Suspense fallback={<div />}>
    <Switch>
      {routes.map((i) => (
        <Route
          key={String(i.path)}
          exact={i.exact}
          path={i.path}
          component={i.component}
        />
      ))}
    </Switch>
  </Suspense>
);

export default CustomSwitch;
