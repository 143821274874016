import { PopUpResponse } from 'models/api/content/news/popup/popup.model';
import { LatestNewsResponse } from 'models/api/content/news/latest-news/latest-news.model';
import { NewDetailResponse } from 'models/api/content/news/latest-news/new-detail/new-detail.model';
import serialize from 'helpers/form.helper';
import {
  ContentResponse,
  ContactInfoResponse,
  MessageStatusResponse,
  ContactMessage,
  ContentLogo,
} from '../models/api/content/content/content.model';
import authorizedRequest from '../utils/request/authorizedRequest';

export function getPopupNewsApi() {
  return authorizedRequest.get<PopUpResponse, PopUpResponse>('news/popup');
}

export function getContentApi(type: number) {
  return authorizedRequest.get<ContentResponse, ContentResponse>('/content', {
    params: {
      type,
    },
  });
}

export function getContactApi() {
  return authorizedRequest.get<ContactInfoResponse, ContactInfoResponse>(
    'content/contact_us',
  );
}

export function sendContactMessageApi(data: ContactMessage) {
  return authorizedRequest.post<MessageStatusResponse, MessageStatusResponse>(
    'content/contact_us/message',
    serialize(data),
    {
      shouldUseFlatData: true,
    },
  );
}

export function getLatestNewsApi(page: number) {
  return authorizedRequest.get<LatestNewsResponse, LatestNewsResponse>(
    'news/list',
    { params: { page } },
  );
}

export function getNewDetailApi(newsId: string) {
  return authorizedRequest.get<NewDetailResponse, NewDetailResponse>(
    `news/id/${newsId}`,
  );
}

export function getContentLogo(country: string) {
  return authorizedRequest.get<ContentLogo, ContentLogo>(
    `/content/setting?country=${country}`,
  );
}
