import CustomSwitch from 'components/common/custom-switch/CustomSwitch';
import React from 'react';
import routes from 'routes/auth.route';
import Footer from './Footer/Footer';
import classes from './layout.module.scss';

const AuthLayout = () => (
  <div className={classes.layout}>
    <div className={classes.content}>
      <CustomSwitch routes={routes} />
    </div>
    <Footer />
  </div>
);

export default AuthLayout;
