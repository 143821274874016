import { getPopupNewsApi } from 'api/content.api';
import cx from 'classnames';
import Close from 'components/common/svg/Close/Close';
import { COLORS } from 'constants/color.const';
import { AuthRouteConst } from 'constants/route.const';
import { PopupNewsData } from 'models/api/content/news/popup/popup.model';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import classes from './new-popup.module.scss';

const NewsPopup = () => {
  const [visible, setVisible] = useState(false);
  const [content, setContent] = useState<PopupNewsData>(null);
  const location = useLocation<{ from?: string }>();
  useEffect(() => {
    if (location?.state?.from === AuthRouteConst.SIGN_IN) {
      getPopupNewsApi()
        .then((r) => {
          setContent(r.data);
          if (r.data) setVisible(true);
        })
        .catch(() => {});
    }
  }, [location?.state?.from]);

  return (
    <>
      {visible && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className={cx('relative mx-auto ', classes.wrapper)}>
              <div className="border-0 rounded relative flex flex-col w-full bg-white outline-none focus:outline-none overflow-hidden flex-shrink-0">
                <img
                  src={`${content?.filepath + content?.filename}`}
                  alt={'File Path'}
                  className={cx(classes.img)}
                />
                {/* <div className={cx(classes.content, 'font-normal text-gray-1')}>
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: content?.content,
                    }}
                  />
                  </div> */}
              </div>

              <button
                type={'button'}
                onClick={() => setVisible(false)}
                className={cx(
                  'absolute top-0 rounded focus:outline-none flex items-center',
                  classes.btn,
                )}>
                <Close
                  className={'w-full h-full m-auto'}
                  fill={COLORS['dark-blue']}
                />
              </button>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-gray-1" />
        </>
      )}
    </>
  );
};

export default NewsPopup;
