import { call, takeLatest } from 'typed-redux-saga';
import md5 from 'md5';
import { changePasswordApi } from '../../api/changePassword.api';
import { CHANGE_PASSWORD_MESSAGE } from '../../constants/password.const';
import { changePassword } from './password.action';

function* handleChangePassword(
  action: ReturnType<typeof changePassword.request>,
) {
  try {
    const response = yield* call(
      changePasswordApi,
      md5(action.payload.currentPassword),
      md5(action.payload.newPassword),
    );

    if (response.result.message === CHANGE_PASSWORD_MESSAGE.success) {
      yield* call(action.payload.onCallBack, response.result.message);
    } else {
      yield* call(action.payload.onCallBack, response.result.message);
    }
  } catch (e) {
    yield* call(action.payload.onCallBack, e);
  }
}

export default function* passwordSaga() {
  yield* takeLatest(changePassword.request, handleChangePassword);
}
