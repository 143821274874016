import React from 'react';

function Plus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M21.6666 12.3292L21.6666 9.6705L12.3294 9.67057L12.3294 0.333316L9.67066 0.333316L9.67066 9.67057L0.333332 9.67066L0.333332 12.3294L9.67066 12.3293L9.67066 21.6667L12.3294 21.6667L12.3294 12.3293L21.6666 12.3292Z"
        fill={props.fill || '#00579E'}
      />
    </svg>
  );
}

export default Plus;
