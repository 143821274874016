import images from 'assets/images';
import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { getContentLogo } from 'api/content.api';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { createPopper } from '@popperjs/core';
import { useTranslation } from 'react-i18next';
import { Type, User } from 'models/api/authentication/authentication.model';
import LogoutPopup from 'layouts/app/Header/LogoutPopup/LogoutPopup';
import { SuccessTypes } from 'constants/success.const';
import CouponModal from './CouponModal/CouponModal';
import MenuCourier from './MenuCourier/MenuCourier';
import ChangePassword from './ChangePassword/ChangePassword';
import SuccessPopup from '../../../components/ui/SuccessPopup/SuccessPopup';
import history from '../../../helpers/history.helper';
import Menu from './Menu/Menu';
import classes from './header.module.scss';

interface Props {
  user: User;
  bigSize?: boolean;
}

const Header: FC<Props> = ({ user, bigSize }) => {
  const { t } = useTranslation('auth');
  const buttonRef = useRef<HTMLButtonElement>();
  const dropDownRef = useRef<HTMLDivElement>();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);
  const [passwordPopupVisible, setPasswordPopupVisible] = useState(false);
  const lang = localStorage.getItem('i18nextLng');
  const [source, setSource] = useState<any>('');
  const [country, setCountry] = useState('hk');

  const handleOpenDropDown = useCallback(() => {
    createPopper(buttonRef.current, dropDownRef.current, {
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [50, 10],
          },
        },
      ],
    });
    setDropdownVisible((r) => !r);
  }, []);
  const [logoutModalVisible, setLogoutModalVisible] = useState(false);
  const handleCloseDropDown = useCallback(() => {
    setDropdownVisible(false);
  }, []);

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (
        !dropDownRef.current.contains(e.target as Node) &&
        !buttonRef.current.contains(e.target as Node) &&
        dropdownVisible
      ) {
        handleCloseDropDown();
      }
    },
    [dropdownVisible, handleCloseDropDown],
  );

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);
  const displayName = useMemo(() => {
    if (!user) {
      return '';
    }

    if (user.type === Type.SHOP) {
      return `${user.company.name} - ${user.name}`;
    }
    return user.name;
  }, [user]);

  useEffect(() => {
    if (lang === 'en') {
      setCountry('os');
    } else if (lang === 'tw') {
      setCountry('hk');
    } else {
      setCountry('cn');
    }

    getContentLogo(country).then((res) => {
      setSource(res?.data);
    });
  }, [country, lang]);

  return (
    <>
      <div
        style={{ height: !bigSize ? 60 : 80, lineHeight: !bigSize ? 60 : 80 }}
        className="bg-white rounded-sm border-b sticky top-0 z-40">
        <div className="container md:max-w-700px xl:max-w-1170px 2xl:max-w-1280px lg:max-w-980px 3xl:max-w-1536px mx-auto flex items-center justify-between px-2 md:px-0">
          <Link to={'/'}>
            <img
              style={{
                width: !bigSize ? 148.2 : 216.13,
                height: !bigSize ? 48 : 70,
              }}
              src={source?.logo?.filepath}
              alt={'Logo'}
            />
          </Link>

          {user ? (
            <button
              type={'button'}
              className={'flex items-center focus:outline-none'}
              onClick={handleOpenDropDown}
              ref={buttonRef}>
              <img
                src={images.icons.iconAccount}
                alt={'Account'}
                className={'mr-1'}
              />

              <span className="text-blue font-bold text-xl hidden md:block">
                {displayName}
              </span>
            </button>
          ) : (
            <button
              type={'button'}
              className={'flex items-center focus:outline-none'}
              onClick={() => {
                history.push('/auth/login');
              }}
              ref={buttonRef}>
              <img
                src={images.icons.iconAccount}
                alt={'Account'}
                className={'mr-1'}
              />

              <span className="text-blue font-bold text-xl hidden md:block">
                {t('login')}
              </span>
            </button>
          )}
          <div
            ref={dropDownRef}
            className={cx(
              'bg-white z-60 px-2 py-4 float-right border border-solid rounded-sm',
              {
                hidden: !dropdownVisible,
                block: dropdownVisible,
              },
              classes.menu,
            )}>
            {user?.type !== Type.COURIER ? (
              <Menu
                onHideDropdown={handleCloseDropDown}
                user={user}
                onLogout={() => {
                  setLogoutModalVisible(true);
                }}
                onViewCoupon={() => {
                  setModalIsOpen(true);
                }}
                onChangePassword={() => {
                  setChangePasswordVisible(true);
                }}
              />
            ) : (
              <MenuCourier
                user={user}
                onHideDropdown={handleCloseDropDown}
                onLogout={() => {
                  setLogoutModalVisible(true);
                }}
              />
            )}
          </div>
        </div>
      </div>
      <LogoutPopup
        visible={logoutModalVisible}
        onClose={() => {
          setLogoutModalVisible(false);
        }}
      />
      <CouponModal
        modalIsOpen={modalIsOpen}
        onClose={() => {
          setModalIsOpen(false);
        }}
        user={user}
      />
      <ChangePassword
        visible={changePasswordVisible}
        onClose={() => {
          setChangePasswordVisible(false);
        }}
        onSuccess={() => {
          setPasswordPopupVisible(true);
          setTimeout(() => {
            setPasswordPopupVisible(false);
          }, 3000);
        }}
      />
      <SuccessPopup
        visible={passwordPopupVisible}
        successType={SuccessTypes.PASSWORD}
      />
    </>
  );
};

export default memo(Header);
