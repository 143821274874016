import { SaleOrderDetailStore } from 'models/store/order/detail/detail.model';
import { createReducer } from 'typesafe-actions';
import { clearOrderDetail, getSaleOrderDetail } from './detail.action';

const INIT_STATE: SaleOrderDetailStore = {
  detail: {},
  loading: false,
  error: '',
};

export const reducer = createReducer(INIT_STATE)
  .handleAction(getSaleOrderDetail.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(getSaleOrderDetail.success, (state, action) => ({
    ...state,
    loading: false,
    detail: action.payload,
    error: '',
  }))
  .handleAction(getSaleOrderDetail.failure, (state, action) => ({
    ...state,
    loading: false,
    detail: {},
    error: action.payload,
  }))
  .handleAction(clearOrderDetail, (state) => ({
    ...state,
    detail: {},
    error: '',
  }));
