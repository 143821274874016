import images from 'assets/images';
import cx from 'classnames';
import Button, { ButtonType } from 'components/ui/Button/Button';
import { COLORS } from 'constants/color.const';
import { INPUT } from 'constants/order';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PinField from 'react-pin-field';
import { ScaleLoader } from 'react-spinners';
import classes from './style.module.scss';

interface Props {
  setVisible: (e?: boolean) => void;
  orderId?: string;
  loading?: boolean;
  onSubmit?: (e: string) => void;
  error?: string;
}
const InputPassword: FC<Props> = ({ setVisible, loading, error, onSubmit }) => {
  const [password, setPassword] = useState('');
  const { t } = useTranslation('order');
  const handleOnChange = useCallback((e: string) => {
    setPassword(e);
  }, []);
  const handleConfirmForInputPw = () => {
    onSubmit(password);
  };
  return (
    <div>
      <div
        className={cx(
          'fixed top-12 left-0 bg-white text-center w-full bottom-0 right-0 z-50 box-border h-screen',
          classes.inputModal,
        )}>
        <div className="w-full flex justify-end">
          <button type="button" onClick={() => setVisible(false)}>
            <img
              className={cx('py-7', classes.icon_close_add_extra)}
              src={images.icons.iconCloseFilter}
              alt="close-img"
            />
          </button>
        </div>
        <h3 className={' text-blue font-bold text-xl'}>
          {t('CollectionDetail.InputShowPassword')}
        </h3>
        <p className={classes.warning}>
          {t('CollectionDetail.NoteInputPassword')}
        </p>
        <div className="d-flex justify-content-between" id="formInputPassword">
          <form>
            <PinField
              autoFocus
              style={{
                outline: 'none',
              }}
              className={cx(
                'text-center text-primary mt-7 pb-3',
                classes.input,
              )}
              placeholder="  "
              validate={/^[0-9]$/}
              length={INPUT.LENGTH_CODE}
              type="password"
              inputMode="numeric"
              pattern="[0-9]*" // Display Numeric Keypad on smartphones
              onChange={handleOnChange}
            />
            {error && error !== '' ? (
              <p className="text-error mt-5 text-xs">
                {t('CollectionDetail.ErrorInputPw')}
              </p>
            ) : null}
          </form>
        </div>
        <Button
          buttonType={ButtonType.Primary}
          disabled={password.length < INPUT.LENGTH_CODE}
          onClick={handleConfirmForInputPw}
          className={cx('mt-12', classes.confirmBtn)}>
          {loading ? (
            <ScaleLoader color={COLORS.blue[1]} />
          ) : (
            t('CollectionDetail.Confirm')
          )}
        </Button>
      </div>
    </div>
  );
};

export default InputPassword;
