import cx from 'classnames';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { OrderRouteConst } from 'constants/route.const';
import {
  Type,
  User,
  UserStatus,
} from 'models/api/authentication/authentication.model';
import classes from './menu.module.scss';

interface Props {
  onHideDropdown: () => void;
  onLogout: () => void;
  user: User;
  onViewCoupon: () => void;
  onChangePassword: () => void;
}

const MENU_KEY = {
  LOGOUT: 'LOGOUT',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  COUPON: 'COUPON',
};

const MenuCp: FC<Props> = ({
  onHideDropdown,
  user,
  onLogout,
  onViewCoupon,
  onChangePassword,
}) => {
  const { t } = useTranslation(['header', 'shopDetail']);
  const history = useHistory();

  const renderStatusValue = useCallback(
    (currentStatus: UserStatus) => {
      if (currentStatus) {
        switch (currentStatus) {
          case UserStatus.ACTIVE: {
            return t('header:available');
          }
          default: {
            return t('header:unavailable');
          }
        }
      }
    },
    [t],
  );

  const onMenuClick = useCallback(
    (key) => {
      switch (key) {
        case MENU_KEY.LOGOUT: {
          if (history.location.pathname === OrderRouteConst.NEW_ORDER) {
            history.push(OrderRouteConst.NEW_ORDER);
          } else {
            onLogout();
          }
          break;
        }
        case MENU_KEY.CHANGE_PASSWORD: {
          onChangePassword();
          break;
        }
        case MENU_KEY.COUPON: {
          onViewCoupon();
          break;
        }
      }
      onHideDropdown();
    },
    [onViewCoupon, onHideDropdown, onLogout, onChangePassword, history],
  );

  const renderHeader = useCallback(() => {
    if (user) {
      switch (user.type) {
        case Type.HEADQUARTER: {
          return (
            <span className="text-gray-3 text-xs">
              {t('header:headquarterAccount')}
            </span>
          );
        }
        case Type.SHOP: {
          return (
            <span className="text-gray-3 text-xs">
              {t('header:shopAccount')}
            </span>
          );
        }
      }
    }
  }, [user, t]);

  const renderName = useCallback(() => {
    if (user) {
      switch (user.type) {
        case Type.HEADQUARTER: {
          return user.name;
        }
        case Type.SHOP: {
          return `${user.company.name}_${user.name}`;
        }
      }
    }
  }, [user]);

  const renderViewCoupon = useCallback(() => {
    if (user) {
      if (
        user?.type === Type.HEADQUARTER &&
        user?.company?.is_overseas === false
      ) {
        return (
          <>
            <span
              className={cx(
                'text-gray-300 text-xs mx-15px',
                classes.smallDivider,
              )}>
              |
            </span>
            <button
              type={'button'}
              onClick={() => onMenuClick(MENU_KEY.COUPON)}
              className={'flex focus:outline-none'}>
              <span className="text-blue-1 text-sm font-bold px-1px">
                <u>{t('header:viewCouponCode')}</u>
              </span>
            </button>
          </>
        );
      }
    }
    return null;
  }, [user, t, onMenuClick]);

  const renderViewAllShop = useCallback(() => {
    if (user?.type === Type.HEADQUARTER) {
      return (
        <>
          <button
            type={'button'}
            onClick={() => {
              history.push('/shop');
              onHideDropdown();
            }}
            className={'flex focus:outline-none'}>
            <span className="text-blue text-sm font-bold whitespace-nowrap">
              <u>{t('header:viewAllShop')}</u>
            </span>
          </button>
        </>
      );
    }
  }, [t, user?.type, history, onHideDropdown]);

  return (
    <>
      <div
        className={cx(
          'pt-1 px-5 pb-3.5 flex flex-col items-start h-auto',
          classes.menu,
        )}>
        <div
          className={cx(
            'hover:text-black flex flex-col break-words w-full px-0',
            classes.shopDetail,
            classes.menuItem,
          )}>
          {renderHeader()}
          <span className="text-dark-blue text-xl font-bold break-words whitespace-normal static w-64 left-0 top-1/4 bottom-0">
            {renderName()}
          </span>
          <span className="text-gray-3 text-xs break-words whitespace-normal">
            {user?.company?.address}
          </span>
          <div
            className={cx('flex flex-row', {
              'mt-0': user?.type !== Type.HEADQUARTER,
              'mt-2.5': user?.type === Type.HEADQUARTER,
            })}>
            {renderViewAllShop()}
            {renderViewCoupon()}
          </div>
        </div>
        <div className={cx('w-full h-px mt-15px', classes.divider)} />
        <div className={cx('px-0 mt-2.5', classes.menuItem)}>
          <div className={'flex justify-start w-auto'}>
            <div className={'w-120px flex flex-col mr-10'}>
              <span className="text-gray-1 font-bold text-xs">
                {t('header:shipCode')}
              </span>
              <div className={'text-gray-3 leading-snug text-base'}>
                {user?.company.shipCode}
              </div>
            </div>
            <div className={'w-auto flex flex-col'}>
              <span className="text-gray-1 font-bold text-xs">
                {t('header:status')}
              </span>
              <div className={'text-gray-3 leading-snug text-base'}>
                {renderStatusValue(user?.status)}
              </div>
            </div>
          </div>
        </div>
        <div className={cx('px-0 mt-2.5', classes.menuItem)}>
          <div className={'flex justify-start w-auto'}>
            <div className={'w-120px flex flex-col mr-10'}>
              <span className="text-gray-1 font-bold text-xs">
                {t('shopDetail:contactNo')}
              </span>
              <div className={'text-gray-3 leading-snug text-base'}>
                {user?.phone}
              </div>
            </div>
            <div className={'w-auto flex flex-col'}>
              <span className="text-gray-1 font-bold text-xs">
                {t('shopDetail:contactName')}
              </span>
              <div className={'text-gray-3 leading-snug text-base'}>
                {user?.company?.contactPerson || '-'}
              </div>
            </div>
          </div>
        </div>
        <div className={cx('px-0 mt-2.5', classes.menuItem)}>
          <span className="text-gray-1 font-bold text-xs block">
            {t('header:email')}
          </span>
          <div className={'text-gray-3 leading-snug text-base'}>
            {user?.email}
          </div>
        </div>
        <button
          type={'button'}
          className={cx('font-bold px-0 text-gray-3 mt-5 focus:outline-none')}
          onClick={() => onMenuClick(MENU_KEY.CHANGE_PASSWORD)}>
          <span className="text-dark-blue font-bold block leading-snug">
            {t('header:changePassword')}
          </span>
        </button>
        <button
          type={'button'}
          className={cx(
            'font-bold p-0 text-gray-3 block leading-snug mt-5 focus:outline-none',
          )}
          onClick={() => onMenuClick(MENU_KEY.LOGOUT)}>
          {t('header:logOut')}
        </button>
      </div>
    </>
  );
};

export default MenuCp;
