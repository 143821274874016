import cx from 'classnames';
import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { OrderRouteConst } from 'constants/route.const';
import ChangeLanguagePopup from '../ChangeLanguagePopup/ChangeLanguagePopup';
import { LanguageConfig } from '../../../../constants/i18n.const';

const Language = () => {
  const { i18n } = useTranslation();
  const [newLanguage, setNewLanguage] = useState<string>('');
  const [changeLanguageVisible, setChangeLanguageVisible] =
    useState<boolean>(false);
  const location = useLocation();
  const handleChangeLang = useCallback(
    (lang: string) => i18n.changeLanguage(lang),
    [i18n],
  );
  const user = useSelector((store) => store.authentication.user);

  const handleOnClick = (lang: string) => {
    if (location.pathname.includes(OrderRouteConst.NEW_ORDER)) {
      setChangeLanguageVisible(true);
      setNewLanguage(lang);
    } else {
      handleChangeLang(lang);
      setNewLanguage('');
    }
  };

  return (
    <>
      <ul className={'flex flex-row justify-center md:justify-end'}>
        {LanguageConfig.map((i, index) => (
          <li
            key={i.lang}
            className={cx('rounded-full h-7 w-7 border-2', {
              'lg:ml-3 md:ml-1 ml-4': i.lang !== LanguageConfig[0].lang,
              'border-white': i.lang === i18n.language,
              'border-transparent': i.lang !== i18n.language,
              'hidden md:hidden': index > 2 && user?.type === 2,
            })}>
            <button
              type={'button'}
              className={'outline-none focus:outline-none'}
              onClick={() => handleOnClick(i.lang)}>
              <img src={i.img} alt={i.name} className={'w-full h-full'} />
            </button>
          </li>
        ))}
      </ul>
      <ChangeLanguagePopup
        visible={changeLanguageVisible}
        onClose={() => setChangeLanguageVisible(false)}
        handleChangeLang={handleChangeLang}
        newLanguage={newLanguage}
      />
    </>
  );
};

export default Language;
