import cn from 'classnames';
import Button, { ButtonType } from 'components/ui/Button/Button';
import { COLORS } from 'constants/color.const';
import Header from 'layouts/app/Header/Header';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import { ScaleLoader } from 'react-spinners';
import classes from './style.module.scss';
import InputPassword from './InputPassword';
import NewQrCode from './NewQrCode';

interface Props {
  setModalVisible: (visible: boolean) => void;
  visible: boolean;
  completeLoading?: boolean;
  onComplete?: () => void;
  onCompleteByPassword?: (e: string) => void;
  onCompleteByQrCode?: (e: string) => void;
  onRemoveResult: () => void;
  orderId?: string;
  errorResult?: string;
  errorInput?: string;
}
const ModalCollection: FC<Props> = ({
  visible,
  setModalVisible,
  completeLoading,
  orderId,
  onCompleteByPassword,
  onCompleteByQrCode,
  onComplete,
  errorResult,
  onRemoveResult,
}) => {
  const { t } = useTranslation('order');
  const user = useSelector((store) => store.authentication.user);
  const [isFreeToConfirm, setIsFreeToConfirm] = useState(false);
  const [isInputPassword, setIsInputPassword] = useState(false);
  const [isQrCode, setIsQrCode] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [errorValue, setErrorValue] = useState('');

  useEffect(() => {
    setOpenModal(visible);
    if (!isQrCode && !isInputPassword) {
      setErrorValue('');
    }
  }, [visible, isQrCode, isInputPassword]);

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleIsInputPassword = () => {
    setIsInputPassword(true);
  };

  const handleInputPwForQrCode = () => {
    setIsQrCode(false);
    setIsInputPassword(true);
  };

  useEffect(() => {
    setErrorValue(errorResult);
  }, [errorResult]);

  useEffect(() => {
    if (!isQrCode && !isInputPassword) {
      setErrorValue('');
    }
  }, [isQrCode, isInputPassword]);
  useEffect(() => {
    setIsQrCode(false);
    setIsInputPassword(false);
    setIsFreeToConfirm(false);
  }, [visible]);

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCancel}
        classNames={{
          modalContainer: 'top-1/3 fixed w-full mx-auto',
          closeButton: isQrCode && 'hidden',
          modal: cn('p-4 rounded', classes.modalQRcode),
        }}>
        <div className={classes.modalContainer}>
          {isQrCode && (
            <div className="fixed inset-0">
              <div className={classes.headerInsideModal}>
                <Header user={user} />
              </div>
              {/*
                <QrCode
                  handleCloseQrCode={() => setIsQrCode(false)}
                  isCollectDetail={true}
                  resultQrCode={errorResult}
                  setIsInputPw={handleInputPwForQrCode}
                  handleScan={onCompleteByQrCode}
                />
              */}
              {
                <NewQrCode
                  handleCloseQrCode={() => setIsQrCode(false)}
                  isCollectDetail={true}
                  resultQrCode={errorResult}
                  setIsInputPw={handleInputPwForQrCode}
                  handleScan={onCompleteByQrCode}
                />
              }
            </div>
          )}
          <h3 className={classes.modalHeader}>
            {t('CollectionDetail.CompleteCollect')}
          </h3>
          <p className={classes.deviceDetail}>
            {t('CollectionDetail.GiveYourDevice')}
          </p>
          <Button
            buttonType={ButtonType.Primary}
            className={classes.confirmBtn}
            onClick={() => setIsQrCode(true)}>
            {completeLoading ? (
              <ScaleLoader />
            ) : (
              t('CollectionDetail.CompleteQrCode')
            )}
          </Button>
          <Button
            buttonType={ButtonType.Outline}
            onClick={handleIsInputPassword}
            className={classes.outLineBtn}>
            {completeLoading ? (
              <ScaleLoader />
            ) : (
              t('CollectionDetail.InputPassword')
            )}
          </Button>
          <button
            type="button"
            className={'w-full outline-none'}
            onClick={() => {
              setIsFreeToConfirm(true);
            }}>
            <p
              className={cn(
                classes.buttonShopAccept,
                'text-xs text-blue font-bold text-center',
              )}>
              {t('CollectionDetail.NotFreeToConfirm')}
            </p>
          </button>
          {isFreeToConfirm && (
            <>
              <div className="opacity-50 fixed inset-0 z-40 bg-gray-1" />
              <div className="absolute bottom-0 bg-white w-full z-50 rounded-sm left-0 right-0 p-6">
                <h3 className={classes.modalHeader}>
                  {t('CollectionDetail.NotFreeToConfirm')}
                </h3>
                <p className="text-sm w-4/5 my-1 text-gray-1">
                  {t('CollectionDetail.ContentConfirmForShopI')}
                </p>
                <p className="text-sm text-gray-3 font-light">
                  {t('CollectionDetail.ContentConfirmForShopII')}
                </p>
                <div className="flex justify-around my-6">
                  <Button
                    buttonType={ButtonType.Outline}
                    onClick={() => setIsFreeToConfirm(false)}
                    className={cn(
                      'font-bold w-4/5 mr-2 whitespace-nowrap',
                      classes.darkColor,
                    )}>
                    {t('CollectionDetail.Cancel')}
                  </Button>
                  <Button
                    buttonType={ButtonType.Primary}
                    className="w-4/5 ml-2 whitespace-nowrap"
                    onClick={() => {
                      setIsFreeToConfirm(false);
                      setModalVisible(!visible);
                      onComplete();
                      onRemoveResult();
                    }}>
                    {completeLoading ? (
                      <ScaleLoader color={COLORS.blue[1]} />
                    ) : (
                      t('CollectionDetail.Confirm')
                    )}
                  </Button>
                </div>
              </div>
            </>
          )}
          {isInputPassword && (
            <>
              <div className={classes.headerInsideModal}>
                <Header user={user} />
              </div>
              <InputPassword
                error={errorValue}
                orderId={orderId}
                loading={completeLoading}
                onSubmit={onCompleteByPassword}
                setVisible={(e) => {
                  setIsInputPassword(e);
                  onRemoveResult();
                }}
              />
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ModalCollection;
