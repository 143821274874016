import images from 'assets/images';
import cx from 'classnames';
import FormikCheckbox from 'components/formik/Checkbox/FormikCheckbox';
import FormikRadio from 'components/formik/Radio/FormikRadio';
import Button, { ButtonType } from 'components/ui/Button/Button';
import { COLORS } from 'constants/color.const';
import { Formik, FormikProps } from 'formik';
import {
  OrderDate,
  // OrderRegion,
  OrderStatus,
  Region,
} from 'models/api/orderCollection/orderCollection.model';
import { getRegionWithPage } from 'api/courierOrder.api';
import { parse, stringify } from 'query-string';
import React, { FC, useCallback, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ScaleLoader } from 'react-spinners';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import classes from './style.module.scss';

enum Type {
  Collect = 1,
  Delivery = 2,
}

interface FormValue {
  date: number;
  filterRegion?: string;
  filterStatusCollect?: string;
  filterStatusDelivery?: string;
}

export interface Props {
  setFilterVisible: () => void;
  type: Type;
  setPage: () => void;
}

const limitRegion = 15;

const FilterComponent: FC<Props> = ({ setFilterVisible, setPage }) => {
  const location = useLocation();
  const {
    filterDate,
    filterRegion,
    filterStatusCollect,
    filterStatusDelivery,
  } = parse(location.search, {
    arrayFormat: 'comma',
  });
  const { t } = useTranslation('order');
  const formikRef = useRef<FormikProps<FormValue>>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [regionList, setRegionList] = useState<Array<Region>>([]);

  const [initValue, setInitValue] = useState({
    date: filterDate ? Number(filterDate as string) : undefined,
    filterRegion: filterRegion ? String(filterRegion) : '',
    filterStatusCollect: filterStatusCollect ? String(filterStatusCollect) : '',
    filterStatusDelivery: filterStatusDelivery
      ? String(filterStatusDelivery)
      : '',
  });
  const history = useHistory();

  useEffect(() => {
    getRegionWithPage(pageNumber)
      .then((response) => {
        if (response.data.length === 0) {
          setHasMore(false);
          setLoading(false);
        } else if (response.data.length !== 0) {
          const { data } = response;
          setLoading(false);
          setRegionList((prev) => (hasMore ? [...prev, ...data] : prev));
          setHasMore(data.length < limitRegion ? false : hasMore);
        }
      })
      .catch((err) => {
        setLoading(false);
        setHasMore(false);
      });
  }, [hasMore, pageNumber]);

  const handleClearFilter = () => {
    setInitValue({
      date: undefined,
      filterRegion: '',
      filterStatusCollect: '',
      filterStatusDelivery: '',
    });
    setPage();
    return history.push(location.pathname);
  };
  const handleApplyFilter = useCallback(
    (value: FormValue) => {
      setFilterVisible();
      setPage();
      history.push({
        pathname: location.pathname,
        search: stringify(
          {
            filterRegion: value?.filterRegion,
            filterStatusCollect: value?.filterStatusCollect,
            filterDate: value?.date === 0 ? 1 : value.date,
            filterStatusDelivery: value?.filterStatusDelivery,
          },
          { arrayFormat: 'comma' },
        ),
      });
    },
    [history, location.pathname, setFilterVisible, setPage],
  );

  const handleScrollNext = () => {
    setPageNumber((prev) => prev + 1);
  };

  return (
    <div
      className={cx(
        'flex flex-col absolute overflow-hidden bg-white p-small w-full z-10 top-0 left-0',
        classes.filter_order,
        classes.modalWithHeader,
      )}>
      <button type="button" onClick={() => setFilterVisible()}>
        <img
          className={cx('', classes.icon_close)}
          src={images.icons.iconCloseFilter}
          alt="close-img"
        />
      </button>
      <Formik
        enableReinitialize
        innerRef={formikRef}
        initialValues={initValue}
        onSubmit={handleApplyFilter}
        onReset={handleClearFilter}
        validateOnChange={false}
        validateOnBlur={false}>
        {({ handleSubmit, values, touched, handleReset }) => {
          const disabledClear =
            !values.date &&
            values?.filterRegion.length === 0 &&
            values?.filterStatusCollect.length === 0 &&
            values?.filterStatusDelivery.length === 0;
          return (
            <form
              onSubmit={handleSubmit}
              onReset={handleReset}
              className={cx(' flex flex-col ', classes.modalFilter)}>
              {/* <div className=" "> */}
              <div
                className={cx('flex flex-col mx-small', classes.filter_radio)}>
                <h3 className="mb-2 text-gray-1 font-fat">
                  {t('filterLabel.Date')}
                </h3>
                <FormikRadio
                  name={'date'}
                  value={String(OrderDate.ALL)}
                  className={cx(
                    'border-none pl-0 text-gray-1',
                    classes.tapHighlight,
                  )}>
                  {t('filterDate.All')}
                </FormikRadio>
                <FormikRadio
                  name={'date'}
                  value={String(OrderDate.THIS_3_DAYS)}
                  className={cx(
                    'border-none pl-0 text-gray-1',
                    classes.tapHighlight,
                  )}>
                  {t('filterDate.This3Days')}
                </FormikRadio>
                <FormikRadio
                  name={'date'}
                  value={String(OrderDate.TODAY)}
                  className={cx(
                    'border-none pl-0 text-gray-1',
                    classes.tapHighlight,
                  )}>
                  {t('filterDate.Today')}
                </FormikRadio>
                <hr className="my-2" />
              </div>
              <div className="filter_checkbox_region flex flex-col mx-small overflow-auto flex-grow">
                <h3 className="mb-2 text-gray-1 font-fat">
                  {t('filterLabel.Region')}
                </h3>
                <div className="overflow-auto flex-grow " id="scrollableDiv">
                  {loading ? (
                    <div className={'flex justify-center mt-4'}>
                      <ScaleLoader color={COLORS.blue.DEFAULT} />
                    </div>
                  ) : (
                    <InfiniteScroll
                      style={{ overflow: 'hidden' }}
                      dataLength={regionList.length}
                      hasMore={hasMore}
                      loader={
                        <>
                          <div className={'flex w-full mt-4 justify-center'}>
                            <ScaleLoader color={COLORS.blue.DEFAULT} />
                          </div>
                        </>
                      }
                      scrollableTarget="scrollableDiv"
                      next={handleScrollNext}
                      className={'row flex-wrap'}>
                      {regionList.length > 0 &&
                        regionList.map((item, key) => (
                          <FormikCheckbox
                            key={String(key)}
                            className={'text-gray-1 filter_checkbox'}
                            value={item.id}
                            name="filterRegion"
                            hasBorder={false}>
                            {item.name}
                          </FormikCheckbox>
                        ))}
                    </InfiniteScroll>
                  )}
                </div>
                <hr className="my-2" />
              </div>
              <div className="filter_checkbox_region flex-grow flex flex-col mx-small">
                <h3 className="text-gray-1 font-fat">
                  {t('filterLabel.Status')}
                </h3>
                <FormikCheckbox
                  hasBorder={false}
                  className={'text-gray-1 filter_checkbox'}
                  name="filterStatusCollect"
                  value={OrderStatus.PENDING}>
                  {t('filterStatus.Pending')}
                </FormikCheckbox>
                <FormikCheckbox
                  hasBorder={false}
                  className={'text-gray-1 filter_checkbox'}
                  name="filterStatusCollect"
                  value={OrderStatus.COMPLETED}>
                  {t('filterStatus.Collected')}
                </FormikCheckbox>
                <FormikCheckbox
                  className={'text-gray-1 filter_checkbox'}
                  hasBorder={false}
                  name="filterStatusDelivery"
                  value={OrderStatus.DELIVERING}>
                  {t('filterStatus.Delivering')}
                </FormikCheckbox>
                <FormikCheckbox
                  className={'text-gray-1 filter_checkbox'}
                  hasBorder={false}
                  name="filterStatusDelivery"
                  value={OrderStatus.DELIVERED}>
                  {t('filterStatus.Delivered')}
                </FormikCheckbox>
              </div>
              {/* </div> */}
              <div className="left-0 m-r-6.5 mt-6">
                <Button
                  className={'text-base w-full'}
                  disabled={disabledClear}
                  type="submit">
                  {t('textButton.ApplyFilter')}
                </Button>
                <Button
                  buttonType={ButtonType.Secondary}
                  className={
                    disabledClear
                      ? 'mt-big bg-gray-50 w-full border-gray-3 text-gray-3'
                      : 'mt-big bg-gray-50 w-full border-dark-blue text-dark-blue'
                  }
                  disabled={disabledClear}
                  type="reset">
                  {t('textButton.ClearAll')}
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default FilterComponent;
