import { LoginResponse } from '../models/api/authentication/authentication.model';
import unauthorizedRequest from '../utils/request/unauthorizedRequest';
import authorizedRequest from '../utils/request/authorizedRequest';

export function loginApi(username: string, password: string) {
  return unauthorizedRequest.post<LoginResponse, LoginResponse>('user/login', {
    username,
    password,
  });
}

export function logoutApi() {
  return authorizedRequest.get('user/logout');
}

export function getUser() {
  return authorizedRequest.get<LoginResponse, LoginResponse>('user');
}
