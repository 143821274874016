import { createAsyncAction, createAction } from 'typesafe-actions';
import { SaleOrder } from 'models/api/order/order.model';

export interface GetListPayload {
  page?: number;
  sort?: number;
  status?: number;
  priority?: number;
  keyword?: string;
}

type GetListOrderSuccessPayload = {
  data: SaleOrder[];
  meta: {
    page: number;
    recordCnt: number;
    totalCnt: number;
  };
};

export const getList = createAsyncAction(
  '@order/GET_LIST_REQUESTED',
  '@order/GET_LIST_SUCCESS',
  '@order/GET_LIST_FAILURE',
)<GetListPayload, GetListOrderSuccessPayload, void>();

export const clearList = createAction('@order/CLEAR_LIST')<void>();
