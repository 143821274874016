import { DeliveryDetailResponse } from 'models/api/orderDelivery/orderDelivery.model';
import {
  OrderDate,
  OrderStatus,
} from '../models/api/orderCollection/orderCollection.model';
import { DeliveryResponse } from '../models/api/orderDelivery/orderDelivery.model';
import authorizedRequest from '../utils/request/authorizedRequest';

export function getListDeliveryApi(
  page: number,
  type: number,
  lang: string,
  filterDate: string | OrderDate,
  filterStatus?: string[] | string | OrderStatus[] | OrderStatus,
  filterRegion?: string[],
  keyword?: string,
) {
  const url = `/courier/order/filtered`;
  return authorizedRequest.get<DeliveryResponse, DeliveryResponse>(url, {
    params: {
      page,
      filterDate,
      filterRegion,
      filterStatus,
      keyword,
      type,
      lang,
    },
  });
}

export function getDeliveryDetailApi(orderId: string, lang: string) {
  return authorizedRequest.get<DeliveryDetailResponse, DeliveryDetailResponse>(
    `/courier/order/id/${orderId}`,
    {
      params: {
        lang,
      },
    },
  );
}

export function getDeliveryDetailByQrCodeApi(qrCode: string, type?: number) {
  return authorizedRequest.get<DeliveryDetailResponse, DeliveryDetailResponse>(
    `/courier/order/getByQRCode`,
    {
      params: {
        qrCode,
        type,
      },
    },
  );
}

interface CompleteCollectOrderApiByPasswordBody {
  password: string;
}

export function completeCollectOrderByPasswordApi(
  orderId?: string,
  body?: CompleteCollectOrderApiByPasswordBody,
) {
  return authorizedRequest.put<DeliveryDetailResponse, DeliveryDetailResponse>(
    `/courier/order/${orderId}/completeByPassword`,
    body,
  );
}

interface CompleteCollectOrderApiByQrCodeBody {
  qrCode: string;
}

export function completeCollectOrderByQrCodeApi(
  orderId: string,
  body?: CompleteCollectOrderApiByQrCodeBody,
) {
  return authorizedRequest.put<DeliveryDetailResponse, DeliveryDetailResponse>(
    `/courier/order/${orderId}/completeByQrCode`,
    body,
  );
}

export function completeCollectOrderApi(orderId: string) {
  return authorizedRequest.put<DeliveryDetailResponse, DeliveryDetailResponse>(
    `/courier/order/${orderId}/complete`,
  );
}
