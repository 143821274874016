import React, { Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'store';
import { registerValidation } from 'validations/yup';
import ScrollToTop from 'components/common/ScrollToTop/ScrollToTop';
import history from './helpers/history.helper';
import allRoutes from './routes/all.route';
// Style
import 'assets/styles/global.scss';
import 'assets/styles/index.css';

import ErrorBoundary from './components/common/ErrorBoundary/ErrorBoundary';

function App() {
  useEffect(() => {
    registerValidation();
  }, []);

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={history}>
            <ScrollToTop />
            <Suspense fallback={<div />}>
              <Switch>
                {allRoutes.map(({ path, ...others }, index) => (
                  <Route key={String(index)} path={path} {...others} />
                ))}
              </Switch>
            </Suspense>
          </Router>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
