enum ResultType {
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export enum ToastIcon {
  SUCCESS = 'success',
  FAILURE = 'failure',
  DELIVERY = 'delivery',
  SUCCESS_CREATE = 'success-create',
}

export { ResultType };
