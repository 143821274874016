import {
  addExtraSalesApi,
  addRemarksApi,
  completeCollectOrderApi,
  completeCollectOrderByPasswordApi,
  completeCollectOrderByQrCodeApi,
} from 'api/orderCollection.api';
import images from 'assets/images';
import cx from 'classnames';
import Minus from 'components/common/svg/Minus/Minus';
import Plus from 'components/common/svg/Plus/Plus';
import Button, { ButtonType } from 'components/ui/Button/Button';
import Input from 'components/ui/Input/Input';
import TextArea from 'components/ui/Textarea/TextArea';
import { COLORS } from 'constants/color.const';
import { TYPE_BUTTON, TYPE_STATUS } from 'constants/order';
import { CourierOrderRouteConst } from 'constants/route.const';
import ModalCollection from 'containers/app/Common/ModalCollection';
import SlideToggle from 'containers/app/Common/SildeToggle';
import Toast from 'containers/app/Common/toast/Toast';
import { getDate, getTime } from 'helpers/datetime.helper';
import {
  OrderStatus,
  ResultResponse,
} from 'models/api/orderCollection/orderCollection.model';
import { ResultType } from 'models/entities/common.entity';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import { getCollectionDetail } from '../../../store/orderCollection/detail/detail.action';
import classes from './style.module.scss';

const special_characters = [',', '.', '+', '-'];
const CollectionDetail: FC = () => {
  const dispatch = useDispatch();
  const inputTextArea = useRef<HTMLDivElement>();
  const { t } = useTranslation('order');
  const { id } = useParams<any>();
  const [isInputRemarks, setIsInputRemarks] = useState(false);
  const [isAddMarks, setIsAddMarks] = useState(false);
  const [isAddExtraSales, setIsAddExtraSales] = useState(false);
  const [visible, setVisible] = useState(false);
  const history = useHistory();
  const collectionDetail = useSelector((store) => store.orderCollection.detail);
  const [extraSales, setExtraSales] = useState(
    collectionDetail.detail?.extraCnt,
  );
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [completeVisible, setCompleteVisible] = useState(false);
  const [contentRemarks, setContentRemarks] = useState('');
  const [errorResult, setErrorResult] = useState('');
  const handleAddRemarks = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContentRemarks(e.target?.value);
  };

  const { i18n } = useTranslation();

  const onCloseModal = useCallback(() => {
    setVisible(false);
  }, []);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    setExtraSales(collectionDetail.detail?.extraCnt);
  }, [collectionDetail.detail?.extraCnt]);
  const handleListenClick = useCallback(
    async (e: MouseEvent) => {
      if (!inputTextArea?.current?.contains(e.target as Node)) {
        if (isInputRemarks) {
          const result = await addRemarksApi(id, contentRemarks.trim());
          if (result.result.code === 200) {
            setIsInputRemarks(false);
          }
        }
      }
    },
    [contentRemarks, id, isInputRemarks],
  );

  useEffect(() => {
    if (
      collectionDetail.detail.remarks &&
      collectionDetail?.detail?.remarks.length !== 0
    ) {
      setContentRemarks(collectionDetail.detail?.remarks);
      setIsAddMarks(true);
    }
  }, [collectionDetail.detail?.remarks]);

  useEffect(() => {
    window.addEventListener('mousedown', handleListenClick);
    return () => {
      window.removeEventListener('mousedown', handleListenClick);
    };
  }, [handleListenClick]);
  useEffect(() => {
    if (collectionDetail.error !== '') {
      history.replace(CourierOrderRouteConst.ORDER_COLLECTION);
    }
  }, [collectionDetail.error, history]);

  const changeable = useMemo(
    () => collectionDetail?.detail?.status === OrderStatus.PENDING,
    [collectionDetail],
  );

  const onCompleteByPassword = useCallback(
    (event: string) => {
      setLoading(true);
      const body = { password: event };
      completeCollectOrderByPasswordApi(id, body)
        .then((response) => {
          if (response?.result?.code === 200) {
            setVisible(false);
            setSuccess(true);
            setCompleteVisible(true);
            setTimeout(() => {
              setCompleteVisible(false);
            }, 1000);
            dispatch(
              getCollectionDetail.request({
                orderId: id,
                lang: i18n.language,
              }),
            );
          }
        })
        .catch((err) => {
          setSuccess(false);
          setErrorResult(t('NotResultFound'));
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [id, dispatch, i18n.language, t],
  );

  const onCompleteByQrCode = useCallback(
    (event: string) => {
      setLoading(true);
      const body = { qrCode: event };
      completeCollectOrderByQrCodeApi(id, body)
        .then((response) => {
          if (response?.result?.code === 200) {
            setVisible(false);
            setSuccess(true);
            setCompleteVisible(true);
            setTimeout(() => {
              setCompleteVisible(false);
            }, 1000);
            dispatch(
              getCollectionDetail.request({
                orderId: id,
                lang: i18n.language,
              }),
            );
          }
        })
        .catch((err) => {
          setSuccess(false);
          setErrorResult(t('NotResultFound'));
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [id, dispatch, i18n.language, t],
  );

  const onComplete = useCallback(() => {
    setLoading(true);
    completeCollectOrderApi(id)
      .then((r) => {
        setSuccess(true);
        setVisible(false);
        dispatch(
          getCollectionDetail.request({
            orderId: id,
            lang: i18n.language,
          }),
        );
      })
      .catch((e) => {
        setSuccess(false);
      })
      .finally(() => {
        setLoading(false);
        setCompleteVisible(true);
        setTimeout(() => {
          setCompleteVisible(false);
        }, 1000);
        setVisible(false);
      });
  }, [id, dispatch, i18n.language]);
  const actionButtonContent = useCallback(() => {
    const content: string = changeable
      ? t('CollectionDetail.Complete')
      : t('CollectionDetail.BackToHomepage');
    return content;
  }, [changeable, t]);
  const actionButtonType = useMemo(
    (): ButtonType => (changeable ? ButtonType.Primary : ButtonType.Outline),
    [changeable],
  );
  const actionButtonCallBack = useCallback(() => {
    const completeAction = () => {
      setVisible(true);
    };
    const backHomeAction = () => history.push('/courier/order/collection');

    return changeable ? completeAction() : backHomeAction();
  }, [changeable, history]);
  const typeButton =
    collectionDetail.detail?.status === OrderStatus.PENDING
      ? TYPE_BUTTON.WARNING
      : TYPE_BUTTON.SUCCESS;

  useEffect(() => {
    dispatch(
      getCollectionDetail.request({
        orderId: id,
        lang: i18n.language,
      }),
    );
  }, [dispatch, i18n.language, id]);
  const handleNumExtras = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (Number(value) > 99) return;
    setExtraSales(Number(value));
  };

  const handleSpecial = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (special_characters.indexOf(e.key) >= 0) {
      e.preventDefault();
    }
  };

  const handleCancelAddExtras = () => {
    setExtraSales(collectionDetail.detail?.extraCnt);
    setIsAddExtraSales((prev) => false);
  };

  const handleConfirmAddExtraSales = useCallback(async () => {
    const result: ResultResponse = await addExtraSalesApi(id, extraSales);
    if (result.result.code === 200) {
      dispatch(
        getCollectionDetail.request({
          orderId: id,
          lang: i18n.language,
        }),
      );
      setIsAddExtraSales((prev) => false);
    }
  }, [dispatch, extraSales, i18n.language, id]);

  const handleAddMarks = () => {
    setIsAddMarks(true);
    setIsInputRemarks(true);
  };

  const handleEditTextArea = () => {
    setIsInputRemarks(true);
  };

  const telHref = `tel:+${collectionDetail?.detail?.phone}`;

  return (
    <div className={cx('relative bg-blue-50', classes.detail_main)}>
      <Toast
        visible={completeVisible}
        type={success ? ResultType.SUCCESS : ResultType.FAILURE}
        content={`#${collectionDetail?.detail?.phone} ${
          success
            ? t('CollectionDetail.SuccessOrderCollected')
            : t('CollectionDetail.FailureOrderCollected')
        }`}
      />
      <ModalCollection
        orderId={id}
        onRemoveResult={() => setErrorResult('')}
        visible={visible}
        setModalVisible={onCloseModal}
        errorResult={errorResult}
        onCompleteByQrCode={onCompleteByQrCode}
        onCompleteByPassword={onCompleteByPassword}
        onComplete={onComplete}
      />
      {isAddExtraSales && (
        <div
          className={
            'absolute top-0 left-0 bg-white text-center w-full bottom-0 right-0 z-50 box-border h-screen'
          }>
          <button
            className={'h-12 relative w-full'}
            type="button"
            onClick={handleCancelAddExtras}>
            <img
              className={cx(
                'absolute right-6 leading-12',
                classes.icon_close_add_extra,
              )}
              src={images.icons.iconCloseFilter}
              alt="close-img"
            />
          </button>
          <h3 className={'text-dark-blue font-fat text-xl mb-8'}>
            {t('CollectionDetail.AddExtra')}
          </h3>
          <div className={'grid grid-cols-3 h-20 mx-auto w-64  '}>
            <div className={'flex justify-center items-center mr-2'}>
              <button
                type="button"
                disabled={extraSales < 2}
                onClick={() => setExtraSales((prev) => prev - 1)}
                className={cx('outline-none', classes.icon_plus)}>
                <Minus fill={extraSales < 2 && COLORS.gray[4]} />
              </button>
            </div>
            <Input
              type="number"
              className={cx(
                'w-full  pr-2 h-20 mx-auto outline-none text-3xl',
                `${extraSales > 9 ? 'pl-6' : 'pl-9'}`,
                classes.input_extra,
              )}
              value={extraSales >= 1 ? extraSales : ''}
              onChange={handleNumExtras}
              onKeyDown={handleSpecial}
              inputMode="numeric"
              autoFocus={true}
            />
            <div className={'flex justify-center items-center ml-2'}>
              <button
                type="button"
                disabled={extraSales > 98}
                onClick={() => setExtraSales((prev) => prev + 1)}
                className={cx('outline-none', classes.icon_plus)}>
                <Plus fill={extraSales === 99 && COLORS.gray[4]} />
              </button>
            </div>
          </div>
          <Button
            buttonType={ButtonType.Primary}
            disabled={extraSales === 0}
            onClick={handleConfirmAddExtraSales}
            className="mb-5 mt-12 w-5/6 mx-auto">
            {t('CollectionDetail.Confirm')}
          </Button>
        </div>
      )}
      {collectionDetail.loading ? (
        <div className={'fixed top-1/4 flex w-full justify-center'}>
          <ScaleLoader color={COLORS.blue.DEFAULT} />
        </div>
      ) : (
        <div
          className={cx('bg-blue-50', classes.order_collection_detail, {
            hidden: isAddExtraSales,
          })}>
          <div>
            <div className={cx('bg-white py-3', classes.order_collection_item)}>
              <div className="flex justify-between mb-2">
                <div className="flex-col">
                  <p className="text-gray-2 font-fat">{t('CollectFrames')}</p>
                  <p className="text-gray-3">
                    {t('FieldCollection.RequestdDate')} :{' '}
                    {collectionDetail?.detail?.pickupDate &&
                      getDate(
                        new Date(collectionDetail.detail.pickupDate).getTime(),
                        'yyyy-mm-dd',
                      )}
                  </p>
                  <p className="text-gray-3">
                    {t('FieldCollection.OfficeHour')} :{' '}
                    {collectionDetail?.detail?.officeHourFrom &&
                      getTime(
                        new Date(
                          collectionDetail?.detail?.officeHourFrom,
                        ).getTime(),
                        'hh-mm',
                      )}
                    -
                    {collectionDetail?.detail?.officeHourTo &&
                      getTime(
                        new Date(
                          collectionDetail?.detail?.officeHourTo,
                        ).getTime(),
                        'hh-mm',
                      )}
                  </p>
                </div>
                <button
                  type="button"
                  className={cx('font-bold', classes[typeButton])}>
                  {collectionDetail?.detail?.status === OrderStatus.PENDING
                    ? TYPE_STATUS.PENDING
                    : TYPE_STATUS.COLLECTED}
                </button>
              </div>
              <div className="flex-col">
                <p className="text-dark-blue font-bold">
                  {collectionDetail?.detail?.region}
                </p>
                <p className="font-fat text-gray-1 text-medium uppercase">
                  {collectionDetail?.detail?.name}
                </p>
                <p className="text-gray-3 text-xs leading-5 ">
                  {collectionDetail?.detail?.address}
                </p>
                <p className="underline text-blue font-fat">
                  <a href={telHref}>
                    {t('CollectionDetail.ContactNo')}
                    {collectionDetail?.detail?.phone}
                  </a>
                </p>
              </div>
            </div>
            <div className={cx('border border-b-0', classes.extra_item)}>
              <div className={cx('mt-1', classes.code_item)}>
                <p className="text-base font-fat flex leading-8">
                  <span className={cx('text-2xl', classes.darkColor)}>
                    #{collectionDetail?.detail?.refNo}
                  </span>
                  <img
                    src={images.orderCollection.glasses}
                    alt="icon-glasses"
                    className="mt-1 ml-2 mr-1"
                  />
                  <span className="mt-1 text-xl text-light-blue">
                    {collectionDetail?.detail?.salesOrderCnt
                      ? `x${collectionDetail?.detail?.salesOrderCnt}`
                      : `${0}`}
                  </span>
                </p>
                <div
                  className={cx(
                    'border border-gray-5 rounded-md mt-2 overflow-hidden',
                    classes.list_order,
                  )}>
                  {collectionDetail?.detail?.salesOrder &&
                    collectionDetail?.detail?.salesOrder.map((item, key) => (
                      <div
                        key={Number(key)}
                        className=" border-gray-5 border-b">
                        <SlideToggle
                          location={key}
                          title={item.edgeNo}
                          content1={item.lensProduct.left}
                          content2={item.lensProduct.right}
                        />
                      </div>
                    ))}
                  {extraSales !== 0 && (
                    <div
                      className={
                        'flex justify-between leading-default px-3 py-2 bg-blue-50'
                      }>
                      <span className={'text-light-blue font-fat text-lg'}>
                        {t('CollectionDetail.ExtraSales')} x{extraSales}
                      </span>
                      <span
                        onClick={() => setIsAddExtraSales(true)}
                        className={cx(
                          'underline text-blue leading-default pr-2 font-fat text-sm',
                          classes.text_edit,
                        )}>
                        {t('CollectionDetail.Edit')}
                      </span>
                    </div>
                  )}
                </div>
                <div className="w-full mt-4 flex flex-col">
                  {extraSales === 0 &&
                    collectionDetail.detail.status !==
                      OrderStatus.COMPLETED && (
                      <div className="mb-4">
                        <Button
                          onClick={() => setIsAddExtraSales(true)}
                          buttonType={ButtonType.Outline}
                          style={{ width: '100%', height: '43px' }}
                          className="mb-3 border-b">
                          {t('CollectionDetail.AddNew')}
                        </Button>
                        <hr />
                      </div>
                    )}
                  {!isAddMarks ? (
                    <span
                      className={cx('font-bold underline', classes.blueColor)}
                      onClick={handleAddMarks}>
                      {t('CollectionDetail.AddRemarks')}
                    </span>
                  ) : (
                    <div>
                      <div className="flex justify-between">
                        <h3 className={'font-fat text-gray-1 text-sm mb-1'}>
                          {t('CollectionDetail.Remarks')}
                        </h3>
                        {!isInputRemarks && (
                          <span
                            className="underline text-blue font-fat mb-1 text-sm pt-0.5 leading-default"
                            onClick={handleEditTextArea}>
                            {t('CollectionDetail.Edit')}
                          </span>
                        )}
                      </div>
                      <div ref={inputTextArea}>
                        <TextArea
                          value={contentRemarks}
                          onChange={handleAddRemarks}
                          rows={4}
                          className={'rounded'}
                          readOnly={!isInputRemarks}
                        />
                      </div>
                    </div>
                  )}
                  <Button
                    buttonType={actionButtonType}
                    style={{ width: '100%', height: '45px' }}
                    className={classes.completeBtn}
                    onClick={actionButtonCallBack}>
                    {loading ? (
                      <ScaleLoader color={COLORS.blue.DEFAULT} />
                    ) : (
                      actionButtonContent()
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CollectionDetail;
