import { call, put, takeLatest } from 'typed-redux-saga';
import { RESULT_MESSAGES } from 'constants/common.const';
import { getSaleOrderDetail } from './detail.action';
import { getSaleOrderDetailApi } from '../../../api/order.api';

function* getOrderDetail(
  action: ReturnType<typeof getSaleOrderDetail.request>,
) {
  try {
    const response = yield* call(getSaleOrderDetailApi, action.payload.orderId);
    if (
      response.result.code === 200 &&
      response.result.message !== RESULT_MESSAGES.NO_RECORD_FOUND
    ) {
      yield put(getSaleOrderDetail.success(response.data));
    } else {
      yield put(getSaleOrderDetail.failure(response.result.message));
    }
  } catch (e) {
    yield put(
      getSaleOrderDetail.failure(
        'Error. Something happened that failed to make the request',
      ),
    );
  }
}

export default function* saleOrderDetailSaga() {
  yield* takeLatest(getSaleOrderDetail.request, getOrderDetail);
}
