import { createAsyncAction, createAction } from 'typesafe-actions';
import { SaleOrderDetail } from 'models/api/order/orderDetail.model';

interface SaleOrderDetailPayload {
  orderId: number;
}

export const getSaleOrderDetail = createAsyncAction(
  '@order/GET_SALE_ORDER_DETAIL_REQUEST',
  '@order/GET_SALE_ORDER_DETAIL_SUCCESS',
  '@order/GET_SALE_ORDER_DETAIL_FAILURE',
)<SaleOrderDetailPayload, SaleOrderDetail, string>();

export const clearOrderDetail = createAction(
  '@order/CLEAR_ORDER_DETAIL',
)<void>();
