import cx from 'classnames';
import { invariant } from 'helpers/common.helper';
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  MutableRefObject,
  useEffect,
} from 'react';

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Outline = 'outline',
  Transparent = 'transparent',
}

export enum ButtonSize {
  Large = 'l',
  Medium = 'm',
  Small = 's',
  ExtraLarge = 'xl',
}

interface Props extends React.ButtonHTMLAttributes<any> {
  buttonType?: typeof ButtonType[keyof typeof ButtonType];
  buttonSize?: typeof ButtonSize[keyof typeof ButtonSize];
  size?: typeof ButtonSize[keyof typeof ButtonSize];
  innerRef?: MutableRefObject<HTMLButtonElement>;
}

const Button: ForwardRefRenderFunction<HTMLButtonElement, Props> = (
  props,
  ref,
) => {
  const {
    buttonType = ButtonType.Primary,
    size,
    buttonSize = ButtonSize.Medium,
    children,
    className,
    innerRef,
    disabled,
    ...other
  } = props;
  useEffect(() => {
    invariant(
      !size,
      'Property size is deprecate, please use buttonSize instead',
    );
  }, [size]);

  return (
    <button
      type={'button'}
      ref={innerRef || ref}
      className={cx(
        'button flex px-6 rounded border focus:outline-none items-center justify-center font-bold',
        {
          primary: buttonType === ButtonType.Primary,
          secondary: buttonType === ButtonType.Secondary,
          outline: buttonType === ButtonType.Outline,
          transparent: buttonType === ButtonType.Transparent,
          'h-11 px-11.5': buttonSize === ButtonSize.Large,
          'h-11': buttonSize === ButtonSize.Medium,
          'h-9': buttonSize === ButtonSize.Small,
          disabled,
        },
        className,
      )}
      disabled={disabled}
      {...other}>
      {children}
    </button>
  );
};
export default forwardRef<HTMLButtonElement, Props>(Button);
