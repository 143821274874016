import cx from 'classnames';
import React, { useCallback } from 'react';
import CookieConsent from 'react-cookie-consent';
import { useTranslation, getI18n } from 'react-i18next';
import { Link } from 'react-router-dom';
import classes from './cookie-popup.module.scss';

const CookiePopup = () => {
  const { t } = useTranslation('common');
  const { language } = getI18n();

  const renderCookie = useCallback(() => {
    if (language === 'tw' || language === 'zh') {
      return (
        <>
          {t('cookieBeyond')}
          <Link className={'text-light-blue font-bold'} to="/privacy">
            {t('privacyPolicy')}
          </Link>
          {t('cookieAfter')}
        </>
      );
    }
    return (
      <>
        {' '}
        {t('cookieMessage')}{' '}
        <Link className={'text-light-blue font-bold'} to="/privacy">
          {t('privacyPolicy')}
        </Link>
      </>
    );
  }, [language, t]);

  return (
    <CookieConsent
      buttonText={t('agree')}
      style={{
        transform: 'translate(-50%, -50px)',
        left: '50%',
        width: '90%',
      }}
      containerClasses={cx(
        'bg-blue-1 rounded font-normal text-sm',
        classes.container,
      )}
      buttonClasses={
        'border bg-light-blue text-white font-bold px-4 py-2 rounded text-base outline-none focus:outline-none'
      }
      buttonWrapperClasses={
        'h-full flex items-center w-full md:w-auto justify-end'
      }
      contentClasses={'flex-auto md:flex-1 md:my-auto'}>
      {renderCookie()}
    </CookieConsent>
  );
};

export default CookiePopup;
