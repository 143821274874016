import {
  AppRouteConst,
  CourierOrderRouteConst,
  OrderRouteConst,
} from 'constants/route.const';
import { lazy } from 'react';
import { RouteProps } from 'react-router';
import CollectionDetail from 'containers/app/OrderCollection/CollectionDetail';
// import Collection from 'containers/app/OrderCollection/Collection';
import DeliveryDetail from 'containers/app/OrderDelivery/DeliveryDetail';
import Sitemap from '../containers/app/Sitemap/Sitemap';
import Delivery from '../containers/app/OrderDelivery/Delivery';

const Home = lazy(() => import('containers/app/Home/Home'));
const AllShops = lazy(() => import('../containers/app/AllShops/AllShops'));
const NewOrder = lazy(() => import('containers/app/Order/NewOrder'));
const OrderEdit = lazy(() => import('../containers/app/Order/OrderEdit'));
const About = lazy(() => import('../containers/app/About/About'));
const OrderDetail = lazy(() => import('../containers/app/Order/OrderDetail'));
const Collection = lazy(
  () => import('containers/app/OrderCollection/Collection'),
);
const Disclaimer = lazy(
  () => import('../containers/app/Disclaimer/Disclaimer'),
);
const PrivacyAndPolicy = lazy(
  () => import('../containers/app/PrivacyAndPolicy/PrivacyAndPolicy'),
);
const TermsAndConditions = lazy(
  () => import('../containers/app/TermsAndConditions/TermsAndConditions'),
);
const ContactUs = lazy(() => import('containers/app/ContactUs/ContactUs'));
const LatestNews = lazy(() => import('containers/app/LatestNews/LatestNews'));
const NewDetail = lazy(
  () => import('containers/app/LatestNews/NewDetail/NewDetail'),
);
const AssemblingOrderList = lazy(
  () => import('containers/app/Order/AssemblingOrder/AssemblingOrderList'),
);
const OrderHistory = lazy(
  () => import('containers/app/OrderHistory/OrderHistory'),
);
const staticRoutes: RouteProps[] = [
  {
    path: AppRouteConst.ABOUT,
    component: About,
    exact: true,
  },
  {
    path: AppRouteConst.DISCLAIMER,
    component: Disclaimer,
    exact: true,
  },
  {
    path: AppRouteConst.PRIVACY,
    component: PrivacyAndPolicy,
    exact: true,
  },
  {
    path: AppRouteConst.TERMS_AND_CONDITIONS,
    component: TermsAndConditions,
    exact: true,
  },
  {
    path: AppRouteConst.SITEMAP,
    component: Sitemap,
    exact: true,
  },
  {
    path: AppRouteConst.CONTACT_US,
    component: ContactUs,
    exact: true,
  },
  {
    path: AppRouteConst.ORDER_HISTORY,
    component: OrderHistory,
    exact: true,
  },
];

const orderCollection: RouteProps[] = [
  {
    path: CourierOrderRouteConst.ORDER_COLLECTION,
    component: Collection,
    exact: true,
  },
  {
    path: CourierOrderRouteConst.ORDER_COLLECTION_DETAIL,
    component: CollectionDetail,
    exact: true,
  },
  {
    path: CourierOrderRouteConst.ORDER_DELIVERY,
    component: Delivery,
    exact: true,
  },
  {
    path: CourierOrderRouteConst.ORDER_DELIVERY_DETAIL,
    component: DeliveryDetail,
    exact: true,
  },
];

const orderRoute: RouteProps[] = [
  {
    path: OrderRouteConst.ASSEMBLING_ORDER,
    component: AssemblingOrderList,
    exact: true,
  },
  {
    path: OrderRouteConst.ORDER_DETAIL,
    component: OrderDetail,
    exact: true,
  },
];

const newRoute: RouteProps[] = [
  {
    path: AppRouteConst.LATEST_NEWS_DETAIL,
    component: NewDetail,
    exact: true,
  },
];

const routes: RouteProps[] = [
  {
    path: AppRouteConst.HOME,
    component: Home,
    exact: true,
  },
  {
    path: AppRouteConst.SHOP,
    component: AllShops,
    exact: true,
  },
  {
    path: AppRouteConst.LATEST_NEWS,
    component: LatestNews,
    exact: true,
  },
  {
    path: OrderRouteConst.NEW_ORDER,
    component: NewOrder,
    exact: true,
  },
  {
    path: OrderRouteConst.ORDER_EDIT,
    component: OrderEdit,
    exact: true,
  },
  ...staticRoutes,
  ...orderCollection,
  ...orderRoute,
  ...newRoute,
];

export default routes;
