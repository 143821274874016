import * as devConfig from './config.dev';
import * as progressConfig from './config.progress';
import * as stagingConfig from './config.staging';
import * as productionConfig from './config.production';

export const appStage = process.env.REACT_APP_STAGE
  ? process.env.REACT_APP_STAGE.trim()
  : 'production';

interface ConfigModel {
  BASE_URL: string;
}

let CONFIG: ConfigModel;

if (appStage === 'production') {
  CONFIG = productionConfig;
} else if (appStage === 'staging') {
  CONFIG = stagingConfig;
} else if (appStage === 'progress') {
  CONFIG = progressConfig;
} else {
  CONFIG = devConfig;
}

export default CONFIG;
