import { call, put, takeLatest } from 'typed-redux-saga';
import { RESULT_MESSAGES } from 'constants/common.const';
import { getDeliveryDetail } from './detail.action';
import { getDeliveryDetailApi } from '../../../api/orderDelivery.api';

function* getDeliveryById(
  action: ReturnType<typeof getDeliveryDetail.request>,
) {
  try {
    const response = yield* call(
      getDeliveryDetailApi,
      action.payload.orderId,
      action.payload.lang,
    );
    if (
      response.result.code === 200 &&
      response.result.message !== RESULT_MESSAGES.NO_RECORD_FOUND
    ) {
      yield put(getDeliveryDetail.success(response.data));
    } else {
      yield put(getDeliveryDetail.failure(response.result.message));
    }
  } catch (e) {
    yield put(getDeliveryDetail.failure('Error'));
  }
}

export default function* orderDeliveryDetailSaga() {
  yield* takeLatest(getDeliveryDetail.request, getDeliveryById);
}
