import { createAsyncAction, createAction } from 'typesafe-actions';
import { AssemblingOrder } from 'models/api/order/assemblingOrder.model';

export interface GetListPayload {
  page?: number;
  sort?: number;
  status?: number;
  priority?: number;
  keyword?: string;
}

type GetListOrderSuccessPayload = {
  data: AssemblingOrder[];
  meta: {
    page: number;
    recordCnt: number;
    totalCnt: number;
  };
};

export const getList = createAsyncAction(
  '@order/assemblingOrder/GET_LIST_REQUESTED',
  '@order/assemblingOrder/GET_LIST_SUCCESS',
  '@order/assemblingOrder/GET_LIST_FAILURE',
)<GetListPayload, GetListOrderSuccessPayload, void>();

export const clearListAssembling = createAction(
  `@order/assemblingOrder/CLEAR_LIST`,
)<void>();
