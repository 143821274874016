import { call, delay, put, takeLatest } from 'typed-redux-saga';
import {
  getSaleOrderApi,
  getSaleOrderByKeywordApi,
  getSaleOrderByStatusApi,
} from 'api/order.api';
import { identity, pickBy } from 'lodash';
import { RESULT_MESSAGES } from 'constants/common.const';
import { getList } from './list.action';

function* handleGetList(action: ReturnType<typeof getList.request>) {
  try {
    yield* delay(500);
    const payload = pickBy(action.payload, identity);
    let response;

    if (payload.keyword) {
      response = yield* call(
        getSaleOrderByKeywordApi,
        payload.keyword,
        payload.page,
        payload.status,
        payload.sort,
        payload.priority,
      );
    } else if (payload.status) {
      response = yield* call(
        getSaleOrderByStatusApi,
        payload.page,
        payload.status,
        payload.sort,
        payload.priority,
      );
    } else {
      response = yield* call(
        getSaleOrderApi,
        payload.page,
        payload.sort,
        payload.priority,
      );
    }

    if (response.result.message === RESULT_MESSAGES.NO_RECORD_FOUND) {
      yield* put(
        getList.success({
          data: [],
          meta: null,
        }),
      );
    } else {
      yield* put(
        getList.success({
          data: response.data,
          meta: response.metadata,
        }),
      );
    }
  } catch (e) {
    yield* put(getList.failure());
  }
}

export default function* orderListSaga() {
  yield* takeLatest(getList.request, handleGetList);
}
