import { FieldProps } from 'formik';

import { FastField } from 'helpers/formik.helper';
import React, { FC, InputHTMLAttributes, memo } from 'react';
import Checkbox, { Props as CheckboxProps } from '../../ui/Checkbox/Checkbox';

interface Props {
  name: string;
  isBoolean?: boolean;
}

const FormikCheckbox: FC<
  Props & InputHTMLAttributes<HTMLInputElement> & CheckboxProps
> = (props) => {
  const { name, onChange, value, isBoolean, ...other } = props;

  return (
    <FastField name={name}>
      {({ field, form }: FieldProps<string>) => (
        <Checkbox
          {...other}
          {...(isBoolean && { checked: Boolean(field.value) })}
          {...((!isBoolean || isBoolean === undefined) && {
            checked:
              String(field.value).split(',').indexOf(String(value)) !== -1,
          })}
          name={name}
          onChange={(e) => {
            let values: Array<string> = field.value
              ? String(field.value).split(',')
              : [];

            if (e.target.checked) {
              values.push(String(value));
            } else {
              values = values.filter((i) => i !== String(value));
            }

            form.setFieldValue(name, values.join(','));

            onChange?.(e);
          }}
        />
      )}
    </FastField>
  );
};

export default memo(FormikCheckbox);
