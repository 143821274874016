export const COLORS = {
  'dark-blue': '#0F2869',
  blue: {
    DEFAULT: '#00579E',
    1: '#586679',
  },
  'light-blue': {
    DEFAULT: '#3DAAE8',
    1: '#F2F8FD',
  },
  gray: {
    1: '#333333',
    2: '#4F4F4F',
    3: '#828282',
    4: '#BDBDBD',
    5: '#E0E0E0',
    6: '#F2F2F2',
    7: 'rgba(246, 246, 246, 0.7)',
  },
  yellow: {
    DEFAULT: '#FFDB59',
  },
  error: {
    DEFAULT: '#C51C1C',
  },
  status: {
    2: '#F2994A',
    3: '#E82F5C',
    4: '#219653',
  },
};
