import cx, { Mapping } from 'classnames';
import React, {
  ChangeEvent,
  forwardRef,
  InputHTMLAttributes,
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';

export enum InputSize {
  Large = 'l',
  Medium = 'm',
  Small = 's',
  ExtraLarge = 'xl',
}

export type InputSizeType = typeof InputSize[keyof typeof InputSize];

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  renderPrefix?: (() => ReactElement) | ReactElement;
  renderSuffix?: (() => ReactElement) | ReactElement;
  isError?: boolean;
  inputClassName?: string;
  inputSize?: InputSizeType;
  classSuffix?: string;
  classPrefix?: string;
  label?: (() => ReactElement) | ReactElement | string;
  id?: string;
}

const Radio = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    isError = false,
    className,
    renderPrefix,
    renderSuffix,
    classSuffix,
    classPrefix,
    inputClassName,
    style,
    disabled,
    name,
    label,
    onChange,
    children,
    id,
    checked,
    ...other
  } = props;
  const inputRef = useRef<HTMLInputElement>();
  useImperativeHandle(ref, () => inputRef.current);

  const onMouseUp = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleOnClick = useCallback(
    (e: MouseEvent<HTMLDivElement>, ...args: any[]) => {
      if (inputRef?.current && !disabled) {
        const event = Object.create(e);
        event.target = inputRef.current;
        event.currentTarget = inputRef.current;
        inputRef.current.checked = !inputRef.current?.checked;
        onChange(event as ChangeEvent<HTMLInputElement>);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange, inputRef.current?.checked],
  );

  return (
    <>
      {label && (
        <span className="font-normal text-sm block text-gray-1">{label}</span>
      )}

      <div
        className={cx(
          'radio border rounded border-gray-4 items-center flex bg-white cursor-pointer',
          {
            hidden: props.type === 'hidden',
            'border-error': isError && !checked && !disabled,
            'border-light-blue checked': checked,
            'mt-1': label,
            'bg-gray-6 border-gray-4 text-gray-4 disabled': disabled,
          } as Mapping,
          className,
        )}
        onClick={handleOnClick}
        style={style}
        onMouseUp={onMouseUp}>
        {renderPrefix && (
          <span
            className={cx('renderPrefix inline-flex h-full mr-2', classPrefix)}>
            {renderPrefix}
          </span>
        )}
        <input
          className={cx(
            'focus:outline-none border-none bg-transparent outline-none cursor-pointer hidden opacity-0',
            inputClassName,
          )}
          type="radio"
          {...other}
          ref={inputRef}
          disabled={disabled}
          onChange={onChange}
          name={name}
          id={id}
          checked={checked}
        />
        <span className="radioTick" />
        {children && (
          <div className={cx('ml-2 text-sm font-normal w-full')}>
            {children}
          </div>
        )}
        {renderSuffix && (
          <span className={cx('renderSuffix inline-flex h-full', classSuffix)}>
            {renderSuffix}
          </span>
        )}
      </div>
    </>
  );
});

Radio.displayName = 'Radio';

export default Radio;
