import CustomSwitch from 'components/common/custom-switch/CustomSwitch';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import routes from 'routes/app.route';
import { Type, User } from 'models/api/authentication/authentication.model';
import { getUser } from 'api/authentication.api';
import { ScaleLoader } from 'react-spinners';
import { COLORS } from 'constants/color.const';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import HeaderNew from './Header/HeaderNew';
import classes from './layout.module.scss';
import CookiePopup from './CookiePopup/CookiePopup';
import NewsPopup from './NewsPopup/NewsPopUp';

const AppLayout = () => {
  const { i18n } = useTranslation();
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState<boolean>(true);

  const url = window.location.href;
  const domain = new URL(url);
  const newDomain = domain.pathname;

  useEffect(() => {
    getUser()
      .then((r) => setUser(r.data)) // eslint-disable-next-line
      .catch((e) => {
        // eslint-disable-next-line
        console.log(e);
      })
      .finally(() => setLoading(false));
  }, [i18n.language]);

  return (
    <>
      {loading ? (
        <div className={'fixed top-1/3 flex w-full justify-center'}>
          <ScaleLoader color={COLORS.blue.DEFAULT} />
        </div>
      ) : (
        <>
          <div
            className={
              newDomain === '/order/edit' ? classes.layoutNew : classes.layout
            }>
            {newDomain === '/order/edit' ? (
              <HeaderNew user={user} bigSize={user?.type !== Type.COURIER} />
            ) : (
              <Header user={user} bigSize={user?.type !== Type.COURIER} />
            )}
            <div
              className={cx(
                'container md:max-w-700px xl:max-w-1170px 2xl:max-w-1280px lg:max-w-980px 3xl:max-w-1536px',
                classes.content,
              )}>
              <CustomSwitch routes={routes} />
            </div>
            <Footer user={user} />
          </div>
          {(user?.type === Type.SHOP || user?.type === Type.HEADQUARTER) && (
            <>
              <CookiePopup />
              <NewsPopup />
            </>
          )}
        </>
      )}
    </>
  );
};

export default AppLayout;
