import { getCollectionDetailApi } from 'api/orderCollection.api';
import { RESULT_MESSAGES } from 'constants/common.const';
import { call, put, takeLatest } from 'typed-redux-saga';
import { getCollectionDetail } from './detail.action';

function* getCollectionById(
  action: ReturnType<typeof getCollectionDetail.request>,
) {
  try {
    const response = yield* call(
      getCollectionDetailApi,
      action.payload.orderId,
      action.payload.lang,
    );
    if (
      response.result.code === 200 &&
      response.result.message !== RESULT_MESSAGES.NO_RECORD_FOUND
    ) {
      yield put(getCollectionDetail.success(response.data));
    } else {
      yield put(getCollectionDetail.failure(response.result.message));
    }
  } catch (e) {
    yield put(getCollectionDetail.failure('Error'));
  }
}

export default function* orderCollectionDetailSaga() {
  yield* takeLatest(getCollectionDetail.request, getCollectionById);
}
