import * as Yup from 'yup';

// eslint-disable-next-line no-template-curly-in-string
const mutilplyByOneQuater = "${path} isn't multiplied by 0.25";

function multiplyByOneQuater() {
  return this.test(
    'mulply_0.25',
    mutilplyByOneQuater,
    (value: Number | undefined) => value && Number(value) % 0.25 === 0,
  );
}

export function registerValidation() {
  Yup.addMethod<Yup.NumberSchema>(
    Yup.number,
    'multiplyByOneQuarter',
    multiplyByOneQuater,
  );
}
