import { lazy } from 'react';
import { RouteProps } from 'react-router';
import { AuthRouteConst } from 'constants/route.const';

const Login = lazy(() => import('containers/auth/Login/LoginForm'));
const routes: RouteProps[] = [
  { path: AuthRouteConst.SIGN_IN, component: Login, exact: true },
];

export default routes;
