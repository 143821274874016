import { CommonResponse } from '../../common.model';

export type ContentResponse = CommonResponse<Content>;
export type ContentLogo = CommonResponse<Content>;
export type ContactInfoResponse = CommonResponse<ContactInfo[]>;
export type MessageStatusResponse = CommonResponse<MessageStatus>;

export interface ContactMessage {
  message: string;
  photo1?: File;
  photo2?: File;
  photo3?: File;
}

export interface Content {
  id: number;
  title?: string;
  content?: string;
  filepath?: string;
  filename?: string;
}

export enum ContentType {
  ABOUT_US = 1,
  TERMS_AND_CONDITIONS = 2,
  PRIVACY = 3,
  DISCLAIMER = 4,
  PRODUCT_LIST = 5,
  Q_AND_A = 6,
}

export interface ContactInfo {
  name: string;
  address: string;
  phone: string;
  fax: string;
  email: string;
  filepath: string;
  filename: string;
}

export interface MessageStatus {
  id: number;
  title: string;
  type: number;
  content: string;
  status: number;
}
